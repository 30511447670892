import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { userFields } from "../../Fields/userFields";
import InputField from "../../common/InputField";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutOp } from "../../redux/operations/user.operations";
import { updateUserGenOp } from "../../redux/operations/user.operations";
import { deleteAdminGenOp } from "../../redux/operations/deleteAdmin.operations";
import { toast } from "react-toastify";
import { IoMdSave } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import Modal from "../../components/Modal";
import { Helmet } from "react-helmet";
import { FaBellSlash } from "react-icons/fa";
import { setUser } from "../../redux/actions/login.action";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import moment from "moment";
import { Card } from "@mui/material";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoTimeSharp } from "react-icons/io5";
import { PiPaperPlaneFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import {
  cancelSubscriptionGenOp,
  cancelFreeSubscriptionGenOp,
  deactivatePersonalPlanGenOp,
} from "../../redux/operations/subscription.operations";
function Profile() {
  const [fields, setFields] = useState();
  const [unSubscribeModal, setUnSubscribeModal] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { description } = useSelector((state) => state?.subscription);
  const currentPlan = useSelector((state) => state?.user?.plan);

  const { current_period_end } = useSelector((state) => state?.subscription);
  const { cancel_at_period_end } = useSelector((state) => state?.subscription);

  const interval = useSelector((state) => state?.subscription?.plan?.interval);
  const subId = useSelector((state) => state.subscription.id);
  const navigate = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (!fields) {
      setFields(userFields);
    }
  }, [fields]);

  const onSubmit = (data) => {
    const phoneNumber = data.phoneNumber;
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phoneNumber", phoneNumber || user.email);
    if (phoneNumber?.length > 0) {
      const isPhoneNumber = isPossiblePhoneNumber(phoneNumber);
      if (!isPhoneNumber) {
        return toast.error("Enter a valid phone number!");
      }
    }
    dispatch(updateUserGenOp(formData)).then((res) => {
      if (res.status === 200) {
        dispatch(setUser(res.data.data));
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message || res.message);
      }
    });
  };

  const handleDeleteAdmin = () => {
    dispatch(deleteAdminGenOp()).then((res) => {
      console.log("res", res);
      if (res.status === 200) {
        toast.success(res.data.message);
        dispatch(userLogoutOp());
        navigate("/web/login");
      } else {
        toast.error(res.data.message || res.message);
      }
    });
  };
  const openUnSubscribeModal = () => {
    setUnSubscribeModal(true);
  };
  const handleUnSubscribe = () => {
    const subData = {
      subId: subId,
    };
    const FreePlanData = {
      description:
        currentPlan && currentPlan?.length > 0 && currentPlan[0].currentPlan,
    };
    if (
      currentPlan &&
      currentPlan?.length > 0 &&
      currentPlan[0].currentPlan === "Free"
    ) {
      dispatch(deactivatePersonalPlanGenOp(FreePlanData));
      setUnSubscribeModal(false);
      navigate("/web/dashboard");
    } else {
      dispatch(cancelSubscriptionGenOp(subData));
      setUnSubscribeModal(false);
      // handleLogout();
    }
  };
  return (
    <>
      <Helmet>
        <title>{`SCANS  |  User Profile`}</title>
        <meta name="description" content="User Profile" />
      </Helmet>
      <Modal
        firstButtonText={"No"}
        secondButtonText={"Yes"}
        heading={"Confirm Un-Subscribe"}
        body={<p>Do you want to Cancel Your Subscription?</p>}
        modal={unSubscribeModal}
        pressSecondButton={() => handleUnSubscribe()}
        pressFirstButton={() => setUnSubscribeModal(false)}
        pressCloseButton={() => setUnSubscribeModal(false)}
      />
      <Card className="mt-2 mb-2 col-span-5 p-3">
        <div className="flex flex-col laptop:flex-row laptop:justify-between">
          <div className="order-1 laptop:order-2 laptop:w-75 mb-4 laptop:mb-0">
            {!cancel_at_period_end &&
            currentPlan &&
            currentPlan?.length > 0 &&
            currentPlan[0].currentPlan !== null &&
            currentPlan &&
            currentPlan?.length > 0 &&
            currentPlan[0].currentPlan !== "Free" ? (
              <div className="flex-1  text-sm flex justify-end  max-h-48 my-auto whitespace-pre w-full  font-medium ">
                <div className="flex p-1 items-center justify-between">
                  <div className="logout">
                    <button
                      onClick={openUnSubscribeModal}
                      className="block  text-gray-100 hover:text-gray-200 rounded-3xl px-6 py-3 tablet:hover:bg-red-500 tablet:border-0 bg-red-700"
                    >
                      <FaBellSlash
                        size={23}
                        className="min-w-max mr-2 inline-flex"
                      />
                      Un-Subscribe
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {currentPlan &&
            currentPlan?.length > 0 &&
            currentPlan[0].currentPlan !== "Free" ? (
              <Card
                className="flex-auto flex justify-between items-center h-40 lg:w-60 m-2 pl-10 pr-10"
                style={{
                  background: "linear-gradient(-20deg,#1fd2dc 0,#1f9bff 100%)",
                }}
              >
                <div className="flex flex-col items-start gap-3">
                  <div className="flex items-center">
                    <PiPaperPlaneFill size={23} className="text-white mr-2" />
                    <p className="text-sm laptop:text-xl font-bold text-white flex items-center">
                      Plan Type :
                    </p>
                  </div>
                  <div className="flex items-center">
                    <IoTimeSharp size={23} className="text-white mr-2" />
                    <p className="text-sm laptop:text-xl font-bold text-white flex items-center">
                      Plan Interval :
                    </p>
                  </div>

                  <div className="flex items-center">
                    <FaRegCalendarAlt size={23} className="text-white mr-2" />
                    {cancel_at_period_end ? (
                      <p className="text-sm laptop:text-xl font-bold text-white flex items-center">
                        Plan Valid Till :
                      </p>
                    ) : (
                      <p className="text-sm laptop:text-xl font-bold text-white flex items-center">
                        Renews on :
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-col items-start ml-5 gap-3">
                  <p className="text-sm laptop:text-xl font-bold text-white flex items-center uppercase">
                    {description || "NA"}
                  </p>
                  <p className="text-sm laptop:text-xl font-bold text-white flex items-center uppercase">
                    {interval || "NA"}
                  </p>

                  <p className="text-sm laptop:text-xl font-bold text-white flex items-center uppercase">
                    {current_period_end
                      ? moment
                          .unix(current_period_end)
                          .format("MMMM DD, YYYY") || "NA"
                      : "NA"}
                  </p>
                </div>
              </Card>
            ) : null}
          </div>
          <div className="order-2 laptop:order-1 laptop:w-1/2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col">
                {fields &&
                  fields.map((field) => {
                    return (
                      <div
                        className="flex flex-col mb-6 w-full laptop:w-3/6"
                        key={field.fieldName}
                      >
                        <label className="block text-gray-800 font-bold mb-3 pr-4">
                          {field.fieldTitle}
                        </label>
                        <InputField
                          {...field}
                          register={register}
                          errors={errors}
                          defaultValue={user[field.fieldName]}
                          className="shadow appearance-none border-2 border-gray-500 rounded-xl w-full  py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                        />
                      </div>
                    );
                  })}
                <div className="flex flex-col mb-6 w-full laptop:w-3/6">
                  <label className="block text-gray-800 font-bold mb-3 pr-4">
                    Phone Number
                  </label>
                  <PhoneInputWithCountry
                    flags={flags}
                    className="shadow appearance-none border-2 border-gray-500  rounded-xl w-full  py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                    defaultCountry="US"
                    name="phoneNumber"
                    control={control}
                    defaultValue={user.phoneNumber}
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2 tablet:flex-row  tablet:justify-center tablet:w-1/2 ">
                <button
                  type="button"
                  onClick={handleDeleteAdmin}
                  className={`inline-block border-2 border-red-600 bg-red-600 mt-2 px-6 py-2 text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-red-400 hover:bg-red-500 hover:text-white focus:border-red-400 focus:text-white focus:outline-none focus:ring-0 active:border-red-400 active:text-white rounded-full w-full laptop:w-72`}
                >
                  <MdDelete
                    size={23}
                    className="min-w-max mr-2 mb-1 inline-flex"
                  />
                  Delete
                </button>
                <button
                  type="submit"
                  className={`inline-block border-2 border-themeBlue bg-themeBlue mt-2 px-6 py-2 text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full w-full laptop:w-72`}
                >
                  <IoMdSave
                    size={23}
                    className="min-w-max mr-2 mb-1 inline-flex"
                  />
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </>
  );
}

export default Profile;
