const menuState = {
    isGrid: false,
  };
  export const menuReducer = (state = menuState, action) => {
    const { type, payload } = action;
    switch (type) {
      case 'ISGRID':
        return {
          ...state,
          isGrid:payload,
        };
      default:
        return { ...state };
    }
  };