import {
  generateOTPAction,
  verifyOTPAction,
  updateUserAction,
  addUserAction,
  socialLoginAction,
  setLogin,
  setLogOut,
  getUserAction,
  getUserByAdminAction,
  deleteUserByAdminAction
} from '../actions/login.action';

const userLoginGenOp = (data) => async (dispatch) => {
  console.log('data in userLoginGenOp', data);
  const response = await dispatch(generateOTPAction(data));
  return response.payload;
};

const userLoginVerifyOp = (data) => async (dispatch) => {
  const response = await dispatch(verifyOTPAction(data));
  await dispatch(setLogin({ isLoggedIn: true }));
  return response.payload;
};

const updateUserGenOp = (data) => async (dispatch) => {
  const response = await dispatch(updateUserAction(data));
  return response.payload;
};
const addUserGenOp = (data) => async (dispatch) => {
  const response = await dispatch(addUserAction(data));
  return response.payload;
};
const getUserByAdminGenOp = () => async (dispatch) => {
  const response = await dispatch(getUserByAdminAction());
  return response.payload;
};
const deleteUserByAdminGenOp = (id,currentPlan) => async (dispatch) => {
  const response = await dispatch(deleteUserByAdminAction(id,currentPlan));
  return response.payload;
};
const getUserGenOp = () => async (dispatch) => {
  const response = await dispatch(getUserAction());
  return response.payload;
};

const socialLoginGenOp = (token) => async (dispatch) => {
  const response = await dispatch(socialLoginAction(token));
  return response.payload;
};

const userLogoutOp = () => async (dispatch) => {
  await dispatch(setLogOut({ isLoggedIn: false }));
};

export {
  userLoginGenOp,
  userLoginVerifyOp,
  userLogoutOp,
  updateUserGenOp,
  socialLoginGenOp,
  getUserGenOp,
  addUserGenOp,
  getUserByAdminGenOp,
  deleteUserByAdminGenOp
};
