import React, { useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { GrDocumentZip } from 'react-icons/gr';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import Loader from './Loader';

function GenerateQR() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [value, setValue] = useState([]);
  const [showDownload, setShowDownload] = useState(false);
  const [zip, setZip] = useState(null);
  const [showLoading, setShowLoading] = useState(false);

  const generateZip = () => {
    // Capture all canvas elements on the page
    const canvasElements = document.querySelectorAll('canvas');

    // Create a zip file
    const zipFile = new JSZip();

    // Convert each canvas to a PNG image and add it to the zip file
    const promises = [];

    canvasElements.forEach((canvas, index) => {
      const canvasId = canvas.id || `canvas_${index + 1}`;
      setShowLoading(true);
      promises.push(
        html2canvas(canvas).then((canvasImage) => {
          const imgDataUrl = canvasImage.toDataURL('image/png');
          zipFile.file(`${canvasId}.png`, imgDataUrl.split('base64,')[1], {
            base64: true,
          });
        })
      );
    });

    // Wait for all promises to resolve
    Promise.all(promises).then(() => {
      setZip(zipFile);
      setShowLoading(false);
    });
  };

  useEffect(() => {
    if (showDownload) {
      generateZip();
    }
  }, [showDownload, value]);

  const onSubmit = (data) => {
    const qrNumber = data.numberOfQR;
    let qr_id = [];
    for (let i = 1; i <= qrNumber; i++) {
      qr_id.push(`${window.location.origin}/${uuid()}`);
    }
    setValue(qr_id);
    setShowDownload(true);
  };

  const onBulkDownload = async () => {
    if (zip) {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const url = URL.createObjectURL(content);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'QR_images.zip';
        link.click();
        URL.revokeObjectURL(url);
      });
    }
  };

  const onImageDownload = (qr) => {
    let canvas = document.getElementById(qr);
    let url = canvas.toDataURL('image/png');
    let link = document.createElement('a');
    link.download = `${qr}.png`;
    link.href = url;
    link.click();
  };

  return (
    <div className="min-h-screen bg-welcomeBg">
      <div className="flex flex-col justify-center items-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col tablet:grid tablet:grid-cols-4 tablet:gap-2 mt-4 mb-2">
            <div className="relative tablet:col-span-3 mb-2 tablet:mb-0">
              <input
                type="number"
                id="numberOfQR"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter the number of QR to be generated"
                {...register('numberOfQR', {
                  required: true,
                  min: 1,
                  max: 30,
                })}
              />
              {errors.numberOfQR && (
                <span className="text-red-800">
                  Please enter how many QR codes you need from minimum of 1 to
                  maximum of 30.
                </span>
              )}
            </div>
            <button
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
              type="submit"
            >
              Generate QR
            </button>
          </div>
        </form>
        <div className="flex flex-col mb-2 tablet:flex-row justify-center">
          {showDownload && (
            <div className="grid grid-cols-1 place-items-center">
              <button
                className="flex space-x-3 mr-2 font-semibold bg-gradient-to-r from-indigo-600 to-pink-500 text-gray-100 rounded-sm ring-2 ring-purple-400 px-5 py-2.5 mb-2 hover:bg-white  hover:text-white hover:ring-slate-300 shadow-lg shadow-indigo-300/50"
                onClick={onBulkDownload}
              >
                <GrDocumentZip className="zipColor" size="1.7rem" /> &nbsp;
                Download
              </button>
            </div>
          )}
        </div>
        {showLoading ? (
          <div className="grid grid-cols-1 mb-4">
            <Loader />
          </div>
        ) : (
          <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-2">
            {value &&
              value.map((qr) => (
                <div
                  className="mt-4 mb-4 cursor-pointer"
                  key={qr}
                  onClick={() => onImageDownload(qr)}
                >
                  <div
                    style={{
                      height: 'auto',
                      margin: '0 auto',
                      width: '100%',
                    }}
                  >
                    <QRCode
                      id={qr}
                      size={256}
                      style={{
                        height: 'auto',
                        maxWidth: '100%',
                        width: '100%',
                      }}
                      value={qr}
                      viewBox={`0 0 256 256`}
                      logoImage={`${process.env.REACT_APP_S3_URL}/scanQRlogo.png`}
                      logoWidth={75}
                      logoHeight={25}
                      logoOpacity={0.8}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default GenerateQR;
