export const boxItemFields = [
    {
      fieldName: 'itemTitle',
      fieldTitle: 'Title',
      placeholder: 'Item Title',
      validationObj: {
        required: 'Please Enter Item Title',
        maxLength: {
          value: 52,
          message: 'Character Limit Exceeded, Max Allowed Is 52!',
        },
      },
      fieldType: 'text',
    },
  
    // {
    //   fieldName: 'price',
    //   fieldTitle: 'Price',
    //   placeholder: 'Item Price',
    //   // validationObj: {
    //   //   required: 'Please Enter Item Price',
    //   // },
    //   fieldType: 'number',
    // },
    {
      fieldName: 'description',
      fieldTitle: 'Description',
      placeholder: 'Item Description',
      validationObj: {
        maxLength: {
          value: 250,
          message: 'Character Limit Exceeded,  Max Allowed Is 250!',
        },
      },
      fieldType: 'textarea',
    },
  ];