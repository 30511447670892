import React, { useEffect } from "react";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import App from "./App";
import Login from "./components/Login/Login";
import ProtectedRoute from "./protectedRoutes";
import Dashboard from "./scenes/Dashboard/Dashboard.js";
import GenerateQR from "./components/GenerateQR.jsx";
import ViewItems from "./components/ViewItems.jsx";
import FormItem from "./components/FormItem";
import AddQR from "./components/QR/AddQR";
import Boxes from "./scenes/Inventory/Boxes.js";
import ThankYou from "./components/ThankYou";
import Faq from "./components/Faq";
import QuickStart from "./components/QuickStart";
import ContactUs from "./components/ContactUs";
import AllItems from "./scenes/Items/AllItems.js";
import ViewSingleItem from "./components/ViewSingleItem.js";
import AddBox from "./scenes/Inventory/AddBox.js";
import ViewBox from "./scenes/Inventory/ViewBox.js";
import EditBox from "./scenes/Inventory/EditBox.js";
import AddItemWithBox from "./scenes/Inventory/AddItemWithBox.js";
import Subscription from "./scenes/Subscription/Subscription.js";
import Profile from "./scenes/UserProfile/Profile.js";
import AddUser from "./scenes/UserProfile/AddUser.js";
import Checkout from "./scenes/Payment/Checkout.js";
import Blog from "./components/blog/Blog.jsx";
import PostDetails from "./components/blog/PostDetails.jsx";
import UpgradePlan from "./scenes/Subscription/UpgradePlan.jsx";
import Report from "./scenes/Report/Report.js";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.js";
import moment from "moment";
import Features from "./components/features/Features.jsx";

const Routing = (props) => {
  const userToken = localStorage.getItem("userAuthToken");
  const plan = useSelector((state) => state.user.plan);
  const { userType } = useSelector((state) => state.user);
  const { current_period_end } = useSelector((state) => state?.subscription);
  const { cancel_at_period_end } = useSelector((state) => state?.subscription);
  const cancelDate = moment.unix(current_period_end).format("DD-MM-YY");
  const currentDate = moment(new Date()).format("DD-MM-YY");

  // Step 2: Parse the formatted dates back to moment objects
  const canceledMoment = moment(currentDate, "DD-MM-YY");
  const currentMoment = moment(currentDate, "DD-MM-YY");
  const allRoutes = () => {
    return (
      <BrowserRouter>
        {!userToken && <Navbar />}
        <Routes>
          <Route
            path="/web/dashboard"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/qr/add-qr"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <AddQR />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/upgrade"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/qr/boxes"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <Boxes />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/qr/generate-qr"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <GenerateQR />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/item/:labelId/:type"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <ViewItems />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/item/:labelId/:itemId/:type"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <ViewSingleItem />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/items/:labelId/:type"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <FormItem />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/items/add"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <FormItem />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/items/:labelId/:itemId/:type"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <FormItem />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/items/all"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <AllItems />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/web/items/box/add"
            element={
              userToken &&
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <AddItemWithBox />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/user/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/web/user/add-user"
            element={
              userToken &&
              plan?.length > 0 &&
              plan[0]?.status !== "active" &&
              cancel_at_period_end &&
              canceledMoment.isSameOrBefore(currentMoment) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : (userType === "ADMIN" || userType === "SUPER-ADMIN") &&
                plan?.length > 0 &&
                plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <AddUser />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/web/report"
            // element={
            //   <ProtectedRoute>
            //     <Report />
            //   </ProtectedRoute>
            // }
            element={
              (userToken && userToken && plan?.length === 0) ||
              (userToken &&
                plan?.length > 0 &&
                plan[0]?.status !== "active" &&
                cancel_at_period_end &&
                canceledMoment.isSameOrBefore(currentMoment)) ? (
                <ProtectedRoute>
                  <UpgradePlan />
                </ProtectedRoute>
              ) : plan?.length > 0 && plan[0]?.status === "active" ? (
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              ) : (userToken && plan?.length === 0) ||
                (userToken &&
                  plan?.length > 0 &&
                  plan[0]?.status !== "active") ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          {/* <Route path="/web/linkedIn" element={<LinkedInCallback />} /> */}
          <Route path="/web/login" element={<Login />} />
          <Route path="/web/thank-you" element={<ThankYou />} />
          <Route path="/web/faq" element={<Faq />} />
          <Route path="/web/features" element={<Features />} />
          <Route path="/web/quick-start" element={<QuickStart />} />
          <Route path="/web/contact-us" element={<ContactUs />} />
          <Route path="/web/blog" element={<Blog />} />
          <Route path="/web/pricing" element={<Subscription />} />
          {/* <Route path="/web/post-details" element={<PostDetails />} /> */}
          <Route path="/web/post-details/:slug" element={<PostDetails />} />
          <Route
            path="/"
            element={
              userToken && plan?.length > 0 ? (
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              ) : userToken && plan?.length === 0 ? (
                <ProtectedRoute>
                  <Subscription />
                </ProtectedRoute>
              ) : (
                <App />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/:qrcode" element={<App />} />
        </Routes>
        <FooterRoute />
      </BrowserRouter>
    );
  };

  return <>{allRoutes(props)}</>;
};
const FooterRoute = () => {
  const userToken = localStorage.getItem("userAuthToken");
  const location = useLocation();

  if (location.pathname === "/web/login" || userToken) {
    return null;
  }

  return (
    <>
      <Footer />
    </>
  );
};
export default Routing;
