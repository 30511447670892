/* eslint-disable react-hooks/exhaustive-deps */
import Sidebar from "./Sidebar/Sidebar";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { setSubscription } from "../redux/actions/subscription.action";
import { getSubscriptionGenOp } from "../redux/operations/subscription.operations";

function RootLayout({ children }) {
  const userId = useSelector((state) => state.user._id);
  const subscription = useSelector((state) => state.subscription);
  const currentPlan = useSelector((state) => state?.user?.plan);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const todayDate = moment().format("LL");
  const { current_period_end } = useSelector((state) => state?.subscription);
  const { cancel_at_period_end } = useSelector((state) => state?.subscription);
  const cancelDate = moment.unix(current_period_end).format("DD-MM-YY");
  const currentDate = moment(new Date()).format('DD-MM-YY');
  
  // Step 2: Parse the formatted dates back to moment objects
  const canceledMoment = moment(cancelDate, "DD-MM-YY");
  const currentMoment = moment(currentDate, "DD-MM-YY");

  // console.log('subscription', subscription?.status);

  useEffect(() => {
    getSubscriptionStatus(userId);
  }, []);

  const getSubscriptionStatus = (id) => {
    dispatch(getSubscriptionGenOp(id)).then((res) => {
      dispatch(setSubscription(res.data));
    });
  };
  return (
    <div className="flex h-screen overflow-hidden font-manrope">
      {(subscription?.status === "active" &&
        subscription?.description === "Personal") ||
      subscription?.description === "Free" ? (
        <Sidebar plan="Personal" />
      ) : (subscription?.status === "active" ||
          subscription?.status === "trialing") &&
        (
          subscription?.description === "Pro" ||
          subscription?.description === "Premium" ||
          subscription?.description === "Infinity") ? (
        <Sidebar plan="Business" />
      ) : (
        <Sidebar plan="none" />
      )}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <main>
          <div className="px-2 laptop:px-2 py-4">
            <div className="bg-themeBlue flex flex-col justify-center laptop:flex-row py-4 rounded-md laptop:justify-between pl-4 pr-4 mb-2">
              <div className="flex items-center">
                {/* <svg className="w-8 h-8 text-white mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg> */}
                <h2 className="text-lg tablet:text-xl laptop:text-2xl text-white">
                  Welcome back, {userData.name || userData.email}!
                </h2>
                <button className="inline-block rounded-xl  border-1 border-solid border-gray-100 ml-5 px-4 py-2 text-md font-bold font-medium uppercase  text-gray-100 ">
                  {currentPlan&&currentPlan?.length>0 && cancel_at_period_end&& canceledMoment.isSameOrBefore(currentMoment)
                    ?"Subscribe to a PLAN":currentPlan&&currentPlan?.length>0 ? currentPlan[0].currentPlan
                    : "Subscribe to a PLAN"}
                </button>
              </div>
              <div className="flex items-center">
                <span className="text-xl tablet:text-2xl text-slate-400 font-bold">
                  {todayDate}
                </span>
              </div>
            </div>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default RootLayout;
