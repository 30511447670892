import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "react-data-table-component";
import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";

const ItemsTable = ({
  data,
  viewItem,
  openModal,
  perPage,
  handlePerPageChange,
  handlePageChange,
  count,
}) => {
  // console.log("openModal", openModal);

  const columns = [
    // {
    //   name: "SNo",
    //   selector: (row) => row._id,
    //   sortable: true,
    //   width: "75px"
    // },
    {
      name: "Title",
      selector: (row) => (
        <button
          className="text-blue-900 underline"
          onClick={() => viewItem(row)}
        >
          <Tooltip title={row.title}>{row.title}</Tooltip>
        </button>
      ),
      sortable: true,
    },
    {
      name: "Lable-Title",
      selector: (row) => row.labelTitle,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Updated Date",
      selector: (row) => moment(row.updatedDate).format("DD-MM-YYYY"),
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <IconButton size="small" onClick={() => viewItem(row)}>
            <Tooltip title="view">
              <VisibilityIcon color="primary" />
            </Tooltip>
          </IconButton>
          {openModal && (
            <IconButton size="small" onClick={() => openModal(row)}>
              <Tooltip title="Delete">
                <DeleteIcon color="error" />
              </Tooltip>
            </IconButton>
          )}
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <div className="rounded-xl shadow-lg p-3">
      {data ? (
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped
          fixedHeader
          highlightOnHover
          pointerOnHover
          customStyles={customStyles}
          paginationPerPage={perPage || null} // Set per page value
          paginationRowsPerPageOptions={[
            10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
          ]} // Optionally, specify rows per page options
          paginationServer // Enable server-side pagination
          paginationTotalRows={count || null} // Total rows count for pagination
          onChangePage={handlePageChange} // Handle page change
          onChangeRowsPerPage={handlePerPageChange}
        />
      ) : (
        <p>No Items available.</p>
      )}
    </div>
  );
};
const customStyles = {
  subHeader: {
    style: {
      backgroundColor: "blue",
    },
  },
  head: {
    style: {
      color: "black",
      fontSize: "13px",
      fontWeight: 600,
      minHeight: "2px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#ADC8E3",
      minHeight: "45px",
      borderBottomWidth: "1px",
      borderBottomColor: "#ffffff",
      borderBottomStyle: "solid",
    },
  },

  rows: {
    style: {
      fontSize: "13px",
      minHeight: "45px",
      fontWeight: 600,
    },
  },
};

export default ItemsTable;
