import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { Card } from "@mui/material";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import No_image_placeholder from "../../assets/No-image-placeholder.png";
const PostDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const slug = location.pathname.split("/").pop(); // Extract the slug from the URL

  useEffect(() => {
    if (location.state && location.state.post) {
      setPost(location.state.post);
    } else {
      fetchData(slug); // Fetch data based on the slug
    }
  }, [location.state, slug]);

  const fetchData = (slug) => {
    if (!isLoading) {
      setIsLoading(true);
      axios
        .post("https://blog.itpros.tech/blogs/findOneBlogDetails", {
          slug: slug,
        })
        .then((response) => {
          if (response.data.message) {
            setIsLoading(false);
          } else {
            setPost(response.data);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching posts:", error);
        });
    }
  };

  const handleBack = () => {
    navigate("/web/blog");
  };

  const handleImageError = (event) => {
    event.target.src = No_image_placeholder;
  };

  return (
    <>
      <Helmet>
        <title>{`SCANS | ${post.title}`}</title>
        <meta name="description" content={post.excerpt} />
        {post?.keywords?.map((eachKeyword) => (
          <meta name="keywords" content={eachKeyword} />
        ))}
      </Helmet>
      {/* <Navbar /> */}
      <div className="flex justify-center bg-blue-100 ">
        <div className="flex m-3 flex-col laptop:flex-row w-full rounded-xl shadow-xl bg-white">
          <LazyLoadImage
            src={post?.image || No_image_placeholder}
            style={{ height: "600px", width: "100%" }}
            PlaceholderSrc={No_image_placeholder}
            alt="Image Alt"
            effect="blur"
            className="rounded-tl-xl rounded-tr-xl laptop:rounded-bl-xl laptop:rounded-tr-sm "
          />
          <div className="p-4 laptop:w-1/2" style={{ flex: "1 1 auto" }}>
            <h2 className="font-bold text-lg mt-1 mb-1">{post?.title}</h2>
            <p className="text-gray-600 text-md mt-1 mb-1">
              <FaCalendarAlt className="inline-block mr-1" />{" "}
              {moment(post?.published_date).format("MMMM D, YYYY")}
            </p>
            <div
              className="text-gray-700 text-md font-bold mt-1 mb-1"
              dangerouslySetInnerHTML={{ __html: post?.content }}
            ></div>
            <button
              type="button"
              className="rounded-3xl border border-themeBlue border-solid bg-white text-themeBlue px-5 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white"
              style={{
                alignSelf: "flex-center",
                marginBottom: "20px",
                margin: "20px",
              }}
              onClick={handleBack}
            >
              Back
            </button>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default PostDetails;
