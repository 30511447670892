import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {useSelector} from 'react-redux';
import { toast } from "react-toastify";
import moment from "moment";
import Menu from "../menu/Menu";
import { BiSolidReport } from "react-icons/bi";
import { generateReportGenOp } from "../../redux/operations/report.operations";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import HomeCardWithImage from "../../components/HomeCardWithImage";
import { CgExport } from "react-icons/cg";
import ItemsTable from "../table/ItemsTable";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import SEO from "../../components/SEO";
function Report() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [reportURL, setReportURL] = useState("");

  // const [isGrid, setIsGrid] = useState(false);
  const { isGrid } = useSelector((state) => state.menu);

  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);

  // useEffect(()=>{

  //   generateReport()

  // },[perPage,pageNumber])

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const handlePerPageChange = (newPerPage, page) => {
    setPageNumber(page);
    setPerPage(newPerPage);
  };

  const handleStartChange = (e) => {
    setStartDate(e);
  };

  const handleEndChange = (e) => {
    setEndDate(e);
  };

  const generateReport = async () => {
    setReportData([]);
    setReportURL("");

    try {
      if (startDate.length === 0 || endDate.length === 0) {
        return toast.error("Select date range!");
      }
      setIsLoading(true);
      const startDateRange = moment(startDate).format("YYYY-MM-DD");
      const endDateRange = moment(endDate).format("YYYY-MM-DD");
      if (endDateRange < startDateRange) {
        toast.error("End Date cannot be less than Start Date!");
        setIsLoading(false);
      } else {
        const payload = {
          startDate: startDateRange,
          endDate: endDateRange,
          perPage: perPage,
          pageNum: pageNumber,
        };
        await dispatch(generateReportGenOp(payload)).then((res) => {
          setReportData(res.data?.data);
          setReportURL(res.data?.reportUrl);
          toast.info(res.data?.message);
          setIsLoading(false);
        });
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const exportReport = (url) => {
    window.open(url, "_self");
  };

  const viewItem = (item) => {
    navigate(`/web/item/${item.labelId}/${item._id}/view`, {
      state: {
        data: item,
        isView: true,
      },
    });
  };

  return (
    <div className="flex flex-col  justify-center gap-4 px-4 py-8">
      <SEO
        title={`Inventory Reports with Scans - Detailed Insights and Analysis`}
        description={`Generate detailed inventory reports with Scans. Get comprehensive insights and analysis to make informed decisions about your inventory management.`}
        keywords={`Inventory Reports, Detailed Inventory Insights, Inventory Analysis, Generate Inventory Reports, Scans Inventory Reports, Inventory Management Insights, Comprehensive Inventory Analysis, Informed Inventory Decisions`}
      />

      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <React.Fragment>
          <div className="flex justify-start justify-between">
            <h1 className=" text-lg laptop:text-2xl font-bold">
              Inventory Report
            </h1>
            <div className=" flex flex-col laptop:flex-row items-center  gap-4 tablet:ml-auto mb-3">
              {reportData && reportData.length > 0 && (
                <Button
                  onClick={() => exportReport(reportURL)}
                  variant="contained"
                  className="inline-block border-2 border-themeBlue bg-themeBlue py-3 px-6  text-md font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full w-full"
                >
                  <CgExport className="inline mr-1" /> Export
                </Button>
              )}
              <Menu/>
            </div>
          </div>
          {/* <div className="text-lg text-gray-700 text-center">
            Filter by date range
          </div> */}
          <div className="grid grid-cols-1 laptop:grid-cols-4 w-full max-w-screen-xl">
            <div className="flex flex-col mr-1">
              <h2 className="text-md font-bold text-gray-900 mb-1">
                Start Date
              </h2>
              <DatePicker
                className="shadow appearance-none border-1 border-gray-400 rounded-xl w-full laptop:w-44 desktop:w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                dateFormat="MM-dd-yyyy"
                selected={startDate}
                defaultDate={new Date()}
                placeholderText="Select from date"
                onChange={handleStartChange}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
              />
            </div>
            <div className="flex flex-col ml-1 mr-1">
              <h2 className="text-md font-bold text-gray-900 mb-1">End Date</h2>
              <DatePicker
                className="shadow appearance-none border-1 border-gray-400 rounded-xl w-full laptop:w-44 desktop:w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                dateFormat="MM-dd-yyyy"
                minDate={startDate}
                selected={endDate}
                placeholderText="Select to date"
                onChange={handleEndChange}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
              />
            </div>
            <div className="flex flex-col mt-5 ml-1">
              <button
                onClick={() => generateReport()}
                className="inline-block border-2 border-themeBlue mt-2 bg-themeBlue py-2 px-4  text-md font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full w-full laptop:w-64"
              ><BiSolidReport  size={23}
              className="min-w-max mr-2 mb-1 inline-flex"/>
                Generate Report
              </button>
            </div>
          </div>
          <div className="w-full  max-w-screen-lg mt-8">
          <h5 className="mb-2 text-xl pl-3 self-start font-bold leading-tight text-neutral-800">
                Items
              </h5>
            {isGrid ? (
              <div className=" flex flex-row flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 justify-items-center">
                {reportData && reportData.length > 0 ? (
                  reportData.map((report) => (
                    <HomeCardWithImage
                      key={report._id}
                      title={report?.title}
                      image={report?.image[0]}
                      link=""
                      boxTitle={report?.labelTitle}
                      updatedAt={report?.updatedDate}
                      onClick={() => viewItem(report)}
                    />
                  ))
                ) : (
                  <p className="text-center font ">No Items available.</p>
                )}
              </div>
            ) : (
              <ItemsTable
                data={reportData}
                viewItem={viewItem}
                pageNumber={pageNumber}
                perPage={perPage}
                handlePerPageChange={handlePerPageChange}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Report;
