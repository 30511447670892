
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';

function App() {
 
  return (
    <div className="font-proximaNova">
    
      <Home />
      
    </div>
  );
}

export default App;
