import {
  getSubscription,
  createSubscription,
  activatePersonal,cancelSubscription,
  deactivatePersonalPlan,
} from '../actions/subscription.action';

const getSubscriptionGenOp = (data) => async (dispatch) => {
  const response = await dispatch(getSubscription(data));
  return response.payload;
};

const createSubscriptionGenOp = (data) => async (dispatch) => {
  const response = await dispatch(createSubscription(data));
  return response.payload;
};

const activatePersonalGenOp = (data) => async (dispatch) => {
  const response = await dispatch(activatePersonal(data));
  return response.payload;
};
const cancelSubscriptionGenOp = (data) => async (dispatch) => {
  const response = await dispatch(cancelSubscription(data));
  return response.payload;
};
const deactivatePersonalPlanGenOp = (data) => async (dispatch) => {
  const response = await dispatch(deactivatePersonalPlan(data));
  return response.payload;
};

export { getSubscriptionGenOp, createSubscriptionGenOp, activatePersonalGenOp ,cancelSubscriptionGenOp,deactivatePersonalPlanGenOp};
