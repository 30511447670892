import React from "react";
import { Helmet } from "react-helmet";
const SEO = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>Scans | {title}</title>
      <meta name="description" content={description} />
      <title>
        {title}|| {"Warehouse Management Software | Scans"}
      </title>
      <meta
        name="description"
        content="Scans is an easy inventory software that can be used from anywhere, on any device. Learn more and try Scans free for 14 days."
      />
      <link
        rel="canonical"
        href="https://www.sortly.com/solutions/inventory-management-software/"
      />
      <meta property="og:keywords" content={keywords} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Warehouse Management Software | Scans"
      />
      <meta
        property="og:description"
        content="Scans is an easy inventory software that can be used from anywhere, on any device. Learn more and try Scans free for 14 days."
      />
      <meta
        property="og:url"
        content="https://www.sortly.com/solutions/inventory-management-software/"
      />
      <meta property="og:site_name" content="Scans" />
      <meta
        property="website:modified_time"
        content="2024-04-19T20:46:09+00:00"
      />
      {/* <meta
        property="og:image"
        content="https://media.sortly.com/wp-content/uploads/2022/09/14025105/hero_phone_1.png"
      />
      <meta property="og:image:width" content="1120" />
      <meta property="og:image:height" content="714" />
      <meta property="og:image:type" content="image/png" /> */}
    </Helmet>
  );
};

export default SEO;
