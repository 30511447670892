export const Organizedata = [
  {
    title: "Items",
    description:
      "Item entries allow you to add your items and track custom details.",
    icon: "MdProductionQuantityLimits",
  },
  {
    title: "Inventory Import",
    description:
      "Inventory Import lets you upload an existing inventory spreadsheet and populate your items in Scans instantly.",

    icon: "MdInventory2",
  },
  {
    title: "Item Photos",
    description:
      "Item Photos help you track the appearance and condition of your items.",
    icon: "MdAddPhotoAlternate",
  },
  {
    title: "Inventory Lists",
    description:
      "Inventory Lists summarize your entire inventory in one easy view.",
    icon: "FaThList",
  },
  {
    title: "User Licenses",
    description:
      "User Licenses allow you to bring your employees into Scans so you can manage inventory with your team.",
    icon: "FaUserPlus",
  },
];

export const Customizedata = [
  {
    title: "Custom Fields",
    description:
      "Custom Fields allow you to track and customize unique information about your items.",
    icon: "MdDashboardCustomize",
  },
  {
    title: "Custom Labels",
    description:
      "Custom Labels allow you to organize your items exactly how you want them.",
    icon: "MdNewLabel",
  },
  {
    title: "Customizable User Access",
    description:
      "Customizable User Access lets you control who can do and see what in Scans.",
    icon: "FaUsers",
  },
  {
    title: "Customizable Roles",
    description:
      "Custom Roles enable you to define the user experience by creating a new role and tailoring what this role can add, edit, hide, or delete in Scans.",
    icon: "GrUserAdmin",
  },
];

export const Managedata = [
  {
    title: "Barcode Scanning & Label Generation",
    description:
      "Barcoding lets you scan barcodes from your smartphone to instantly add and manage your items. Plus, generate and print custom barcode labels with ease.",
    icon: "IoMdBarcode",
  },
  {
    title: "QR Code Scanning & Label Generation",
    description:
      "QR Coding lets you scan QR codes from your smartphone to instantly add and manage your items. Plus, generate and print custom QR labels with ease.",

    icon: "MdOutlineQrCodeScanner",
  },
  {
    title: "Purchase Orders",
    description:
      "Create and export Purchase Orders using inventory details like item names, photos, costs, quantities, and more.",
    icon: "FaCartPlus",
  },
  {
    title: "Item Check-in/Check-out",
    description:
      "Check-in/Check-out lets your team check physical inventory in and out with printed labels—so you always know who used what, when.",
    icon: "MdOutlineLibraryAddCheck",
  },
];

export const TrackandUpdatedata = [
  {
    title: "Low Stock Alerts",
    description:
      "Low Stock Alerts notify you when an item is running low so you always re-order the right amount.",
    icon: "IoMdNotifications",
  },
  {
    title: "Date-Based Alerts",
    description:
      "Date-based Alerts let you can track maintenance and repair schedules for assets, tools, and equipment.",

    icon: "GoAlertFill",
  },
  {
    title: "Offline Mobile Access",
    description:
      "Offline Mobile Access lets you track and update inventory even when you’re offline or out of range.",
    icon: "MdWifiOff",
  },
  {
    title: "Automatic Sync",
    description:
      "Automatic Sync lets you and your team track and update inventory in real time from any device.",
    icon: "FaSync",
  },
  {
    title: "In-App Alerts",
    description:
      "User Licenses allow you to bring your employees into Scans so you can manage inventory with your team.",
    icon: "IoMdAlert",
  },
  {
    title: "Email Alerts",
    description:
      "Email Alerts nudge you and your team via email when it’s time to re-order or repair.",
    icon: "MdEmail",
  },
];

export const Reportdata = [
  {
    title: "Activity History Reports",
    description:
      "Activity History Reports let you monitor your team’s changes and updates in Scans.",
    icon: "FaHistory",
  },
  {
    title: "Inventory Summary Reports",
    description:
      "Inventory Summary Reports show you the total quantity and value of your inventory based on your custom filters.",

    icon: "GiStack",
  },
  {
    title: "User Activity Summary Reports",
    description:
      "User Activity Summary Reports let you view a summary of each team members’ actions and filter for specific activities within a given time period.",
    icon: "PiUserListFill",
  },
  {
    title: "Low Stock Reports",
    description:
      "Low Stock Reports show you all items that are below minimum quantity so you know how much to re-order.",
    icon: "AiOutlineStock",
  },
  {
    title: "Move Summary Reports",
    description:
      "Move Summary Reports track all item location changes in a specified time period.",
    icon: "MdOutlineMemory",
  },
  {
    title: "Item Flow Report",
    description:
      "Item Flow Reports track all item quantity changes in a specified time period.",
    icon: "BsHddRackFill",
  },
  {
    title: "Saved Reports",
    description:
      "Saved Reports allow you to go beyond pre-set report types and build customized reporting dashboards. Select the data you want to see and save your preferred filters and layouts.",
    icon: "FaTag",
  },
  {
    title: "Report Subscriptions",
    description:
      "Report Subscriptions allow you to automate a summary of any report to be sent via email (one-time or ongoing).",
    icon: "IoMdMailUnread",
  },
];
