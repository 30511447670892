import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";
import Navbar from "../Navbar";
import BlogPost from "./BlogPost";
import Footer from "../Footer";
import SEO from "../SEO";
const Blog = () => {
  return (
    <>
      <SEO
        title={`Scans Blog - Latest Updates and Tips on Inventory Management`}
        description={
          "Stay informed with the latest updates, tips, and best practices in inventory management. Read the Scans blog for expert insights and industry news."
        }
        keywords={
          "Inventory Management Tips, Latest Inventory Updates, Best Practices in Inventory Management, Inventory Management Blog, Expert Insights on Inventory, Inventory Industry News, Scans Inventory Tips, Inventory Management Strategies"
        }
      />
      <div className="bg-blue-100">
        <BlogPost />
      </div>
    </>
  );
};

export default Blog;
