import {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest
} from '../../components/interceptor/interceptor';
import {
  GENERATE_OTP,
  SOCIAL_LOGIN,
  VERIFY_OTP,
  UPDATE_USER,
  GET_USER,
  GET_USER_BY_ADMIN,
  DELETE_USER_BY_ADMIN,
  ADD_USER
} from '../../apiConst';

export const generateOTPAction = (data) => ({
  type: 'GENERATE_OTP',
  payload: postRequest(`${GENERATE_OTP}`, data),
});

export const verifyOTPAction = (data) => ({
  type: 'VERIFY_OTP',
  payload: postRequest(`${VERIFY_OTP}`, data),
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: { ...data },
});

export const updateUserAction = (data) => ({
  type: 'UPDATE_USER',
  payload: putRequest(`${UPDATE_USER}`, data),
});

export const addUserAction = (data) => ({
  type: 'ADD_USER',
  payload: postRequest(`${ADD_USER}`, data),
});

export const deleteUserByAdminAction = (id,currentPlan) => ({
  type: 'DELETE_USER_BY_ADMIN',
  payload: deleteRequest(`${DELETE_USER_BY_ADMIN}${id}&&plan=${currentPlan}`),
});
export const getUserByAdminAction = () => ({
  type: 'GET_USER_BY_ADMIN',
  payload: getRequest(`${GET_USER_BY_ADMIN}`),
});

export const getUserAction = () => ({
  type: 'GET_USER',
  payload: getRequest(`${GET_USER}`),
});

export const socialLoginAction = (token) => ({
  type: 'SOCIAL_LOGIN',
  payload: getRequest(`${SOCIAL_LOGIN}${token}`),
});

export const setLogin = (data) => ({
  type: 'LOGIN',
  payload: { ...data },
});

export const setLogOut = () => {
  return async (dispatch) => {
    await dispatch({ type: 'LOGOUT' });
    window.location.reload();
  };
};
