import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';

function Hero() {
  return (
    <>
      <div
        className="flex items-center  justify-center tablet:justify-end text-white"
        style={{
          backgroundImage:
            'url("https://scanqr-app.s3.amazonaws.com/warehouse-worker-checking-inventory.webp")',
          width: '100%',
          height: '100vh',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="bg-blue-200 ml-10 mr-10 md:mr-20 flex flex-col justify-between items-center bg-opacity-50 backdrop-filter backdrop-blur-lg p-6 rounded-2xl max-w-2xl">
          <div className="flex w-full mb-4 flex-col tablet:flex-row justify-center items-center fade-in">
            <Link
              to="https://apps.apple.com/us/app/scans/id6470666076"
              target="__blank"
            >
              <img
                src={`${process.env.REACT_APP_S3_URL}/AppStore.svg`}
                className="mb-2 tablet:h-12 tablet:pr-4 w-48 tablet:w-auto cursor-pointer"
                alt="app-store"
              />
            </Link>
            <Link
              to="https://play.google.com/store/apps/details?id=com.scans"
              target="__blank"
            >
              <img
                src={`${process.env.REACT_APP_S3_URL}/PlayStore.svg`}
                className="mb-2 tablet:h-12 w-48 tablet:w-auto cursor-pointer"
                alt="play-store"
              />
            </Link>
          </div>
          <h2
            className="text-2xl w-full text-themeHeadingText  font-blod  laptop:text-5xl desktop:text-[3rem] font-proximaNovaBlack text-center"
            style={{ fontFamily: 'italic' }}
          >
            <span className="text-themeBlue">Warehouse</span> Management
            <br /> Made Simple.
          </h2>
          <p className="w-full font-semibold text-base text-lg md:text-xl lg:text-lg text-gray-900 text-center mt-6 mb-6">
            The greatest inventory management software for small businesses to
            handle supply, inventory, and other tasks.
          </p>
          <div className="flex flex-row flex-wrap justify-center items-center gap-4">
            <Link
              to="/web/login"
              className="bg-themeBlue text-white font-bold rounded-full text-lg px-6 py-3 text-center hover:bg-indigo-700 focus:ring-4 focus:ring-primary-200"
            >
              Try Scans free
            </Link>
            <Link
              to="/web/pricing"
              className="text-themeBlue font-bold text-lg hover:underline"
            >
              See all plans <FaAngleRight className="inline" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
