import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import {
  createSubscriptionGenOp,
  getSubscriptionGenOp,
} from "../redux/operations/subscription.operations";
import { setSubscription } from "../redux/actions/subscription.action";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import FullScreenLoader from "../components/Loader/FullScreenLoader";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { getUserGenOp } from "../redux/operations/user.operations";
import { setUser } from "../redux/actions/login.action";
import { postRequest } from "./interceptor/interceptor";
import {CREATE_COUPON} from '../apiConst'

function CheckoutForm({ plan, priceId, name, email, planType, customer ,price,setPrice,isYearly}) {
  const dispatch = useDispatch();
  const currentPlan = useSelector((state) => state?.user?.plan);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState("");
  const [couponId, setCouponId] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const[discountPrice,setDiscountPrice]=useState()
  const [couponButtonText,setCouponButtontext]=useState('Apply Coupon')
  const [isLoading, setIsLoading] = useState(false);
  const [isCouponLoading, setIsCouponLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  const handleCoupon = async () => {
    if(coupon!==''){
      setIsCouponLoading(true);
      setIsDisable(true)
    try {
        const couponData = {
            name: coupon.toUpperCase()
        };
        const response = await postRequest(`${CREATE_COUPON}`, couponData);
        console.log('response',response)
        const { success, message, data } = response.data
            setCoupon('');
            setCouponId(data.id);
            console.log('Coupon applied successfully:', data.id);
            const discountedPrice = price - (price * data.percent_off / 100);
            console.log('discountedPrice',discountedPrice)
            setPrice(discountedPrice);
            setIsDisable(true)
            setCouponButtontext('Applied')
            toast.success('Coupon applied successfully');
     
    } catch (error) {
        console.error('An unexpected error occurred while applying coupon:', error);
        toast.error('An unexpected error occurred');
        setIsDisable(false)
    } finally {
        setIsCouponLoading(false);
        setIsDisable(true)
    }

    }else{
      toast.error('please Add coupon')
    }
    
};



  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     if (!stripe || !elements) {
  //       // Stripe.js hasn't yet loaded.
  //       // Make sure to disable form submission until Stripe.js has loaded.
  //       return;
  //     }

  //     const stripeElements = await elements.submit();
  //     console.log(
  //       "🚀 ~ file: CheckoutForm.js:46 ~ handleSubmit ~ stripeElements:",
  //       stripeElements
  //     );

  //     const wait = (ms) => {
  //       return new Promise((resolve) => {
  //         setTimeout(() => {
  //           resolve();
  //         }, ms);
  //       });
  //     };

  //     const subscriptionData = {
  //       name: name,
  //       email: email,
  //       priceId: priceId,
  //       plan: plan,
  //       userId: customer,
  //       coupon:couponId
  //     };
  //     if (stripeElements.error) {
  //       setIsLoading(false);
  //     } else {
  //       dispatch(createSubscriptionGenOp(subscriptionData))
  //         .then((res) => res.data.data)
  //         .then(({ type, clientSecret }) => {
  //           const confirmIntent =
  //             type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
  //           confirmIntent({
  //             elements,
  //             clientSecret,
  //             confirmParams: {
  //               return_url: "https://scanqr.ai/web/thank-you",
  //             },
  //             redirect: "if_required",
  //           });
  //         })
  //         .then(() =>
  //           wait(5000).then(() =>
  //             dispatch(getUserGenOp(customer))
  //               .then(
  //                 (res) => dispatch(setUser(res.data)),
  //                 dispatch(getSubscriptionGenOp(customer)).then((res) => {
  //                   toast.success(
  //                     `${res.data.description} is now ${res.data.status}`
  //                   );
  //                   dispatch(setSubscription(res.data));
  //                 })
  //               )
  //               .then(() => navigate("/web/dashboard"))
  //           )
  //         );
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setIsLoading(false);
        toast.error("Stripe.js hasn't loaded yet. Please try again.");
        return;
      }
  
      const stripeElements = await elements.submit();
      console.log("🚀 ~ file: CheckoutForm.js:46 ~ handleSubmit ~ stripeElements:", stripeElements);
  
      const wait = (ms) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, ms);
        });
      };
  
      const subscriptionData = {
        name: name,
        email: email,
        priceId: priceId,
        plan: plan,
        userId: customer,
        coupon: couponId
      };
  
      if (stripeElements.error) {
        setIsLoading(false);
        toast.error(`Payment Error: ${stripeElements.error.message}`);
      } else {
        const { error: subscriptionError, data: subscriptionResponse } = await dispatch(createSubscriptionGenOp(subscriptionData));
  
        if (subscriptionError) {
          throw new Error(subscriptionError);
        }
  
        const { type, clientSecret } = subscriptionResponse.data;
  
        const confirmIntent = type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;
  
        const { error: intentError, paymentIntent } = await confirmIntent({
          elements,
          clientSecret,
          confirmParams: {
            return_url: "https://scanqr.ai/web/thank-you",
          },
          redirect: "if_required",
        });
  
        if (intentError) {
          setIsLoading(false);
          switch (intentError.code) {
            case 'card_declined':
              toast.error("Your card was declined.");
              break;
            case 'insufficient_funds':
              toast.error("You have insufficient funds.");
              break;
            case 'incorrect_cvc':
              toast.error("Incorrect CVC code.");
              break;
            default:
              toast.error(`Payment Error: ${intentError.message}`);
          }
        } else {
          await wait(5000);
  
          const userResponse = await dispatch(getUserGenOp(customer))
          dispatch(setUser(userResponse.data));
  
          const subscriptionResponse = await dispatch(getSubscriptionGenOp(customer))
          toast.success(`${subscriptionResponse.data.description} is now ${subscriptionResponse.data.status}`);
          dispatch(setSubscription(subscriptionResponse.data));
          navigate("/web/dashboard");
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(`An error occurred: ${error.message}`);
    }
  };
  

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <PaymentElement options={paymentElementOptions} />
        {currentPlan&&currentPlan?.length>0
                    && currentPlan[0].currentPlan==='Free'&&currentPlan[0].previousPlan===null
                   &&isYearly&&
        <div className="flex flex-row items-center mt-5">
          <div className="mb-8">
            <label className="block text-gray-600 mb-2 pr-4">
              coupon
            </label>
            <input
              name="coupon"
              value={coupon}
              onChange={(event) => setCoupon(event.target.value)}
              className="shadow appearance-none  border-1 border-gray-500 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={handleCoupon}
              disabled={isDisable}
              className="flex items-center max-w-sm ml-3 inline-block rounded-3xl border border-themeBlue border-solid bg-white text-themeBlue px-4 py-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white"
              data-te-ripple-init
              data-te-ripple-color="light"
            >
              {isCouponLoading ? (
                <p>Loading....</p>
              ) : (
                <>
                 
                  <span>{couponButtonText}</span>
                </>
              )}
            </button>
          </div>
        </div>}

        {!isLoading ? (
          <button
            className={
              "inline-block border-2 border-themeBlue bg-themeBlue mt-2 px-6 pt-2 pb-[6px] text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full w-full"
            }
          >
            Pay
          </button>
        ) : (
          <button
            type="button"
            className="pointer-events-none inline-block bg-themeBlue px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 disabled:opacity-70 rounded-full w-full"
            disabled
          >
            <div
              className="inline-block h-4 w-4 animate-[spinner-grow_0.75s_linear_infinite] rounded-full bg-current align-[-0.125em] opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
              role="status"
            ></div>
            <span>Please wait...</span>
          </button>
        )}
      </form>
    </React.Fragment>
  );
}

export default CheckoutForm;
