/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getItemsByUserGenOp } from "../../redux/operations/items.operations";
import { setItems } from "../../redux/actions/items.action";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { IoMdAdd } from "react-icons/io";
import { searchItemGenOp } from "../../redux/operations/search.operations";
import {
  getQRsGenOp,
  createQRGenOp,
} from "../../redux/operations/qrCodes.operations";
import {getUserByAdminGenOp} from '../../redux/operations/user.operations';
import SEO from "../../components/SEO";
import { setQRCodes } from "../../redux/actions/qrCodes.action";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import HomeCard from "../../components/HomeCard";
import HomeCardWithImage from "../../components/HomeCardWithImage";
import { TERipple } from "tw-elements-react";
import Search from "../Search/Search";
import { toast } from "react-toastify";
import { Card } from "@mui/material";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { RxDashboard } from "react-icons/rx";
import { FaUsers } from "react-icons/fa";
import {isYearlyAction} from '../../redux/actions/term.action'
import ItemsTable from "../table/ItemsTable";
import Menu from "../menu/Menu";
import { Helmet } from "react-helmet";
import { getRequest } from "../../components/interceptor/interceptor";
import { GET_ITEMS_BY_USER } from "../../apiConst";
import AddBox from "../Inventory/AddBox";
function Dashboard() {
  const items = useSelector((state) => state.items);
  const userId = useSelector((state) => state.user._id);
  const { customFieldsCount } = useSelector((state) => state.items);
  const plan = useSelector((state) => state?.user?.plan);
  const count = useSelector((state) => state.qrCodes.count);
  const labels = useSelector((state) => state.user.plan[0].labels);
  const subscription = useSelector((state) => state.subscription);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemCount, setItemCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const { isGrid } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [dataId, setDataId] = useState("");
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const interval=useSelector((state)=>state.subscription?.plan?.interval)
  const [usersCount, setUsersCount] = useState(0);
  // console.log("interval", interval);
  // console.log("count", count);
  // console.log("labels", labels);

  const {
    register,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (searchQuery.length > 0) {
      onSubmit(searchQuery);
    }
  }, [searchQuery]);

  const onSubmit = async (search) => {
    const dataToBeSent = {
      searchQuery: search,
    };
    await dispatch(searchItemGenOp(dataToBeSent)).then((res) => {
      setSearchResult(res.data.data);
    });
  };
 
  useEffect(() => {
    dispatch(isYearlyAction(interval === 'year'));
    getQRCodes();
    getItemsByUser("", "", "recent");
    getUsers()
  }, []);

  const getQRCodes = async () => {
    setIsLoading(true);
    dispatch(getQRsGenOp()).then((res) => {
      dispatch(setQRCodes(res));
      setIsLoading(false);
    });
  };

  const getItemsByUser = (perPage, pageNum, type) => {
    setIsLoading(true);
    dispatch(getItemsByUserGenOp(perPage, pageNum, type)).then((res) => {
      dispatch(setItems(res));
      getItemsByUserCount();
      setIsLoading(false);
    });
  };

  const getItemsByUserCount = () => {
    setIsLoading(true);
    dispatch(getItemsByUserGenOp()).then((res) => {
      console.log("Inventory Summary res.count", res);

      setItemCount(res.count);
      setIsLoading(false);
    });
  };

  const getUsers = () => {
    setIsLoading(true);
    dispatch(getUserByAdminGenOp())
      .then((res) => {
        setUsersCount(res?.data?.length || 0);
      
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Error", error);
      });
  };

  const viewItem = (item) => {
    navigate(`/web/item/${item.labelId}/${item._id}/view`, {
      state: {
        data: item,
        isView: true,
      },
    });
  };

  const genQR = async () => {
    setIsLoading(true);
    const qrCodes = 1;
    const body = {
      qrCodes: qrCodes,
    };

    if (count >= labels) {
      setIsLoading(false);
      // Show an error toast if the count exceeds the limit set by labels
      toast.error(
        "You have reached your limit of this plan. Upgrade your plan to add more."
      );
    } else {
      dispatch(createQRGenOp(body)).then((res) => {
        // Allow adding if count is less than labels
        navigate("/web/items/box/add", {
          state: {
            box: res.data.data,
            isBox: true,
            customFieldsCount: customFieldsCount,
          },
        });
      });
    }
  };

  const handleGenerateLabels = () => {
    if (count === labels) {
      toast.error(
        "You have reached your limit of this plan. Upgrade your plan to add more."
      );
    } else {
      setIsAddModal(true);
      // navigate("/web/box/add");
    }
  };

  const handlePageChange = (page) => {
    // getRequest(
    //   `${GET_ITEMS_BY_USER}?perPage=${perPage}&pageNum=${page}&type=${userId}`)
    //   .then((res) => {
    //     console.log("res from getItems", res.customFieldsCount);
    //     dispatch(setItems(res));
    //   });
  };

  const handlePerPageChange = (newPerPage, page) => {
    // getRequest(
    //   `${GET_ITEMS_BY_USER}?perPage=${newPerPage}&pageNum=${page}&type=${userId}`)
    //   .then((res) => {
    //     console.log("res from getItems", res.customFieldsCount);
    //     dispatch(setItems(res));
    //   });
    // setPerPage(newPerPage);
  };
  const openModal = (item) => {
    setModal(true);
    setDataId(item._id);
  };

  return (
    <div>
      <SEO
        title={`Scans Dashboard - Manage Your Inventory with Ease`}
        description={`Access your Scans dashboard to manage your inventory effortlessly. Get real-time insights, monitor your data, and optimize your storage management.`}
        keywords={`Inventory Management Dashboard, Manage Inventory Easily, Real-Time Inventory Insights, Optimize Storage Management, Scans Inventory Dashboard, Monitor Inventory Data, Efficient Inventory Management, Inventory Control System`}
      />
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <React.Fragment>
          {isAddModal && (
            <AddBox
              isAddModal={isAddModal}
              setIsAddModal={setIsAddModal}
              dataId={dataId}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          <div className="laptop:flex laptop:flex-col laptop:justify-center laptop:items-start laptop:ml-5 laptop:mt-5">
            <div className="flex w-full flex-wrap justify-between">
              <Card
                className="flex-auto flex  justify-between items-center h-20 lg:w-60 m-2 pl-10 pr-10 p-1"
                style={{
                  background: "linear-gradient(-20deg,#aabf72 0,#22b573 100%)",
                }}
              >
                <div className="flex  items-center">
                  <RxDashboard
                    size={25}
                    className="min-w-max text-white mr-2"
                  />
                  <p className="text-xl font-bold text-white">Items</p>
                </div>

                <div className="h-full bg-gray-200 w-1 rounded"></div>
                <p className="text-xl font-bold text-white">
                  {itemCount && (itemCount < 10 ? "0" + itemCount : itemCount)}
                </p>
              </Card>
              <Card
                className="flex-auto flex justify-between items-center h-20 lg:w-60 m-2 pl-10 pr-10 p-1"
                style={{
                  background: "linear-gradient(-20deg,#f89650 0,#f84450 100%)",
                }}
              >
                <div className="flex  items-center">
                  <HiOutlineClipboardDocumentList
                    size={25}
                    className="min-w-max text-white mr-2"
                  />
                  <p className="text-xl font-bold text-white">Boxes</p>
                </div>
                <div className="h-full bg-gray-200 w-1 rounded"></div>
                <p className="text-xl font-bold text-white">
                  {count && (count < 10 ? "0" + count : count)}
                </p>
              </Card>
              <Card className="flex-auto flex justify-between items-center h-20 lg:w-60 m-2 pl-10 pr-10 p-1" style={{
                background:
                  'linear-gradient(-20deg,#1fd2dc 0,#1f9bff 100%)',


              }}>
                <div className="flex  items-center">
                  <FaUsers
                    size={25}
                    className="min-w-max text-white mr-2"
                  />
                  <p className="text-xl font-bold text-white">Users</p>
                </div>
                <div className="h-full bg-gray-200 w-1 rounded"></div>
                <p className="text-xl font-bold text-white">
                  {usersCount && (usersCount < 10 ? "0" + usersCount : usersCount)}
                </p>
              </Card>
            </div>
            <div className="flex w-full flex-col justify-center items-center laptop:flex-row laptop:justify-between">
              <Search
                register={register}
                onChange={(e) => setSearchQuery(e.target.value)}
                errors={errors}
              />
              <div className=" flex items-center laptop:ml-auto laptop: flex laptop:flex-row laptop:items-center mb-3 ">
                <div>
                  {plan &&
                  plan?.length > 0 &&
                  plan[0].currentPlan === "Free" ? (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-3xl bg-themeBlue mr-5 px-6 py-3.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                      onClick={() => genQR()}
                    >
                      <IoMdAdd className="h-5 w-5 mr-1 text-white" />
                      <span>Add Box</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="inline-flex items-center rounded-3xl bg-themeBlue mr-5 px-6 py-3.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                      onClick={() => handleGenerateLabels()}
                    >
                      <IoMdAdd className="h-5 w-5 mr-1" />
                      <span>Generate Boxes</span>
                    </button>
                  )}
                </div>
                <Menu />
              </div>
            </div>

            <React.Fragment>
              {searchQuery.length > 0 &&
              searchResult &&
              searchResult.length > 0 ? (
                <React.Fragment>
                  <div className=" w-full">
                    <h5 className="mb-2 text-xl self-start font-bold leading-tight text-neutral-800">
                      Recent Items
                    </h5>

                    {isGrid ? (
                      <div className="flex flex-row flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 justify-items-center">
                        {searchResult?.length > 0 ? (
                          searchResult?.map((item) => (
                            <React.Fragment key={item._id}>
                              <HomeCardWithImage
                                title={item?.title}
                                image={item?.image[0]}
                                link=""
                                boxTitle={item?.labelTitle}
                                updatedAt={item?.updatedDate
                                }
                                onClick={() => viewItem(item)}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <div className="flex flex-row justify-center items-center">
                            <p>No Items are Availble</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="w-full">
                        <ItemsTable
                          data={searchResult}
                          // openModal={openModal}
                          viewItem={viewItem}
                          perPage={perPage}
                          handlePerPageChange={handlePerPageChange}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <div>
                    <h5 className="mb-2 text-xl font-bold leading-tight text-neutral-800">
                      Inventory Summary
                    </h5>
                    <div className="flex flex-col tablet:grid tablet:grid-cols-2 tablet:gap-2">
                      <HomeCard data={itemCount} type="Items" />
                      <HomeCard data={count} type="Boxes" />
                    </div>
                  </div> */}
                  <div className=" w-full">
                    <h5 className="mb-2 pl-3 text-xl self-start font-bold leading-tight text-neutral-800">
                      Recent Items
                    </h5>

                    {isGrid ? (
                      <div className="flex flex-row flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 justify-items-center">
                        {items?.data?.length > 0 ? (
                          items.data.map((item) => (
                            <React.Fragment key={item._id}>
                              <HomeCardWithImage
                                title={item?.title}
                                image={item?.image[0]}
                                link=""
                                boxTitle={item?.labelTitle}
                                updatedAt={item?.updatedDate                                }
                                onClick={() => viewItem(item)}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <div className="flex flex-row justify-center items-center">
                            <p>No Items are Availble</p>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="w-full">
                        <ItemsTable
                          data={items.data}
                          // openModal={openModal}
                          viewItem={viewItem}
                          perPage={perPage}
                          handlePerPageChange={handlePerPageChange}
                          handlePageChange={handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

export default Dashboard;
