import {
  getRequest,
  postRequest,
} from '../../components/interceptor/interceptor';
import {
  GET_SUBSCRIPTION_STATUS,
  CREATE_SUBSCRIPTION,
  ACTIVATE_PERSONAL,
  CANCEL_SUBSCRIPTION,
  DEACTIVATE_PERSONAL
} from '../../apiConst';

export const getSubscription = (id) => ({
  type: 'GET_SUBSCRIPTION',
  payload: getRequest(`${GET_SUBSCRIPTION_STATUS}${id}`),
});

export const setSubscription = (data) => ({
  type: 'SET_SUBSCRIPTION',
  payload: { ...data },
});

export const createSubscription = (data) => ({
  type: 'CREATE_SUBSCRIPTION',
  payload: postRequest(`${CREATE_SUBSCRIPTION}`, data),
});

export const activatePersonal = (data) => ({
  type: 'ACTIVATE_PERSONAL',
  payload: postRequest(`${ACTIVATE_PERSONAL}`, data),
});

export const cancelSubscription = (data) => ({
  type: 'CANCEL_SUBSCRIPTION',
  payload: postRequest(`${CANCEL_SUBSCRIPTION}`, data),
});

export const deactivatePersonalPlan = (data) => ({
  type: 'DEACTIVATE_PERSONAL',
  payload: postRequest(`${DEACTIVATE_PERSONAL}`, data),
});