/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import { useDispatch } from 'react-redux';
import { userLogoutOp } from './redux/operations/user.operations';
import moment from 'moment';

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    const userToken = localStorage.getItem('userAuthToken');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
      localStorage.removeItem('locationIp');
      localStorage.removeItem('userAuthToken');
      dispatch(userLogoutOp());
      return navigate('/login');
    } else {
      return setIsLoggedIn(true);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  useEffect(() => {
    const userToken = localStorage.getItem('userAuthToken');
    parseJwt(userToken);
  }, []);

  const parseJwt = (token) => {
    const decode = JSON.parse(atob(token.split('.')[1]));
    // console.log(decode);
    // console.log(moment.unix(decode.exp).format('DD-MM-YYYY hh:mm:ss A'));
    // console.log(decode.exp * 1000 < new Date().getTime());
    if (decode.exp * 1000 < new Date().getTime()) {
      localStorage.removeItem('locationIp');
      localStorage.removeItem('userAuthToken');
      dispatch(userLogoutOp());
      return navigate('/login');
    }
  };

  return (
    <React.Fragment>
      {isLoggedIn ? <RootLayout>{props.children}</RootLayout> : null}
    </React.Fragment>
  );
};
export default ProtectedRoute;
