import React from 'react'
import { Grid } from '@mui/material'
import Card from './Card'
import { TrackandUpdatedata } from '../../utils/FeaturesData'

const TrackandUpdate = () => {
  return (
    <>
     <div className="bg-blue-100">
    <h1 className='text-xl tablet:text-2xl laptop:text-3xl font-bold font-proximaNovaBlack text-blue-900 uppercase tracking-wider'>Track & Update</h1>
    <h1 className='font-proximaNovaBlack text-lg tablet:text-xl laptop:text-2xl pt-4'>Track & Update Organize your inventory.</h1>
    <p className='font-proximaNovaBlack text-gray-700 text-md laptop:text-lg'>Seamlessly update inventory and set alerts to ensure you always stock the right amount.</p>
    <div className="flex justify-center bg-blue-100 pt-7">
        <Grid container spacing={2}>
          {TrackandUpdatedata.map((trackandupdate, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <Card
                title={trackandupdate.title}
                description={trackandupdate.description}
                icon={trackandupdate.icon}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      </div>
    </>
   
  )
}

export default TrackandUpdate