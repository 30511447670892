const itemsState = {};

export const itemsReducer = (state = itemsState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_ITEMS':
      return {
        ...state,
        ...payload,
      };
    case 'GET_ITEMS':
      return {
        ...payload,
      };
    case 'REMOVE_ITEMS':
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
