import React from 'react';
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from 'tw-elements-react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function Modal({
  heading,
  firstButtonText,
  pressSecondButton,
  pressFirstButton,
  secondButtonText,
  pressCloseButton,
  body,
  modal,
  dataId,
}) {
  return (
    <div>
     
      {/* <TEModal show={modal}>
        <TEModalDialog 
        centered
      
        >
          <TEModalContent>
            <TEModalHeader className='bg-gray-100 '>
             
              <h5 className="text-xl font-medium leading-normal text-neutral-800">
                {heading}
              </h5>
            
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => pressCloseButton()}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                > 
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
         
            <TEModalBody>{body}</TEModalBody>
            {firstButtonText || secondButtonText ? (
              <TEModalFooter className="flex justify-between bg-gray-100">
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className="inline-block rounded bg-danger px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:bg-danger focus:bg-danger focus:outline-none focus:ring-0 active:bg-danger w-24 laptop:w-48"
                    onClick={() => pressFirstButton()}
                  >
                    {firstButtonText}
                  </button>
                </TERipple>
                <TERipple rippleColor="light">
                  <button
                    type="button"
                    className=" inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] w-24 laptop:w-48"
                    onClick={() => pressSecondButton(dataId)}
                  >
                    {secondButtonText}
                  </button>
                </TERipple>
              </TEModalFooter>
            ) : null}
          </TEModalContent>
        </TEModalDialog>
      </TEModal> */}
      <React.Fragment>
      <BootstrapDialog
        onClose={pressCloseButton}
        aria-labelledby="customized-dialog-title"
        open={modal}
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 1,backgroundColor:'#20488a', color:'white' }} id="customized-dialog-title">
        {heading}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => pressCloseButton()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        {body}
        </DialogContent>
        {firstButtonText || secondButtonText ? (
        <DialogActions sx={{backgroundColor:'#e6e9ed'}}>
       
            <div className="flex justify-around w-full">
                <button
                  className="inline-flex px-6 py-2 text-blue-900 rounded-3xl border border-solid border-blue-900-600 hover:text-white hover:bg-blue-900 focus:bg-blue-900"
                  onClick={() => pressFirstButton()}
                >
                  {firstButtonText}
                </button>
                <button
                  className="inline-flex px-6 py-2 text-white rounded-3xl  bg-blue-900 hover:bg-blue-800 focus:bg-blue-900" 
                  style={{backgroundColor:secondButtonText==="Delete"?'#DC2626':secondButtonText==='Logout'?'#DC2626':null}}
                  onClick={() => pressSecondButton(dataId)}
                >
                   {secondButtonText}
                </button>
              </div>
           
        </DialogActions>
         ):null}
      </BootstrapDialog>
    </React.Fragment>

      
    </div>
  );
}



// {modal && <div className="fixed inset-0 flex items-center justify-center z-50">
//           <div className="modal-overlay"></div>
//           <div className="modal-container bg-white mx-auto rounded-lg shadow-lg overflow-y-auto w-[600px]">
//             <div className="modal-header bg-blue-900 relative py-2">
//             <h5 className="text-lg laptop:text-xl text-white pl-5 font-medium leading-normal text-neutral-800">
//                 {heading}
//               </h5>
//               <button
//                 className="close-button absolute top-2 right-2 flex justify-center items-center w-6 h-6 pt-1  bg-gray-400 rounded-full"
//                 onClick={() => pressCloseButton()}
//               >
//                 <span className="text-white mb-1 ">&times;</span>
//               </button>
//             </div>
           

//             <div className="modal-content px-6 py-4 space-y-4">
//             {body}
//             </div>
//             {firstButtonText || secondButtonText ? (
//             <div className="flex justify-around w-full bg-gray-200 shadow-3xl p-3">
//                 <button
//                   className="inline-flex px-6 py-2.5 text-blue-900 rounded-3xl border border-solid border-blue-900-600 hover:text-white hover:bg-blue-900 focus:bg-blue-900"
//                   onClick={() => pressFirstButton()}
//                 >
//                   {firstButtonText}
//                 </button>
//                 <button
//                   className="inline-flex px-6 py-2.5 text-white rounded-3xl  bg-blue-900 hover:bg-blue-800 focus:bg-blue-900"
//                   onClick={() => pressSecondButton(dataId)}
//                 >
//                    {secondButtonText}
//                 </button>
//               </div>
//             ):null}
//           </div>
//           <style jsx>{`
//             .modal-overlay {
//               background-color: rgba(0, 0, 0, 0.7);
//               position: fixed;
//               top: 0;
//               left: 0;
//               right: 0;
//               bottom: 0;
//               z-index: 49; /* Ensuring it's behind the modal container */
//               pointer-events: auto; /* Enable pointer events */
              
//             }

//             .modal-container {
//               max-width: 90%;
//               max-height: 90vh;
//               overflow-y: auto;
//               margin: 2rem auto;
//               z-index: 50;
//             }

//             .modal-content {
//               max-height: calc(100vh - 4rem);
//             }
//           `}</style>
//         </div>
// }
