import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from 'react';
import Organize from './Organize';
import Customize from './Customize';
import Manage from './Manage';
import TrackandUpdate from './TrackandUpdate';
import Report from './Report';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Featuretabs = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, boederTop:1, borderColor: 'divider' }} className="mobile:hidden tablet:block">
        <Tabs
         centered
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
          
        >
          <Tab label="Organize" className="font-bold text-gray-700 m-4" {...a11yProps(0)} />
          <Tab label="Customize" className="font-bold text-gray-700 m-4" {...a11yProps(1)} />
          <Tab label="Manage" className="font-bold text-gray-700 m-4" {...a11yProps(2)} />
          <Tab label="Track & Update" className="font-bold text-gray-700 m-4" {...a11yProps(3)} />
          <Tab label="Report" className="font-bold text-gray-700 m-4" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="mobile:block tablet:hidden">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="scrollable auto tabs example"
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
         
          indicatorColor="primary"
        >
          <Tab label="Organize" className="font-bold text-gray-700 m-4" {...a11yProps(0)} />
          <Tab label="Customize" className="font-bold text-gray-700 m-4" {...a11yProps(1)} />
          <Tab label="Manage" className="font-bold text-gray-700 m-4" {...a11yProps(2)} />
          <Tab label="Track & Update" className="font-bold text-gray-700 m-4" {...a11yProps(3)} />
          <Tab label="Report" className="font-bold text-gray-700 m-4" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <Box sx={{ borderTop: 1, borderColor: 'divider', borderBottom: 1 }}>
        <TabPanel value={value} index={0}>
          <Organize />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Customize />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Manage />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TrackandUpdate />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Report />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default Featuretabs;
