import React from 'react';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { FaPaperPlane } from 'react-icons/fa';
import { MdFlightTakeoff } from 'react-icons/md';
import { GiAirBalloon } from 'react-icons/gi';
import { IoMdRocket } from 'react-icons/io';
import clsx from 'clsx';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 900],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));
const PlanCard = ({
  title,
  planType,
  disabled,
  onClick,
  description,
  buttonText,
  icon,
  price,
  userLicenses,
  fields,
  items,
  point1,
  point2,
  point3,
  point4,
  point5,
  isYearly
}) => {
console.log('isYearly',isYearly)
  return (
    <div
      className={`border border-solid p-5 shadow-xl bg-themeBodyBg mb-4 flex flex-col justify-between rounded-lg`}
      style={{ height: '800px' }}
    >
      {/* {title === "Premium" && <p className=" w-full bg-green-700 text-white font-sm font-bold text-center rounded-lg">Most Popular</p>} */}
      {icon === 'FaPaperPlane' ? (
        <span className="flex justify-center items-center">
          {<FaPaperPlane size={48} color="#020381" />}
        </span>
      ) : icon === 'GiAirBalloon' ? (
        <span className="flex justify-center items-center">
          {<GiAirBalloon size={48} color="#020381" />}
        </span>
      ) : icon === 'MdFlightTakeoff' ? (
        <span className="flex justify-center items-center">
          <MdFlightTakeoff size={48} color="#020381" />
        </span>
      ) : (
        <span className="flex justify-center items-center">
          <IoMdRocket size={48} color="#020381" />
        </span>
      )}
      {/* <img className="mx-auto mb-4" src={src} alt={title} /> */}
      <h2 className="text-2xl font-bold mb-2 text-center">{title}</h2>
      <p className="text-center mb-4">{description}</p>
      {price === 'Get a Quote' ? (
        <h1 className="text-3xl font-extrabold mb-10 text-center">{price}</h1>
      ) : (
        <h1 className="text-4xl font-extrabold mb-4 text-center">
          ${price}
          <span className="text-lg font-semibold"> {`/${isYearly?'Year':'Month'}`}.</span>
        </h1>
      )}
      <button
        disabled={disabled}
        className={`${
          disabled
            ? 'bg-gray-300 px-4 py-2 rounded-md cursor-not-allowed opacity-50'
            : 'border-themeBlue border border-solid hover:bg-themeBlue hover:text-white rounded-3xl text-themeBlue py-2.5 px-6 mx-auto mb-4'
        }`}
        onClick={onClick}
      >
        {buttonText}
      </button>
      <div className="text-sm">
        <h3 className="mb-2 font-bold text-gray-700">
          {userLicenses} User Licenses
        </h3>
        <BorderLinearProgress variant="determinate" value={userLicenses} />
      </div>
      <div className="text-sm">
        <div className="mb-2 font-bold text-gray-700">
          {fields === 'unlimited' ? 'Unlimited' : fields} Customizable Fields
        </div>
        <BorderLinearProgress
          variant="determinate"
          value={fields === 'unlimited' ? 100 : fields === 100 ? 50 : fields}
        />
      </div>
      <div className="text-sm">
        <div className="mb-2 font-bold text-gray-700">{items} Items</div>
        <BorderLinearProgress
          variant="determinate"
          value={
            items === 'Unlimited'
              ? 100
              : items === 200
              ? 20
              : items === 2000
              ? 60
              : 0
          }
        />
      </div>
      <div className=" h-40 overflow-scroll">
        <h2 className="text-md mt-1 mb-1 font-bold">Features</h2>
        {point1 && <p className="text-sm mb-2">{point1}</p>}
        {point2 && <p className="text-sm mb-2">{point2}</p>}
        {point3 && <p className="text-sm mb-2">{point3}</p>}
        {point4 && <p className="text-sm mb-2">{point4}</p>}
        {point5 && <p className="text-sm mb-2">{point5}</p>}
      </div>
    </div>
  );
};

export default PlanCard;
