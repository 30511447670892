import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Navbar from './Navbar.jsx';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography, Container,

} from "@mui/material";

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Footer from './Footer.js';

function Faq() {
  let isTabletMid = useMediaQuery({ query: '(max-width: 639px)' });
  const [faqData, setFaqData] = useState([]);
  const [expanded, setExpanded] = useState(false);




  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  useEffect(() => {
    import('../utils/faqData.js').then((data) => {
      setFaqData(data.faqData);
    });
  }, []);

  return (
    <div className="min-h-screen bg-blue-100">
      <div className="bg-blue-100   tablet:mx-auto">
        {/* <Navbar /> */}
        {/* <div
          className={`p-6 py-4 ${isTabletMid ? 'text-center' : 'text-right'}`}
        >
          <a
            href={isTabletMid ? 'exp+scan-qr://helpandsupport' : '/'}
            className="px-8 bg-indigo-500 hover:bg-indigo-500 text-white rounded-lg font-semibold py-2"
          >
            Home
          </a>
        </div> */}
        <Container maxWidth="xl" >
          <Typography variant="h4" gutterBottom className="text-blue-900 font-bold p-3">
            Frequently Asked Questions
          </Typography>
          <p className="text-blue-900 text-md p-3">
            Welcome to the ScanQR Help and Support page. Here you'll
            find information, FAQs, and guidance to help you make the
            most of our QR code scanning app.
          </p>
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              className="m-2"
            >
              <AccordionSummary
                expandIcon={<KeyboardDoubleArrowDownIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography variant="h6" className="text-gray-900 font-semibold"><span className="text-md font-bold text-blue-900">Q:</span>{faq.header}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="text-gray-800">{faq.text}</Typography>
                {faq.list && (
                  <ul className="list-disc pl-4 mt-1">
                    {faq.list.map((item, itemIndex) => (
                      <li key={itemIndex}>
                        <Typography className="text-base text-gray-600">
                          {item.title}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Container>
        {/* <div className={`py-2 ${isTabletMid ? 'text-center' : 'text-right'}`}>
          <a
            href={isTabletMid ? 'exp+scan-qr://helpandsupport' : '/'}
            className="px-8 bg-indigo-500 hover:bg-indigo-500 text-white rounded-lg font-semibold py-2"
          >
            Home
          </a>
        </div> */}
        {/* <Footer/> */}
      </div>
    </div>
  );
}

export default Faq;
