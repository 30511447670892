import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { Grid } from "@mui/material";
import Footer from "../../components/Footer";
import PlanCard from "./PlanCard";
import { useDispatch } from "react-redux";
import { planDetails } from "../../utils/PlanDetails";
import { isYearlyAction } from "../../redux/actions/term.action";
import { getPlansGenOp } from "../../redux/operations/plans.operations";
import ComparePlansTable from "./ComparePlansTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SEO from "../../components/SEO";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
const Subscription = () => {
  const [plansData, setPlansData] = useState([]);
  const [isLoading,setIsLoading]=useState(false)
  const { isYearly } = useSelector((state) => state.term);
  const token = useSelector((state) => state.user.token);
  const userPlan = useSelector((state) => state.user.plan);
  const dispatch = useDispatch();
  // console.log(
  //   '🚀 ~ file: Subscription.js:24 ~ Subscription ~ userPlan:',
  //   userPlan
  // );

  useEffect(() => {
    getAllPlans();
  }, []);
  const getAllPlans = async () => {
    try {
      setIsLoading(true)
      await dispatch(getPlansGenOp()).then((res) => {
        if (res && res.data) {
          if (planDetails.length > 0 && res.data.length > 0) {
            // Ensure that the lengths of both arrays are the same
            const length = Math.min(planDetails.length, res.data.length);

            const mergedData = planDetails
              .slice(0, length)
              .map((plan, index) => {
                const apiPlan = res.data[index];
                return {
                  ...plan,
                  ...apiPlan.metadata,
                };
              });

            console.log("mergedData", mergedData);

            setPlansData(mergedData);
            setIsLoading(false)
          } else {
            setIsLoading(false)
            console.error("Either planDetails or res.data is empty.");
          }
        } else {
          setIsLoading(false)
          console.error("API response is invalid or empty.");
        }
      });
    } catch (error) {
      setIsLoading(false)
      console.error("An error occurred while fetching plans:", error);
    }
  };
  const navigate = useNavigate();

  const toggleYearly = () => {
    dispatch(isYearlyAction(!isYearly));
  };

  const goToCheckout = (plan) => {
   
      navigate("/checkout", {
        state: {
          data: plan,
          isCheckout: true,
          isYearly: isYearly,
        },
      });
    
  };

  return (
    <>
      {/* {!token ? <Navbar /> : null} */}
      <SEO
        title={`Discover Scans Pricing Plans - Find the Perfect Plan for Your Needs`}
        description={`Explore Scans' pricing plans and find the perfect one for your inventory management needs. Start your free trial today.`}
        keywords={`Scans Pricing Plans, Inventory Management Pricing, Find the Perfect Plan, Scans Free Trial, Inventory Software Pricing, Affordable Inventory Management, Scans Pricing Options, Best Inventory Management Plans`}
      />

      <div className="laptop:flex laptop:flex-col laptop:justify-center m-0 laptop:pl-16 laptop:pr-16 desktop:pl-18 pt-10 desktop:pr-18 bg-blue-100">
        {/* {token ? ( */}
          <header className="w-full pt-12 flex flex-col justify-center items-center pb-10">
            <h2 className="text-4xl w-full text-themeHeadingText laptop:mt-15 laptop:text-6xl desktop:text-[4rem] laptop:mr-24 laptop:ml-24 font-proximaNovaBlack text-center">
              Start Your 14-Day Free Trial Today.
            </h2>

            <p className="w-full text-md tablet:text-xl laptop:text-2xl mt-8 pr-5 pl-5 text-center">
              Transform how your business does inventory with our powerful,
              easy-to-use solution. Find the right Scans plan for you.
            </p>

            <div className="flex justify-center items-center text-center mt-10 mb-2">
              <div className="bg-red-200 h-16 py-2 px-4 w-full rounded-xl text-center">
                <p className="text-red-700 font-extrabold text-sm">Save up to 30% on Yearly</p>
              </div>
              <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                <label className="themeSwitcherThree relative inline-flex cursor-pointer select-none items-center">
                  <input
                    type="checkbox"
                    checked={isYearly}
                    onChange={toggleYearly}
                    className="sr-only"
                  />
                  <div className="shadow-card border-solid border border-gray-900 p-1 flex h-12 w-36 items-center justify-center rounded-3xl bg-white">
                    <span
                      className={`flex h-full w-full items-center font-bold justify-center rounded-3xl font-small ${
                        !isYearly ? "bg-primary text-white" : "text-body-color"
                      }`}
                    >
                      Monthly
                    </span>
                    <span
                      className={`flex h-full w-full items-center font-bold justify-center rounded-3xl font-small ${
                        isYearly ? "bg-primary text-white" : "text-body-color"
                      }`}
                    >
                      Yearly
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </header>
        {/* ) : null} */}
      </div>
      <div className="flex justify-center w-full bg-blue-100 p-5 m-0">
        {isLoading?(<FullScreenLoader/>):(
              <Grid container spacing={2}>
              {plansData&&plansData?.map((plan, index) => (
                <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                  <PlanCard
                    title={plan.title}
                    description={plan.descriptionText}
                    buttonText={
                      token && plan.title === "Free" && userPlan?.length === 0
                        ? "Get Started"
                        : token &&
                          (plan.title === "Pro" || plan.title === "Premium") &&
                          userPlan?.length === 0
                        ? "Start Free Trial"
                        : plan.buttonText
                    }
                    planType={userPlan?.length > 0 ? "Paid" : "Trial"}
                    price={isYearly ? plan.yearly_price : plan.price}
                    userLicenses={plan.userLicenses}
                    fields={plan.fields}
                    items={plan.items}
                    onClick={() =>
                      !token
                        ? navigate("/web/login")
                        : goToCheckout({
                            ...plan,
                            price: isYearly ? plan.yearly_price : plan.price,
                          })
                    }
                    point1={plan.point1}
                    point2={plan.point2}
                    point3={plan.point3}
                    point4={plan.point4}
                    point5={plan.point5}
                    icon={plan.icon}
                    isYearly={isYearly}
                  />
                </Grid>
              ))}
            </Grid>

        )}
    
      </div>

      {!token ? (
        <React.Fragment>
          <ComparePlansTable />
        </React.Fragment>
      ) : null}
      {/* {!token ? <Footer /> : null} */}
    </>
  );
};

export default Subscription;
