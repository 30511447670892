export const itemFieldsWithoutBox = [
  {
    fieldName: 'title',
    fieldTitle: 'Title',
    placeholder: 'Item Title',
    validationObj: {
      required: 'Please Enter Item Title',
      maxLength: {
        value: 52,
        message: 'Character Limit Exceeded, Max Allowed Is 52!',
      },
    },
    fieldType: 'text',
  },
  {
    fieldName: 'labelId',
    fieldTitle: 'Box',
    fieldType: 'select',
    options: [],
    validationObj: {
      required: 'Please Select a box!',
    },
  },
  {
    fieldName: 'description',
    fieldTitle: 'Description',
    placeholder: 'Item Description',
    validationObj: {
      maxLength: {
        value: 250,
        message: 'Character Limit Exceeded,  Max Allowed Is 250!',
      },
    },
    fieldType: 'textarea',
  },
];
