import React from 'react';
import { Oval } from 'react-loader-spinner';

const FullScreenLoader = () => {
  return (
    <div className='full-screen__loader'>
      {/* <Oval
        height={150}
        width={150}
        color='#fff'
        wrapperStyle={{}}
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor='#5b8c51'
        strokeWidth={2}
        strokeWidthSecondary={2}
      /> */}
      {/* <Loader type="Oval" color="#fff" height="150" width="150" /> */}
      
    
      <div
        className="inline-block h-10 w-10 animate-[spinner-grow_0.40s_linear_infinite] rounded-full bg-current align-[-0.125em] text-primary opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span>
      </div>
      <div
        className="inline-block h-10 w-10 animate-[spinner-grow_0.40s_linear_infinite] rounded-full bg-current align-[-0.125em] text-secondary opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span>
      </div>
      <div
        className="inline-block h-10 w-10 animate-[spinner-grow_0.40s_linear_infinite] rounded-full bg-current align-[-0.125em] text-success opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span>
      </div>
      <div
        className="inline-block h-10 w-10 animate-[spinner-grow_0.40s_linear_infinite] rounded-full bg-current align-[-0.125em] text-danger opacity-0 motion-reduce:animate-[spinner-grow_1.5s_linear_infinite]"
        role="status">
        <span
          className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
        >Loading...</span>
      </div>
      
      
   

    </div>
  );
};

export default FullScreenLoader;
