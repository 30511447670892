import {
  addItemAction,
  getItemsAction,
  getItemsByUserAction,
  updateItemAction,
  removeItemAction,
} from '../actions/items.action';

const addItemGenOp = (data) => async (dispatch) => {
  const response = await dispatch(addItemAction(data));
  return response.payload;
};

const getItemsGenOp = (data) => async (dispatch) => {
  const response = await dispatch(getItemsAction(data));
  return response.payload;
};

const getItemsByUserGenOp = (type,pageNumber, perPage) => async (dispatch) => {
  const response = await dispatch(
    getItemsByUserAction(type,pageNumber, perPage)
  );
  return response.payload;
};

const updateItemGenOp = (id, data) => async (dispatch) => {
  const response = await dispatch(updateItemAction(id, data));
  return response.payload;
};

const removeItemGenOp = (data) => async (dispatch) => {
  const response = await dispatch(removeItemAction(data));
  return response.payload;
};

export {
  addItemGenOp,
  getItemsGenOp,
  getItemsByUserGenOp,
  updateItemGenOp,
  removeItemGenOp,
};
