import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';

import {isGridAction} from '../../redux/actions/menu.action'
import { useDispatch, useSelector } from 'react-redux';

export default function BasicMenu() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const dispatch=useDispatch()

    const {isGrid} =useSelector((state)=>state.menu)
    console.log('isGrid',isGrid)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTable = () => {
        
        dispatch(isGridAction(false))
        setAnchorEl(null);
    };

    const handleGrid = () => {
        dispatch(isGridAction(true))
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={anchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                variant="outlined"
                className='rounded-3xl px-5 py-2.5'
                onClick={handleClick}
                color={isGrid ? 'secondary' : 'primary'}
            >
                {isGrid ? (
                    <GridViewIcon color="secondary" sx={{ marginRight: '0.5rem' }} />
                ) : (
                    <TableRowsIcon color="primary" sx={{ marginRight: '0.5rem' }} />
                )}
                View
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled>Layout Type</MenuItem>
                <MenuItem disabled={!isGrid} onClick={handleTable}>
                    <TableRowsIcon  color="primary" sx={{ marginRight: '0.5rem' }} />
                    Table
                </MenuItem>
                <MenuItem disabled={isGrid} onClick={handleGrid}>
                    <GridViewIcon color="secondary" sx={{ marginRight: '0.5rem' }} />
                    Grid
                </MenuItem>
            </Menu>
        </div>
    );
}
