import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QrcodePlugin from '../QRCodePlugin';
import { addQRGenOp } from '../../redux/operations/qrCodes.operations';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FullScreenLoader from '../Loader/FullScreenLoader';

function AddQR() {
  const userData = useSelector((state) => state.user);
  // eslint-disable-next-line
  const [QRcode, setQRcode] = useState('');

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const onNewScanResult = (decodedText) => {
    const domain = 'https://www.scanqr.ai/';
    const checkDomain = decodedText.includes(domain);
    if (checkDomain) {
      const fetchedLabelId = decodedText.split('/').pop();
      if (userData.labels && userData.labels.length === 10) {
        toast.error('QR code limit exceeded!');
      } else if (
        userData.labels &&
        userData.labels.length > 0 &&
        userData.labels.find((i) => i.labelId === fetchedLabelId) !== undefined
      ) {
        navigate(`/web/items/${fetchedLabelId}/view`);
      } else {
        setIsLoading(true);
        const labelSerial = userData.labels.length + 1;
        const body = {
          title: `QR-CODE-${labelSerial}`,
          labelId: fetchedLabelId,
          userId: userData._id,
          products: [],
        };
        dispatch(addQRGenOp(body))
          .then((res) => {
            toast.success(res.message);
            setIsLoading(false);
          })
          .then(navigate(`/web/qr/qr-codes`));
      }
    } else {
      toast.error('QR CODE is invalid!');
    }
  };

  return (
    <React.Fragment>
      {isLoading && <FullScreenLoader />}
      <div className="w-auto laptop:w-3/6">
        <QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      </div>
      <div>{QRcode && <h5>{QRcode}</h5>}</div>
    </React.Fragment>
  );
}

export default AddQR;
