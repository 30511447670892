import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

function RenderCustomInput({
  index,
  selected,
  selectedRadio,
  fieldTitle,
  fieldId,
  fieldName,
  fieldType,
  fieldLabel,
  onChange,
  onDateChange,
  onChangePhone,
  onChangeDropdown,
  onChangeCheckBox,
  onBlur,
  register,
  errors,
  placeholder,
  disabled,
  className,
  validationObj,
  maxLength,
  ref,
  defaultValue,
  value,
  setValue,
  keyDown,
  fieldOptions,
  Controller,
  control,
  dateValue,
  phoneNumber,
  dropdownOptions,

}) {
  const [date, setDate] = React.useState(dateValue);
  console.log("fieldType-----------:", fieldType);
  console.log("dateValue-----------:", dateValue);
  console.log("fieldOptions-------:", fieldOptions);
  console.log("dropdownOptions-------:", dropdownOptions);
  console.log("isArray(fieldOptions)", Array.isArray(fieldOptions));
  const handleChange = (dateChange, fieldName) => {
    const formattedDate = moment(dateChange).format("MM-DD-YYYY");
    setValue("date", formattedDate, {
      shouldDirty: true,
    });
    setDate(formattedDate);
    onDateChange(formattedDate, fieldName);
  };
  return (
    <div className="flex flex-col mb-6 w-full laptop:w-3/6" key={index}>
      {fieldType === "description" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <textarea
            name={fieldName}
            id={fieldId}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              ...validationObj,
            })}
            className="shadow appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
          />
        </React.Fragment>
      ) : fieldType === "text" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <input
            id={fieldId}
            name={fieldName}
            maxLength={maxLength}
            ref={ref}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              ...validationObj,
            })}
            type={fieldType}
            className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={keyDown}
          />
        </React.Fragment>
      ) : fieldType === "email" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <input
            id={fieldId}
            name={fieldName}
            maxLength={maxLength}
            ref={ref}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              pattern: {
                value: /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/g,
                message: "Enter a valid email.",
              },
            })}
            type={fieldType}
            className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={keyDown}
          />
        </React.Fragment>
      ) : fieldType === "dropdown" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <div className="flex flex-row">
            <select
              id={fieldName}
              name={fieldName}
              className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
              {...register(fieldName, {
                onChange: (e) => onChangeDropdown && onChangeDropdown(e),
              })}
             
              placeholder={"select"}
            >
              <option value="">Select</option>
              {Array.isArray(dropdownOptions)
                ? dropdownOptions.map((field, index) => (
                    <option key={field.key} value={field.title} selected={fieldOptions.title===field.title?'selected':''}>
                      {field.title}
                    </option>
                  ))
                : Object.entries(fieldOptions).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
            </select>
          </div>
        </React.Fragment>
      ) : fieldType === "check_box" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <div className="flex flex-row">
            {Array.isArray(fieldOptions) ? (
              <div>
                {fieldOptions?.map((field, index) => (
                  <div key={field.key}>
                    <div className="mt-2 mb-2 ml-2">
                      <input
                        id={field.key}
                        name={fieldName}
                        className="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 mt-2 mb-2"
                        type="checkbox"
                        {...register(fieldName, {
                          onChange: (e) =>
                            onChangeCheckBox && onChangeCheckBox(e),
                        })}
                        value={field.title}
                        defaultChecked={field.isChecked}
                      ></input>
                      <label className="ml-2 text-md font-medium text-gray-900">
                        {field.title}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {Object.entries(fieldOptions).map(([key, value], index) => (
                  <div key={key}>
                    <div className="mt-2 mb-2 ml-2">
                      <input
                        id={key}
                        name={fieldName}
                        className="w-6 h-6 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 mt-2 mb-2"
                        type="checkbox"
                        {...register(fieldName, {
                          onChange: (e) =>
                            onChangeCheckBox && onChangeCheckBox(e),
                        })}
                        value={value}
                        defaultChecked={selected[key]}
                      ></input>
                      <label className="ml-2 text-md font-medium text-gray-900">
                        {value}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </React.Fragment>
      ) : fieldType === "number" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <input
            id={fieldId}
            name={fieldName}
            maxLength={maxLength}
            ref={ref}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              ...validationObj,
            })}
            type={fieldType}
            className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={keyDown}
          />
        </React.Fragment>
      ) : fieldType === "decimal" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <input
            id={fieldId}
            name={fieldName}
            maxLength={maxLength}
            ref={ref}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              pattern: {
                value: /^\d+(\.\d{1,2})?$/,
                message: "Enter a valid decimal number.",
              },
            })}
            type="number"
            min={0.01}
            step="0.01"
            max={9999.99}
            className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={keyDown}
          />
        </React.Fragment>
      ) : fieldType === "date" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <Controller
            name={fieldName}
            control={control}
            defaultValue={dateValue}
            rules={{
              value:
                /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
              message: "Enter a valid date",
            }}
            render={() => (
              <DatePicker
                className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                dateFormat="MM-dd-YYYY"
                selected={date}
                placeholderText="Select date"
                onChange={(e) => handleChange(e, fieldName)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
              />
            )}
          />
        </React.Fragment>
      ) : fieldType === "phone_number" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <PhoneInputWithCountry
            rules={{
              validate: (value) =>
                value && value.length > 0 && isPossiblePhoneNumber(value),
              message: "Enter a valid phone number",
              onChange: (e) => onChangePhone && onChangePhone(e),
            }}
            flags={flags}
            className="shadow appearance-none border-1 rounded w-full py-2 px-4 text-gray-700 leading-tight"
            defaultCountry="US"
            name={fieldName}
            control={control}
            defaultValue={phoneNumber}
            placeholder="Phone Number"
            //onChange={(e, fieldName) => onChangePhone(e, fieldName)}
          />
          {errors[fieldName] && (
            <p className="py-2 text-danger">Enter a valid phone number!</p>
          )}
        </React.Fragment>
      ) : fieldType === "website" ? (
        <React.Fragment>
          <label className="block text-gray-800 font-bold mb-3 pr-4">
            {fieldTitle}
          </label>
          <input
            id={fieldId}
            name={fieldName}
            maxLength={maxLength}
            ref={ref}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              pattern: {
                value:
                  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
                message: "Enter a valid web address",
              },
            })}
            type="text"
            className="shadow appearance-none border-1 border-gray-800 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={keyDown}
          />
        </React.Fragment>
      ) : null}
      <p className="py-2 text-danger">
        {errors[fieldName] && errors[fieldName].message}
      </p>
    </div>
  );
}

export default RenderCustomInput;
