import {
  addQRAction,
  createQRAction,
  getQRsAction,
  updateQRAction,
  removeQRAction,
} from '../actions/qrCodes.action';

const createQRGenOp = (data) => async (dispatch) => {
  const response = await dispatch(createQRAction(data));
  return response.payload;
};

const addQRGenOp = (data) => async (dispatch) => {
  const response = await dispatch(addQRAction(data));
  return response.payload;
};

const getQRsGenOp = (pageNumber, perPage) => async (dispatch) => {
  const response = await dispatch(getQRsAction(pageNumber, perPage));
  return response.payload;
};

const updateQRGenOp = (labelId, data) => async (dispatch) => {
  const response = await dispatch(updateQRAction(labelId, data));
  return response.payload;
};

const removeQRGenOp = (data) => async (dispatch) => {
  const response = await dispatch(removeQRAction(data));
  return response.payload;
};

export { addQRGenOp, createQRGenOp, getQRsGenOp, updateQRGenOp, removeQRGenOp };
