/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { get, isEmpty } from 'lodash';
import FullScreenLoader from '../../components/Loader/FullScreenLoader';
import { FaDownload } from 'react-icons/fa6';
import Modal from '../../components/Modal';
function ViewBox({isViewModal,setIsViewModal,box,dataId,isLoading,setIsLoading}) {
  // const location = useLocation();
  // const [box, setBox] = useState();
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (!isEmpty(get(location, 'state.data'))) {
  //     setIsLoading(true);
  //     const { isBoxView } = get(location, 'state');
  //     if (isBoxView) {
  //       setBox(location.state.data);
  //       setIsLoading(false);
  //     }
  //   }
  // }, []);

  const downloadQR = (imageUrl) => {
    const link = document.createElement('a');
  link.href = imageUrl;
  link.download = 'QRCode.png'; // Name for the downloaded file
  
  // Trigger a click event on the link to download the file
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Remove the link after download
  
  // Close the popup
  setIsViewModal(false);
  };

  return (
    <Modal
    dataId={dataId||''}
    firstButtonText={'Cancel'}
    secondButtonText={'Download'}
    heading={'View Box'}
    body={
      <div className="flex flex-col justify-between items-center">
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <React.Fragment>
          <img
            src={box?.qrImageUrl}
            alt="box"
            className="h-24 w-24 tablet:h-48 tablet:w-48 laptop:h-96 laptop:w-96"
          />
          <h5 className="text-xl tablet:text-2xl laptop:text-3xl">
            {box?.title}
          </h5>
          <p className="text-xs">{box?.labelId}</p>
          {/* <button
            className="text-white bg-themeBlue mt-2 mb-2 focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
            onClick={() => downloadQR(box?.qrImageUrl)}
          >
            Download QR <FaDownload className="inline" />
          </button> */}
        </React.Fragment>
      )}
    </div>
    }
    modal={isViewModal}
    pressSecondButton={() => downloadQR(box?.qrImageUrl)}
    pressFirstButton={() => setIsViewModal(false)}
    pressCloseButton={() => setIsViewModal(false)}
  />
    
    
  );
}

export default ViewBox;
