/* eslint-disable react-hooks/exhaustive-deps */
// Boxes.js

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CardComponent from "../../components/CardComponent";
import {
  getQRsGenOp,
  removeQRGenOp,
  createQRGenOp,
} from "../../redux/operations/qrCodes.operations";
import { getRequest } from "../../components/interceptor/interceptor";
import { GET_QRs } from "../../apiConst";
import { setQRCodes } from "../../redux/actions/qrCodes.action";
import { FaEye, FaEdit } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { RiDeleteBin5Line } from "react-icons/ri";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { useNavigate } from "react-router";
import Modal from "../../components/Modal";
import { TERipple } from "tw-elements-react";
import SEO from "../../components/SEO";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import DataTable from "react-data-table-component";
import moment from "moment";
import { IconButton, Tooltip } from "@mui/material";
import BackButton from "../../common/BackButton";
/* eslint-disable react-hooks/exhaustive-deps */

import { useLocation } from "react-router";
import { get, isEmpty } from "lodash";
import EditBox from "./EditBox";
import ViewBox from "./ViewBox";
import AddBox from "./AddBox";

function Boxes() {
  const userData = useSelector((state) => state.user);
  const labels = useSelector((state) => state.qrCodes.labels);
  const count = useSelector((state) => state.qrCodes.count);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const [dataId, setDataId] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [box, setBox] = useState({});
  const type = "label";
  const navigate = useNavigate();

  const getQRCodes = (pageNumber) => {
    console.log("pageNumber", pageNumber);
    setIsLoading(true);
    dispatch(getQRsGenOp(pageNumber, perPage))
      .then((res) => {
        dispatch(setQRCodes(res));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching QR codes:", error);
      });
  };
  useEffect(() => {
    getQRCodes(1);
  }, []);

  const handlePageChange = (page) => {
    console.log("page+++++++++", page);
    getRequest(`${GET_QRs}?perPage=${perPage}&pageNum=${page}`)
      .then((res) => {
        dispatch(setQRCodes(res));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching QR codes:", error);
      });
  };

  const handlePerPageChange = (newPerPage, page) => {
    getRequest(`${GET_QRs}?perPage=${newPerPage}&pageNum=${page}`)
      .then((res) => {
        dispatch(setQRCodes(res));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching QR codes:", error);
      });
    setPerPage(newPerPage);
  };

  const wait = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };
  const gotoItems = (result) => {
    if (type === "label") {
      const labelId = result.labelId;
      if (result.products.length > 0) {
        navigate(`/web/item/${labelId}/view`, {
          state: {
            data: result,
            isView: true,
          },
        });
      } else {
        navigate(`/web/items/${labelId}/add`, {
          state: {
            data: result,
            isView: false,
          },
        });
      }
    }
  };

  const genQR = async () => {
    setIsLoading(true);
    const qrCodes = 1;
    const body = {
      qrCodes: qrCodes,
    };
    dispatch(createQRGenOp(body));
    wait(4000).then(() => {
      navigate("/web/items/box/add");
    });
  };

  const openBoxView = (box) => {
    setIsViewModal(true);
    setBox(box);
    // navigate('/web/box/view', {
    //   state: {
    //     data: box,
    //     isBoxView: true,
    //   },
    // });
  };

  const openBoxEdit = (box) => {
    console.log("box", box);
    setIsEditModal(true);
    setBox(box);
    // navigate('/web/box/edit', {
    //   state: {
    //     data: box,
    //     isBoxEdit: true,
    //   },
    // });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    dispatch(removeQRGenOp(id)).then((res) => {
      if (res) {
        dispatch(getQRsGenOp()).then((res) => {
          dispatch(setQRCodes(res));
          setIsLoading(false);
          setModal(false);
        });
      }
    });
  };

  const openModal = (id) => {
    setModal(true);
    setDataId(id);
  };
  const columns = [
    // {
    //   name: "SNo",
    //   selector: (row) => row._id,
    //   sortable: true,
    //   width: "75px"
    // },
    {
      name: "Title",
      selector: (row) => (
        <button
          className="text-blue-900 underline"
          onClick={() => gotoItems(row)}
        >
          {" "}
          {row.title}
        </button>
      ),
      sortable: true,
    },
    {
      name: "Items count",
      selector: (row) => row.products?.length,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
    },
    {
      name: "Updated Date",
      selector: (row) => moment(row.updatedAt).format("DD-MM-YYYY"),
      sortable: true,
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          <IconButton size="small" onClick={() => openBoxView(row)}>
            <Tooltip title="view QR code">
              <QrCodeScannerIcon color="primary" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => openBoxEdit(row)}>
            <Tooltip title="Edit">
              <EditIcon color="secondary" />
            </Tooltip>
          </IconButton>
          <IconButton size="small" onClick={() => openModal(row.labelId)}>
            <Tooltip title="Delete">
              <DeleteIcon color="error" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      width: "150px",
    },
  ];
  return (
    <div>
      <SEO
        title={`Inventory Labels with Scans - Organize and Identify Effortlessly`}
        description={`Create and manage inventory labels with Scans. Effortlessly organize and identify items for better inventory management.`}
        keywords={`Inventory Labels, Organize Inventory, Inventory Identification, Manage Inventory Labels, Scans Inventory Labels, Labeling Solutions, Inventory Management Tools, Efficient Inventory Organization`}
      />
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <React.Fragment>
          <Modal
            dataId={dataId}
            firstButtonText={"Cancel"}
            secondButtonText={"Delete"}
            heading={"Confirm Delete"}
            body={<p>Are you sure you want to delete box?</p>}
            modal={modal}
            pressSecondButton={(id) => handleDelete(id)}
            pressFirstButton={() => setModal(false)}
            pressCloseButton={() => setModal(false)}
          />
          {isEditModal && (
            <EditBox
              isEditModal={isEditModal}
              setIsEditModal={setIsEditModal}
              box={box}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          {isViewModal && (
            <ViewBox
              isViewModal={isViewModal}
              setIsViewModal={setIsViewModal}
              box={box}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
          <React.Fragment>
            <div className="laptop:flex laptop:flex-col m-2">
              <div className="flex flec-row justify-between items-center">
                <p className="mr-4 pl-1 tablet:mt-2 font-proximaNovaBold text-md tablet:text-2xl">
                  Inventory Boxes
                </p>
                <p className="mr-4 tablet:mt-2 font-proximaNovaBold text-md tablet:text-2xl">
                  Total Boxes:{" "}
                  {/* {labels?.length < 10 ? '0' + labels?.length : labels?.length} */}
                  <span className="text-blue-900 text-md tablet:text-2xl">
                    {count}
                  </span>
                </p>
              </div>

              {userData?.plan[0].length > 0 &&
              userData?.plan[0]?.status === "active" &&
              (userData?.plan[0]?.currentPlan === "Free" ||
                userData?.plan[0]?.currentPlan === "Personal") ? (
                <TERipple rippleColor="light" className="mt-2">
                  <button
                    type="button"
                    className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                    onClick={() => genQR()}
                  >
                    Add A Box
                  </button>
                </TERipple>
              ) : null}
            </div>
          </React.Fragment>
          {/* <div className="flex flex-col tablet:grid tablet:grid-cols-2 tablet:gap-2 laptop:gap-2 desktop:grid-cols-3">
            <CardComponent
              data={labels ? labels : []}
              type="label"
              viewIcon={<FaEye size={24} />}
              onViewClick={(box) => openBoxView(box)}
              editIcon={<FaEdit size={24} className="text-themeBlue" />}
              onEditClick={(box) => openBoxEdit(box)}
              deleteIcon={<RiDeleteBin5Line size={24} className="text-danger" />}
              onDeleteClick={(id) => openModal(id)}
           
            />
          </div> */}

          <div className="rounded-xl shadow-lg p-3">
            {labels?.length > 0 ? (
              <DataTable
                columns={columns}
                fixedHeader
                data={labels}
                pagination
                striped
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
                paginationPerPage={perPage}
                paginationRowsPerPageOptions={[
                  10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
                ]}
                paginationServer
                paginationTotalRows={count}
                paginationDefaultPage={1}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerPageChange}
              />
            ) : (
              <p>No Boxes available.</p>
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
const customStyles = {
  subHeader: {
    style: {
      backgroundColor: "blue",
    },
  },
  head: {
    style: {
      color: "black",
      fontSize: "13px",
      fontWeight: 600,
      minHeight: "2px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#ADC8E3",
      minHeight: "45px",
      borderBottomWidth: "1px",
      borderBottomColor: "#ffffff",
      borderBottomStyle: "solid",
    },
  },

  rows: {
    style: {
      fontSize: "13px",
      minHeight: "45px",
      fontWeight: 600,
    },
  },
};

export default Boxes;
