import React, { useState, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useForm } from "react-hook-form";
import InputField from "../common/InputField";
import { contactFields } from "../Fields/contactFields";
import { Grid } from "@mui/material";
import { toast } from "react-toastify";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import ReCAPTCHA from "react-google-recaptcha";
import { CONTACT_INFO } from "../apiConst";
import SEO from "../components/SEO";
import "react-phone-number-input/style.css";
import { postRequest } from "./interceptor/interceptor";


const ContactUs = () => {
  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState(false);
  const [contactPhoneAgreed, setContactPhoneAgreed] = useState(false);
  const [contactEmailAgreed, setContactEmailAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef = useRef(null);

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handlePrivacyPolicyChange = (e) => {
    setPrivacyPolicyAgreed(e.target.checked);
  };

  const handleContactPhoneChange = (e) => {
    setContactPhoneAgreed(e.target.checked);
  };

  const handleContactEmailChange = (e) => {
    setContactEmailAgreed(e.target.checked);
  };

  const handleCancel = () => {
    setPrivacyPolicyAgreed(false);
    setContactPhoneAgreed(false);
    setContactEmailAgreed(false);
    reset();
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const formData = {
        firstName: data.firstName,
        lastName: data.lastName,
        contact: data.contact,
        email: data.email,
        message: data.message,
        privacyPolicyAgreed: privacyPolicyAgreed ? "yes" : "no",
        contactPhoneAgreed: contactPhoneAgreed ? "yes" : "no",
        contactEmailAgreed: contactEmailAgreed ? "yes" : "no",
      };
      console.log("formData", formData);

      const response = await postRequest(`${CONTACT_INFO}`, formData);

      if (response.data && response.data.message) {
        toast.success(response.data.message, {
          autoClose: 800,
          onClose: () => {
            setIsLoading(false);
            reset();
            setPrivacyPolicyAgreed(false);
            setContactPhoneAgreed(false);
            setContactEmailAgreed(false);
          },
        });
      } else {
        toast.error(response.error, {
          autoClose: 700,
          onClose: () => {
            setIsLoading(false);
          },
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred", {
        autoClose: 500,
        onClose: () => {
          setIsLoading(false);
        },
      });
    }
  };

  return (
    <>
      <SEO
        title={`Contact Scans - Get in Touch with Our Team`}
        description={
          "Have questions or need support? Contact the Scans team for assistance with your inventory management needs. We're here to help."
        }
        keywords={
          "Inventory Management, Inventory Support, Contact Scans Team, Inventory Assistance, Scans Customer Support, Inventory Solutions, Get Help with Inventory, Inventory Management Questions"
        }
      />

      <div className="pr-8 pl-8 pb-8 pt-5 bg-blue-100">
        <div className="max-w-full mb-8 md:mb-0">
          <div className="block max-w-full rounded-xl bg-white shadow-lg">
            <div className="flex flex-row justify-center ">
              <div className="w-3/4 hidden laptop:block ">
                <img
                  src={"https://scanqr-app.s3.amazonaws.com/contact.webp"}
                  alt="contact image"
                  className="w-full h-full rounded-tl-xl rounded-bl-xl"
                />
              </div>
              <div className="flex flex-col justify-center m-8">
                <p className=" mb-3 text-xl font-medium font-bold leading-tight text-neutral-800">
                  Contact Form
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    {contactFields.map((field) => {
                      const {
                        fieldTitle,
                        fieldName,
                        fieldType,
                        fieldMaxLength,
                        validationObj,
                        disabled,
                      } = field;
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={fieldTitle === "Message*" ? 12 : 6}
                          lg={fieldTitle === "Message*" ? 12 : 6}
                          xl={fieldTitle === "Message*" ? 12 : 6}
                          key={fieldName}
                        >
                          <label className="block text-gray-800 font-bold mb-3 pr-4">
                            {fieldTitle}
                          </label>

                          {fieldName === "contact" ? (
                            <>
                            <PhoneInputWithCountry
                              flags={flags}
                              className="shadow appearance-none border-solid border-1 border-gray-800 rounded-md w-full py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                              defaultCountry="US"
                              name={fieldName}
                              control={control}
                              placeholder={fieldTitle}
                              rules={{
                                validate: (value) =>
                                  value && isPossiblePhoneNumber(value) || 'Enter a valid phone number',
                              }}
                            />
                          {errors.contact && <p className="text-red-500 pt-2 text-xs italic">{errors.contact.message}</p>}
                            </>
                          ) : (
                            <InputField
                              fieldName={fieldName}
                              validationObj={validationObj}
                              register={register}
                              maxLength={fieldMaxLength}
                              errors={errors}
                              fieldType={fieldType}
                              disabled={disabled}
                              placeholder={fieldTitle}
                            />
                          )}
                        </Grid>
                      );
                    })}
                  </Grid>
                  <div
                    style={{
                      marginBottom: "20px",
                      marginLeft: "10px",
                      marginTop: "15px",
                    }}
                  >
                    <div className="flex flex-col mb-2 text-base text-neutral-600">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          name="privacy_policy"
                          required
                          checked={privacyPolicyAgreed}
                          onChange={handlePrivacyPolicyChange}
                          className="form-checkbox h-4 w-4 text-green-600 rounded"
                        />
                        <span className="ml-2 text-sm">
                          I have read and agree to the Privacy Policy of SCANS
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col mb-2 text-base text-neutral-600">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          name="contact_phone"
                          required
                          checked={contactPhoneAgreed}
                          onChange={handleContactPhoneChange}
                          className="form-checkbox h-4 w-4 text-green-600 rounded"
                        />
                        <span className="ml-2 text-sm">
                          I agree to be contacted by Phone by SCANS
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col mb-2 text-base text-neutral-600">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          name="contact_email"
                          required
                          checked={contactEmailAgreed}
                          onChange={handleContactEmailChange}
                          className="form-checkbox h-4 w-4 text-green-600 rounded"
                        />
                        <span className="ml-2 text-sm">
                          I agree to be contacted by Email by SCANS
                        </span>
                      </div>
                    </div>
                    {/* <div className="flex flex-col items-center mb-2 text-base text-neutral-600">
                      <div className="m-4">
                      <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} 
                    onClick={handleCheck}
                    />
                   
                      </div>
                    </div> */}
                  </div>
                  <div className="flex flex-row justify-center items-center mt-5">
                    <button
                      type="button"
                      className="flex items-center max-w-sm mr-3 p-2  inline-block rounded-3xl border border-themeBlue border-solid bg-white text-themeBlue px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                      onClick={handleCancel}
                    >
                      <IoClose className="h-4 w-4 mr-2" />
                      <span>Cancel</span>
                    </button>

                    <button
                      type="submit"
                      disabled={isLoading}
                      className="flex items-center max-w-sm ml-3 p-2 inline-block rounded-3xl border border-themeBlue border-solid bg-white text-themeBlue px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                    >
                      {isLoading ? (
                        // <CircularProgress color="green" size={24} />
                        <p>Loading....</p>
                      ) : (
                        <>
                          <IoMdSend className="h-4 w-4 mr-2" />
                          <span>Submit</span>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
