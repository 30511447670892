import React from 'react';
import InputField from '../../common/InputField';

import { FaSearch } from 'react-icons/fa';

function Search({ register, errors, onChange }) {
  return (
    <div className="flex flex-col  mb-6 w-full  mt-6 laptop:w-3/6" key="search">
      <InputField
        Icon={FaSearch}
        fieldName="search"
        fieldId="search"
        register={register}
        errors={errors}
        fieldType="text"
        onChange={onChange}
        placeholder="Search items..."
      />
    </div>
    
  );
}

export default Search;
