import React from "react";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { Card } from "@mui/material";
import {
  FaRegListAlt,
  FaClock,
  FaSearch,
  FaImages,
  FaChartLine,
  FaRobot,
  FaLightbulb,
  FaLaptop,
  FaArrowRight,
  FaHandPointRight,
  FaFolder,
  FaGlobeAmericas,
  FaTools,
  FaBell,
  FaExchangeAlt,
  FaShieldAlt
} from "react-icons/fa";

export default function MainSection() {
  return (
    <>
      <div className="text-center mt-4">
        <h2
          className="font-proximaNovaBold  mb-2 pb-2 text-2xl tablet:text-4xl mb-2 underline decoration-themeBlue"
          style={{ textUnderlinePosition: "under" }}
        >
          Why Choose Scans?
        </h2>

        <div className="flex  flex-wrap bg-blue-50 mt-10 rounded-md  w-full justify-center items-center text-left">
          <div className="w-full flex justify-center laptop:justify-start laptop:pl-0 laptop:pt-0 mobile:pt-5 rounded-md  laptop:w-1/4">
            <Card style={{ height: "450px", width: "250px"}}>
              <img
                src="https://scanqr-app.s3.amazonaws.com/dashboard.png"
                alt="gem"
                style={{ height: "450px", width: "250px" }}
              />
            </Card>
          </div>

          <div className="w-full  laptop:w-3/4 px-4 text-center tablet:text-left">
            <h3 className="font-proximaNovaBold mt-4 tablet:mt-0 text-xl tablet:text-2xl">
              Efficiency Boost
            </h3>
            <p className="text-lg mt-4 text-justify tablet:text-xl">
              Save time and resources in managing and searching through your
              storage units.
            </p>
            <ul className="list-none text-start pl-6 mt-4 mb-8">
              <li className="text-lg text-gray-800 mb-5">
                <FaRegListAlt className="inline mr-2" />
                <strong>Effortlessly Organize: </strong>
                Say goodbye to chaos! Our intuitive platform allows you to
                categorize items, assign labels, and create virtual storage
                layouts for easy navigation.
              </li>
              <li className="text-lg text-gray-800 mb-5">
                <FaClock className="inline mr-2" />
                <strong>Instant Access: </strong>
                No more rummaging through boxes or shelves. With just a few
                clicks, locate any item within seconds, saving you valuable time
                and effort.
              </li>
              <li className="text-lg text-gray-800 mb-5">
                <FaSearch className="inline mr-2" />
                <strong>Smart Search: </strong>
                Our powerful search functionality enables you to quickly locate
                items based on keywords, categories, or even specific
                attributes, ensuring nothing gets lost in the shuffle.
              </li>
            </ul>
            <div className="flex justify-center text-center mt-10 mb-2">
              <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                <Link
                  to="/web/login"
                  className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                >
                  Try Scans free
                </Link>
                <Link
                  to="/web/pricing"
                  className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
                >
                  See all plans <FaAngleRight className="inline" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap mt-10 items-center">
          <div className="w-full flex justify-center laptop:justify-start laptop:pl-0 laptop:pt-0 mobile:pt-5 rounded-md  laptop:w-1/4">
            <Card style={{ height: '450px', width: '250px' }}>
              <img
                src="https://scanqr-app.s3.amazonaws.com/inventory.png"
                alt="project members"
                style={{ height: '450px', width: '250px' }}
              />
            </Card>
          </div>
          <div className="w-full  laptop:w-3/4 px-4 laptop:order-first text-center tablet:text-left laptop:pr-12">
            <h3 className="font-proximaNovaBold mt-4 tablet:mt-0 text-xl tablet:text-2xl">
              Advanced Technology
            </h3>
            <p className="text-lg mt-4 text-justify tablet:text-xl">
              Harness the power of AI and machine learning for superior image
              and data processing.
            </p>
            <ul className="list-none text-start mt-4 pl-6 mb-8">
              <li className="text-lg text-gray-700 mb-4">
                <FaImages className="inline mr-2" />
                <strong>Enhanced Image Processing:</strong> Utilize cutting-edge AI algorithms to enhance image quality, recognize patterns, and extract valuable insights from visual data.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaChartLine className="inline mr-2" />
                <strong>Intelligent Data Analysis:</strong> Leverage machine learning models to analyze vast amounts of data, uncover hidden correlations, and make data-driven decisions with confidence.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaRobot className="inline mr-2" />
                <strong>Automated Workflows:</strong> Streamline your processes with AI-powered automation, reducing manual intervention and minimizing errors for increased efficiency and productivity.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaClock className="inline mr-2" />
                <strong>Real-time Insights:</strong> Gain actionable insights in real-time, allowing you to respond swiftly to changing conditions and make informed decisions on the fly.
              </li>
            </ul>
            <div className="flex justify-center text-center mt-10 mb-2">
              <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                <Link
                  to="/web/login"
                  className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                >
                  Try Scans free
                </Link>
                <Link
                  to="/web/pricing"
                  className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
                >
                  See all plans <FaAngleRight className="inline" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap bg-blue-50 mt-10 rounded-md w-full justify-center items-center text-left">
        <div className="w-full flex justify-center laptop:justify-start laptop:pl-0 laptop:pt-0 mobile:pt-5 rounded-md  laptop:w-1/4">
            <Card style={{ height: '450px', width: '250px' }}>
              <img
                src="https://scanqr-app.s3.amazonaws.com/allItems.png"
                alt="editor"
                style={{ height: '450px', width: '250px' }}
              />
            </Card>
          </div>
          <div className="w-full  laptop:w-3/4 px-4 text-center tablet:text-left">
            <h3 className="font-proximaNovaBold mt-4 tablet:mt-0 text-xl tablet:text-2xl">
              User-Friendly Interface
            </h3>
            <p className="text-lg mt-4 text-justify tablet:text-xl">
              Navigate through the app with ease and enjoy a seamless user
              experience.
            </p>
            <ul className="list-none text-start pl-6 mt-4 mb-8">
              <li className="text-lg text-gray-700 mb-4">
                <FaLightbulb className="inline mr-2 " />
                <strong>Intuitive Design:</strong> Our interface is thoughtfully designed with user-centric principles, making it easy for users to understand and navigate the application.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaLaptop className="inline mr-2" />
                <strong>Responsive Layout:</strong> Experience consistency across devices with our responsive layout, ensuring optimal usability on desktops, tablets, and mobile devices.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaArrowRight className="inline mr-2" />
                <strong>Effortless Navigation:</strong> Seamlessly navigate through different sections and features of the app with intuitive navigation menus and clear hierarchies.
              </li>
              {/* <li className="text-lg text-gray-700 mb-4">
                <FaHandPointRight className="inline mr-2" />
                <strong>Interactive Elements:</strong> Engage users with interactive elements and intuitive controls that respond to user actions, providing instant feedback and enhancing usability.
              </li> */}
            </ul>
            <div className="flex justify-center text-center mt-10 mb-2">
              <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                <Link
                  to="/web/login"
                  className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                >
                  Try Scans free
                </Link>
                <Link
                  to="/web/pricing"
                  className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
                >
                  See all plans <FaAngleRight className="inline" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center mt-10 text-left">
        <div className="w-full flex justify-center laptop:justify-start laptop:pl-0 laptop:pt-0 mobile:pt-5 rounded-md  laptop:w-1/4">
            <Card style={{ height: '450px', width: '250px' }}>
              <img
                src="https://scanqr-app.s3.amazonaws.com/singleItem.png"
                alt="bulk editing"
                style={{ height: '400px', width: '250px' }}

              />
            </Card>
          </div>
          <div className="w-full  laptop:w-3/4 px-4 laptop:order-first text-center tablet:text-left laptop:pr-12">
            <h3 className="font-proximaNovaBold mt-4 tablet:mt-0 text-xl tablet:text-2xl">
              Robust Data Management
            </h3>
            <p className="text-lg mt-4 text-justify tablet:text-xl">
              Ensure your data is neatly organized, easily accessible, and
              effectively managed. Your data's security is our priority —
              encrypted, protected, and handled with the utmost care, ensuring
              your information stays private and safe, always.
            </p>
            <ul className="list-none text-start mt-4 pl-6 mb-8">
              <li className="text-lg text-gray-700 mb-4">
                <FaFolder className="inline mr-2" />
                <strong>Neat Organization:</strong> Keep your data tidy and structured with our organized data management system, allowing you to efficiently categorize and store information.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaGlobeAmericas className="inline mr-2" />
                <strong>Easy Accessibility:</strong> Access your data anytime, anywhere with our seamless accessibility features, ensuring you can retrieve important information whenever you need it.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaTools className="inline mr-2" />
                <strong>Effective Management:</strong> Manage your data effectively with advanced tools and functionalities, including data entry, editing, version control, and more.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaShieldAlt className="inline mr-2" />
                <strong>Security Priority:</strong> Your data's security is our top priority. We employ robust encryption methods, access controls, and security protocols to safeguard your information against unauthorized access and breaches.
              </li>
            </ul>
            <div className="flex justify-center text-center mt-10 mb-2">
              <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                <Link
                  to="/web/login"
                  className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                >
                  Try Scans free
                </Link>
                <Link
                  to="/web/pricing"
                  className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
                >
                  See all plans <FaAngleRight className="inline" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex  flex-wrap bg-blue-50 mt-10 mb-10 rounded-md w-full justify-center items-center text-left">
        <div className="w-full flex justify-center laptop:justify-start laptop:pl-0 laptop:pt-0 mobile:pt-5 rounded-md  laptop:w-1/4">
            <Card style={{ height: '450px', width: '250px' }}>
              <img
                src="https://scanqr-app.s3.amazonaws.com/menu.png"
                alt="web"
                style={{ height: '400px', width: '250px' }}
              />
            </Card>
          </div>
          <div className="w-full laptop:w-3/4 px-4  text-center tablet:text-left laptop:pr-12">
            <h3 className="font-proximaNovaBold mt-4 tablet:mt-0 text-xl tablet:text-2xl">
              Ready to Transform Your Storage Management?
            </h3>
            <p className="mb-4 font-proximaNova text-lg  text-start">
              Download Scans Today and Embark on a Journey of Enhanced
              Efficiency and Organization.
            </p>

            <ul className="list-none text-start pl-6 mb-8">
              <li className="text-lg text-gray-700 mb-4">
                <FaBell className="inline mr-2" />
                <strong>Customized Alerts:</strong> Receive timely notifications and alerts for low inventory levels, upcoming expiration dates, and other important events, ensuring you stay informed and proactive.
              </li>
              <li className="text-lg text-gray-700 mb-4">
                <FaExchangeAlt className="inline mr-2" />
                <strong>Integration Capabilities:</strong> Seamlessly integrate Scans with your existing systems and applications, including ERP, CRM, and accounting software, for enhanced workflow automation and data synchronization.
              </li>
            </ul>
            
            <div className="text-center  mt-4 mb-8">
              <h3 className="font-proximaNovaBlack text-themeHeadingText text-xl tablet:text-2xl mb-2">
                Discover the most sophisticated software for managing inventories.
              </h3>
              <p className="font-proximaNova text-xl tablet:text-xl mb-2">
                Are you ready to revolutionise your company's inventory management?
              </p>
              <div className="flex justify-center text-center mt-10 mb-2">
                <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                  <Link
                    to="/web/login"
                    className="text-white bg-themeBlue  focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                  >
                    Try Scans free
                  </Link>
                  <Link
                    to="/web/pricing"
                    className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
                  >
                    See all plans <FaAngleRight className="inline" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center  mt-10 text-center">
          {/* <div className="w-full px-4 mobile:pl-8  laptop:w-1/4">
            <Card style={{ height: '450px', width: '250px' }}>
              <img
                src="https://scanqr-app.s3.amazonaws.com/singleItem.png"
                alt="bulk editing"
                style={{ height: '400px', width: '250px' }}

              />
            </Card>
          </div> */}
          <div className="w-full  px-4  text-center">
          
              <h3 className="font-bold mt-4 text-2xl tablet:text-4xl">
                Inventory from anywhere.
              </h3>
              <p className="mb-4 font-proximaNova text-xl tablet:text-2xl text-center">
                Our top-rated mobile app makes it easy to inventory anywhere—even when you’re offline.
              </p>
              <div className="flex justify-center mt-10 mb-10 text-center">
                <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start pb-10 laptop:pb-0 fade-in">
                  <Link to="https://apps.apple.com/us/app/scans/id6470666076" target="__blank">
                  <img
                    src={`${process.env.REACT_APP_S3_URL}/AppStore.svg`}
                    className="mb-2 tablet:h-12 tablet:pr-4 w-48 tablet:w-auto cursor-pointer"
                    alt="app-store"
                  />
                  </Link>
                  <Link to="https://play.google.com/store/apps/details?id=com.scans" target="__blank">
                  <img
                    src={`${process.env.REACT_APP_S3_URL}/PlayStore.svg`}
                    className="mb-2 tablet:h-12 w-48 tablet:w-auto cursor-pointer"
                    alt="play-store"
                  />
                   </Link>
                </div>
              </div>

           
          </div>
        </div>
      </div>

      
    </>
  );
}
