import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { userLogoutOp } from "../../redux/operations/user.operations";
import SubMenu from "./SubMenu";
import { motion } from "framer-motion";
import Modal from "../../components/Modal";
import packageJson from "../../../package.json";

// * React icons
import { IoIosArrowBack } from "react-icons/io";
import { FaBellSlash } from "react-icons/fa";
import { FiBox } from "react-icons/fi";
import { useSelector } from "react-redux";
import { AiOutlineLogout } from "react-icons/ai";
import { IoIosLock } from "react-icons/io";
import { RiHome4Line } from "react-icons/ri";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineSettings } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { MdPersonAddAlt } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
import { MdUpgrade } from "react-icons/md";
import { GrUpgrade } from "react-icons/gr";

import { BsQrCodeScan } from "react-icons/bs";
import { MdMenu } from "react-icons/md";
import {
  cancelSubscriptionGenOp,
  cancelFreeSubscriptionGenOp,
  deactivatePersonalPlanGenOp,
} from "../../redux/operations/subscription.operations";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import scansLogo1 from "../.././assets/scans-logo-white-text.png";
import scansLogo2 from "../.././assets/scans-logo-latest.png";

const Sidebar = ({ plan }) => {
  let isTabletMid = useMediaQuery({ query: "(max-width: 639px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const [modal, setModal] = useState(false);

  const sidebarRef = useRef();
  const { userType } = useSelector((state) => state.user);
  const currentPlan = useSelector((state) => state?.user?.plan);
  const subId = useSelector((state) => state.subscription.id);
  console.log("subId:", subId);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // console.log('plan---', plan);

  const handleLogout = () => {
    dispatch(userLogoutOp());
    localStorage.removeItem("userAuthToken");
    navigate("/web/login");
  };

  

  const openModal = () => {
    setModal(true);
  };



  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.15,
          },
        },
      }
    : {
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "4rem",
          transition: {
            damping: 40,
          },
        },
      };

  const adminSubMenusList = [
    {
      name: "Settings",
      icon: MdOutlineSettings,
      menus: [
        {
          name: "User Profile",
          icon: <FaRegUser size={23} className="min-w-max" />,
          link: "web/user/profile",
        },
        {
          name: " Users",
          icon: <MdPersonAddAlt size={23} className="min-w-max" />,
          link: "web/user/add-user",
          role: "ADMIN",
        },
        // {
        //   name: "Reports",
        //   icon: <HiOutlineDocumentReport size={23} className="min-w-max" />,
        //   link: "web/report",
        // },
        // {
        //   name: "Upgrade Plan",
        //   icon: <GrUpgrade size={23} className="min-w-max" />,
        //   link: "web/upgrade",
        // },
      ],
    },
  ];

  const userSubMenusList = [
    {
      name: "Settings",
      icon: MdOutlineSettings,
      menus: [
        {
          name: "User Profile",
          icon: <FaRegUser size={23} className="min-w-max" />,
          link: "web/user/profile",
        },
        // {
        //   name: ' Users',
        //   icon: <MdPersonAddAlt size={23} className="min-w-max" />,
        //   link: 'web/user/add-user',
        //   role:"ADMIN"
        // },
        // {
        //   name: "Reports",
        //   icon: <HiOutlineDocumentReport size={23} className="min-w-max" />,
        //   link: "web/report",
        // },
        // {
        //   name: "Upgrade Plan",
        //   icon: <GrUpgrade size={23} className="min-w-max" />,
        //   link: "web/upgrade",
        // },
      ],
    },
  ];
  return (
    <div>
      <Modal
        firstButtonText={"Cancel"}
        secondButtonText={"Logout"}
        heading={"Confirm Logout"}
        body={<p>Do you want to logout?</p>}
        modal={modal}
        pressSecondButton={() => handleLogout()}
        pressFirstButton={() => setModal(false)}
        pressCloseButton={() => setModal(false)}
      />
      <div
        onClick={() => setOpen(false)}
        className={`tablet:hidden fixed inset-0 max-h-screen z-[998] bg-black/50 ${
          open ? "block" : "hidden"
        } `}
      ></div>
      <motion.div
        ref={sidebarRef}
        variants={Nav_animation}
        initial={{ x: isTabletMid ? -250 : 0 }}
        animate={open ? "open" : "closed"}
        className="bg-themeBlue text-white shadow-xl z-[999] max-w-[16rem] w-[16rem] overflow-hidden tablet:relative fixed h-screen"
      >
        <div className="flex items-center  justify-between gap-2 border-b  bg-themeBlue px-3 border-slate-300">
          <div className="flex flex-row  justify-center items-start">
            {open ? (
              <img
                src={scansLogo1}
                alt="scans-log"
                className="object-center object-scale-down h-24"
              />
            ) : (
              <span className="text-xl py-5 font-proximaNovaBold">S</span>
            )}
          </div>

          <motion.div
            onClick={() => setOpen(!open)}
            animate={open ? { rotate: 0 } : { rotate: 180 }}
            transition={{ duration: 0.3 }}
            className="cursor-pointer"
          >
            <IoIosArrowBack size={25} />
          </motion.div>
        </div>
        <div className="flex flex-col mt-2  h-full">
          <ul className="whitespace-pre px-2.5 text-[0.9rem] flex flex-col gap-2  font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-slate-100 tablet:h-[68%] h-[70%]">
            <li>
              <NavLink to={"/web/dashboard"} className="linkNav">
                <RiHome4Line size={23} className="min-w-max" />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={"/web/qr/boxes"} className="linkNav">
                <HiOutlineClipboardDocumentList
                  size={23}
                  className="min-w-max"
                />
                Inventory
              </NavLink>
            </li>
            <li>
              <NavLink to={"/web/items/all"} className="linkNav">
                <RxDashboard size={23} className="min-w-max" />
                Items
              </NavLink>
            </li>
            <li>
              <NavLink to={"/web/report"} className="linkNav">
                <HiOutlineDocumentReport size={23} className="min-w-max" />
                Reports
              </NavLink>
            </li>
            {(userType === "ADMIN" || userType === "SUPER-ADMIN") && (
              <li>
                <NavLink to={"/web/upgrade"} className="linkNav">
                  <GrUpgrade size={23} className="min-w-max" />
                  Upgrade Plan
                </NavLink>
              </li>
            )}

            <div className="">
              {userType === "ADMIN" || userType === "SUPER-ADMIN"
                ? adminSubMenusList?.map((menu) => (
                    <div key={menu.name} className="flex flex-col gap-1">
                      <SubMenu data={menu} />
                    </div>
                  ))
                : userSubMenusList?.map((menu) => (
                    <div key={menu.name} className="flex flex-col gap-1">
                      <SubMenu data={menu} />
                    </div>
                  ))}
            </div>
           
          </ul>
          {open ? (
            <div className="flex-1 absolute bottom-0 text-sm z-50  max-h-48 my-auto whitespace-pre w-full  font-medium ">
              <div className="flex border-t border-slate-300 p-1 items-center justify-between">
                <div className="logout">
                  <AiOutlineLogout
                    size={23}
                    className="min-w-max"
                    onClick={openModal}
                  />
                  <button
                    onClick={openModal}
                    className="block  text-white rounded-3xl px-6 py-2 hover:bg-red-500 tablet:hover:bg-red-500 tablet:border-0 bg-red-600"
                  >
                    <IoIosLock  size={22}
                  className="min-w-max mr-2 mb-1 font-bold inline-flex"/>
                    Logout  
                  </button>
                  <span className="lining-nums">v{packageJson.version}</span>{" "}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex-1 absolute bottom-0 text-sm z-50  max-h-48 my-auto whitespace-pre w-full  font-medium ">
              <div className="flex border-t border-slate-300 p-1 items-center justify-between">
                <div className="linkNav">
                  <AiOutlineLogout
                    size={23}
                    className="min-w-max"
                    onClick={openModal}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <motion.div
          onClick={() => {
            setOpen(!open);
          }}
          animate={
            open
              ? {
                  x: 0,
                  y: 0,
                  rotate: 0,
                }
              : {
                  x: -10,
                  y: -200,
                  rotate: 180,
                }
          }
          transition={{ duration: 0 }}
          className="absolute w-fit h-fit tablet:block z-50 hidden right-2 bottom-3 cursor-pointer"
        >
          <IoIosArrowBack size={25} />
        </motion.div> */}
      </motion.div>
      <div className="pt-2 pl-2 tablet:hidden" onClick={() => setOpen(true)}>
        <MdMenu size={25} />
      </div>
    </div>
  );
};

export default Sidebar;
