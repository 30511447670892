import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import Navbar from './Navbar.jsx';
import Footer from './Footer.js';

function QuickStart() {
  let isTabletMid = useMediaQuery({ query: '(max-width: 639px)' });
  const [quickStartData, setQuickStartData] = useState([]);
  useEffect(() => {
    import('../utils/quickStartData.js').then((data) => {
      setQuickStartData(data.quickStartData);
    });
  }, []);
  return (
    <div className="min-h-screen bg-welcomeBg">
      <div className="bg-white  tablet:mx-auto">
        {/* <Navbar /> */}
        {/* <div
          className={` p-6 py-4 ${isTabletMid ? 'text-center' : 'text-right'}`}
        >
          <a
            href={isTabletMid ? 'exp+scan-qr://helpandsupport' : '/'}
            className="px-8 bg-indigo-500 hover:bg-indigo-500 text-white rounded-lg font-semibold py-2"
          >
            Home
          </a>
        </div> */}

        <section className="relative p-6 z-20 overflow-hidden bg-white pt-8 pb-2 laptop:pt-8 laptop:pb-2">
          <div className="container mx-auto">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4">
                <div className="mb-[10px]  laptop:mb-">
                  <h2 className="mb-4 text-2xl font-bold text-dark text-left laptop:text-3xl tablet:text-[40px]">
                    Quick Start Guide
                  </h2>
                  <p className="text-base text-body-color text-justify">
                    Scan QR is a revolutionary mobile application that ensures
                    effortless and advanced scanning capabilities for looking up
                    items in an account. Beyond its essential functionality,
                    Scan QR adds value by offering exceptional features such as
                    universal search, comprehensive CRUD (Create, Read, Update,
                    Delete) operations for items, advanced image processing, and
                    efficient keyword extraction. Designed to simplify and
                    streamline operations, Scan QR makes it easy to extract and
                    store valuable data from receipts and other documents,
                    promoting efficient data management and accessibility.
                  </p>
                </div>
              </div>
            </div>
            <div className="-mx-2 flex flex-wrap">
              <div className="w-full px-2">
                {quickStartData &&
                  quickStartData.map((quickStart) => (
                    <div key={quickStart.id} className="mb-2 pb-0">
                      <div className="p-2 flex-1">
                        <h3 className="text-lg text-blue-900 font-bold">
                          {quickStart.header}
                        </h3>
                        <p className="text-base mb-1">
                          <strong>Overview: </strong>
                          {quickStart.purpose}
                        </p>
                        <p className="text-base mb-1">
                          <strong>Functionality: </strong>
                          {quickStart.functionality}
                        </p>
                        <p className="text-base mb-1">
                          <strong>How It Works: </strong>
                          {quickStart.howitworks}
                        </p>
                        {quickStart.advantages &&
                          quickStart.advantages.length > 0 && (
                            <div className="mb-1">
                              <strong>Advantages: </strong>
                              <ul className="list-disc pl-6 mt-1">
                                {quickStart.advantages.map(
                                  (advantage, advantageIndex) => (
                                    <li
                                      key={advantageIndex}
                                      className="text-base"
                                    >
                                      {advantage.title}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        <p className="text-base mb-1">{quickStart.text}</p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="absolute bottom-0 right-0 z-[-1]">
            <svg
              width="1440"
              height="886"
              viewBox="0 0 1440 886"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.5"
                d="M193.307 -273.321L1480.87 1014.24L1121.85 1373.26C1121.85 1373.26 731.745 983.231 478.513 729.927C225.976 477.317 -165.714 85.6993 -165.714 85.6993L193.307 -273.321Z"
                fill="url(#paint0_linear)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="1308.65"
                  y1="1142.58"
                  x2="602.827"
                  y2="-418.681"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3056D3" stopOpacity="0.36" />
                  <stop offset="1" stopColor="#F5F2FD" stopOpacity="0" />
                  <stop offset="1" stopColor="#F5F2FD" stopOpacity="0.096144" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </section>
        {/* <div className={`py-2 ${isTabletMid ? 'text-center' : 'text-right'}`}>
          <a
            href={isTabletMid ? 'exp+scan-qr://helpandsupport' : '/'}
            className="px-8 bg-indigo-500 hover:bg-indigo-500 text-white rounded-lg font-semibold py-2"
          >
            Home
          </a>
        </div> */}
        {/* <Footer/> */}
      </div>
    </div>
  );
}

export default QuickStart;
