import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import { setItems } from "../../redux/actions/items.action";
import {
  removeItemGenOp,
  getItemsByUserGenOp,
} from "../../redux/operations/items.operations";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import HomeCardWithImage from "../../components/HomeCardWithImage";
import { useNavigate } from "react-router";
import Search from "../Search/Search";
import { useForm } from "react-hook-form";
import moment from "moment";
import { searchItemGenOp } from "../../redux/operations/search.operations";
import BackButton from "../../common/BackButton";
import Modal from "../../components/Modal";
import { TERipple } from "tw-elements-react";
import { IconButton, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";
import Menu from "../menu/Menu";
import ItemsTable from "../table/ItemsTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "react-data-table-component";
import { getRequest } from "../../components/interceptor/interceptor";
import { GET_ITEMS_BY_USER } from "../../apiConst";
import SEO from "../../components/SEO";
function AllItems() {
  const userId = useSelector((state) => state.user._id);
  const companyId = useSelector((state) => state.user.companyId);
  const { isGrid } = useSelector((state) => state.menu);
  const items = useSelector((state) => state.items);
  const labels = useSelector((state) => state.qrCodes?.labels);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [modal, setModal] = useState(false);
  const [usedCustomFields, setUsedCustomFields] = useState(0);
  const [dataId, setDataId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isMoreLoading, setIsMoreLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMoreLoading, pageNumber]);

  const loadMoreItems = async (page) => {
    console.log('page',page)
    setIsMoreLoading(true);
    await getRequest(
      `${GET_ITEMS_BY_USER}?perPage=${perPage}&pageNum=${page}&type=${userId}`
    ).then((res) => {
      dispatch(setItems((prevItems) => ({
        ...prevItems,
        data: [...prevItems.data, ...res.data]
      })));
      setIsMoreLoading(false);
    });
  };

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 2 && !isMoreLoading) {
      setPageNumber((prevPage) => prevPage + 1);
      loadMoreItems(pageNumber + 1);
    }
  };

  

  const handlePageChange = (page) => {
    console.log('page__',page)
    getRequest(
      `${GET_ITEMS_BY_USER}?perPage=${perPage}&pageNum=${page}&type=${userId}`
    ).then((res) => {
      console.log("res from getItems", res.customFieldsCount);
      dispatch(setItems(res));
      setUsedCustomFields(res.customFieldsCount);
      // setIsLoading(false);
    });
  };

  const handlePerPageChange = (newPerPage, page) => {
    getRequest(
      `${GET_ITEMS_BY_USER}?perPage=${newPerPage}&pageNum=${page}&type=${userId}`
    ).then((res) => {
      console.log("res from getItems", res.customFieldsCount);
      dispatch(setItems(res));
      setUsedCustomFields(res.customFieldsCount);
      // setIsLoading(false);
    });
    setPerPage(newPerPage);
  };
  const {
    register,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (searchQuery.length > 0) {
      onSubmit(searchQuery);
    }
  }, [searchQuery]);

  const onSubmit = async (search) => {
    const dataToBeSent = {
      searchQuery: search,
      companyId: companyId,
    };
    await dispatch(searchItemGenOp(dataToBeSent)).then((res) => {
      setSearchResult(res.data.data);
    });
  };

  useEffect(() => {
    getItemsByUser(userId, 1, perPage);
    window.addEventListener("scroll", handleScroll);
    return () => {
      // Remove event listener when component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getItemsByUser = (id) => {
    setIsLoading(true);
    dispatch(getItemsByUserGenOp(id, 1, perPage)).then((res) => {
      console.log("res from getItems", res.customFieldsCount);
      dispatch(setItems(res));
      setUsedCustomFields(res.customFieldsCount);
      setIsLoading(false);
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    dispatch(removeItemGenOp(id)).then((res) => {
      if (res) {
        getItemsByUser(userId);
        setModal(false);
        navigate("/web/items/all");
        setIsLoading(false);
      }
    });
    setIsLoading(false);
  };

  const openModal = (item) => {
    setModal(true);
    setDataId(item._id);
  };

  const viewItem = (item) => {
    navigate(`/web/item/${item.labelId}/${item._id}/view`, {
      state: {
        data: item,
        isView: true,
      },
    });
  };

  console.log("searchQuery", searchQuery);
  console.log("searchResult", searchResult);
  return (
    <div>
      <SEO
        title={`Manage Inventory Items with Scans - Efficient and Accurate Tracking`}
        description={`Track and manage your inventory items with Scans. Ensure efficient and accurate tracking of all your items for optimal inventory control.`}
        keywords={`Inventory Management, Efficient Inventory Tracking, Accurate Inventory Control, Track Inventory Items, Scans Inventory Tracking, Inventory Control Solutions, Manage Inventory Efficiently, Inventory Tracking Tools`}
      />

      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <div className="laptop:mt-5">
          <div className="flex flex-col justify-center items-center laptop:flex-row laptop:justify-start laptop:items-center mr-6">
            <Search
              register={register}
              onChange={(e) => setSearchQuery(e.target.value)}
              errors={errors}
              className="mb-4 sm:mb-0"
            />
            <div className=" flex items-center laptop:ml-auto laptop: flex laptop:flex-row laptop:items-center mb-3 ">
              {labels?.length != 0 && (
                <button
                  type="button"
                  className="inline-flex  items-center rounded-3xl bg-themeBlue mr-5 px-6 py-3.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-indigo-700 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  onClick={() =>
                    navigate("/web/items/add", {
                      state: { data: usedCustomFields },
                    })
                  }
                >
                  <IoMdAdd className="h-5 w-5 mr-1 text-white" />
                  <span>Add Item</span>
                </button>
              )}
              <div className="laptop:ml-auto">
                <Menu />
              </div>
            </div>
          </div>

          <Modal
            dataId={dataId}
            firstButtonText={"Cancel"}
            secondButtonText={"Delete"}
            heading={"Confirm Delete"}
            body={<p>Are you sure you want to delete item?</p>}
            modal={modal}
            pressSecondButton={(id) => handleDelete(id)}
            pressFirstButton={() => setModal(false)}
            pressCloseButton={() => setModal(false)}
          />

          {searchQuery.length > 0 && searchResult && searchResult.length > 0 ? (
            <>
              {isGrid ? (
                <div className="flex flex-row flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 justify-items-center">
                  {searchResult.map((item) => (
                    <React.Fragment key={item._id}>
                      <HomeCardWithImage
                        title={item?.title}
                        image={item?.image[0]}
                        link=""
                        boxTitle={item?.labelTitle}
                        updatedAt={item?.updatedDate                        }
                        onClick={() => viewItem(item)}
                        deleteBtn={() => openModal(item)}
                      />
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <ItemsTable
                  data={searchResult}
                  openModal={openModal}
                  viewItem={viewItem}
                />
              )}
            </>
          ) : (
            <React.Fragment>
              <h5 className="mb-2 text-xl pl-3 self-start font-bold leading-tight text-neutral-800">
                All Items
              </h5>
              {isGrid ? (
                <div className="flex flex-row flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 justify-items-center">
                  {items && items?.data?.length > 0 ? (
                    items.data.map((item) => (
                      <React.Fragment key={item?._id}>
                        <HomeCardWithImage
                          title={item?.title}
                          image={item.image[0]}
                          boxTitle={item?.labelTitle}
                          updatedAt={item?.updatedDate
                          }
                          onClick={() => viewItem(item)}
                          deleteBtn={() => openModal(item)}
                        />
                      </React.Fragment>
                    ))
                  ) : (
                    <p className="self-center font-bold text-md text-gray-900">
                      {" "}
                      No Items are available
                    </p>
                  )}
                </div>
              ) : (
                <ItemsTable
                  data={items.data}
                  openModal={openModal}
                  viewItem={viewItem}
                  perPage={perPage}
                  handlePerPageChange={handlePerPageChange}
                  handlePageChange={handlePageChange}
                />
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
}

export default AllItems;
