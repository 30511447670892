import { useState } from 'react';
import { motion } from 'framer-motion';
import { IoIosArrowDown } from 'react-icons/io';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SubMenu = ({ data }) => {
  const { pathname } = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const{userType}=useSelector((state)=>state.user)
  return (
    <>
      <li
        className={`linkNav ${pathname.includes(data.name) && 'text-blue-600'}`}
        onClick={() => setSubMenuOpen(!subMenuOpen)}
      >
        <data.icon size={25} className='min-w-max' />
        <p className='flex-1 capitalize'>{data.name}</p>
        <IoIosArrowDown
          className={` ${subMenuOpen && 'rotate-180'} duration-200 `}
        />
      </li>
      <motion.ul
        animate={
          subMenuOpen
            ? {
              height: 'fit-content',
            }
            : {
              height: 0,
            }
        }
        className='flex h-0 flex-col text-[0.8rem] font-normal overflow-hidden'
      >
        {data.menus?.map((menu) => (

          <li key={menu.name}>
            <NavLink
              to={`/${menu.link}`}
              className='linkNav mt-1 mb-1 capitalize'
            >
              {menu.icon}
              {menu.name}
            </NavLink>
          </li>
        ))}
      </motion.ul>
    </>
  );
};

export default SubMenu;
