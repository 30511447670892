import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";
import { FaCalendarAlt } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import No_image_placeholder from "../../assets/No-image-placeholder.png";
import { FaSearch } from "react-icons/fa";
import moment from "moment";

const BlogPost = () => {
  const [data, setData] = useState([]); // Complete data fetched from the API
  const [isLoading, setIsLoading] = useState(false); // Loading state for fetching data
  const [hasMore, setHasMore] = useState(true); // Flag to determine if there's more data to fetch
  const [searchQuery, setSearchQuery] = useState("");

  const filteredBlogs =
    data &&
    data.filter((card) =>
      card.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      const currentLength = data.length;
      axios
        .post("https://blog.itpros.tech/blogs/findAllBlogDetails", {
          domain: "www.scanqr.ai",
          offset: currentLength,
          limit: 10,
        })
        .then((response) => {
          if (response.data.message) {
            setIsLoading(false);
            setHasMore(false); // Stop loading more data
          } else {
            const newData = response.data;
            setData((prevData) => [...prevData, ...newData]);
            setIsLoading(false);
            setHasMore(newData?.length > 0); // Set hasMore based on whether new data was fetched
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching posts:", error);
        });
    }
  };

  const navigate = useNavigate();
  const handleKnowMore = (post) => {
    const slug = post.slug;
    navigate(`/web/post-details/${slug}`, { state: { post: post } });
  };

  const handleImageError = (event) => {
    event.target.src = No_image_placeholder;
  };
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // You can perform additional search-related actions here if needed
  };

  return (
    <>
      <form id="search" className="pt-5 mx-4" onSubmit={handleFormSubmit}>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 pr-3 pl-3 rounded-tl-3xl rounded-bl-3xl flex items-center bg-blue-900">
            <FaSearch className="text-white h-5 w-5" />
          </span>
          <input
            type="text"
            id="header-searchbox"
            name="search"
            placeholder="Search here..."
            className="w-full pl-16 pr-4 bg-blue-50 border border-transparent focus:bg-white focus:border-gray-300 focus:outline-none rounded-3xl h-18 p-4 placeholder-gray-500 rounded text-gray-700 text-sm"
            autoComplete="off"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </form>

      <InfiniteScroll
        dataLength={data.length}
        next={fetchData}
        hasMore={hasMore}
        loader={
          <Stack sx={{ width: "100%", height: "10px", color: "grey.500" }}>
            <LinearProgress color="success" sx={{ height: 10 }} />
          </Stack>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>No more posts available</b>
          </p>
        }
        scrollThreshold={0.6}
      >
        <Grid container spacing={2} className="p-5">
          {filteredBlogs?.length > 0 ? (
            filteredBlogs?.map((post) => (
              <Grid item sm={12} md={6} lg={4} xl={3} key={post.id}>
                <Card
                  style={{
                    height: "500px",
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: "10px",
                  }}
                >
                  {/* <img
                    src={post?.image || No_image_placeholder}
                    style={{ height: '250px', width: '100%' }}
                    alt="No Image Available"
                    onError={handleImageError}
                  /> */}
                  <LazyLoadImage
                    src={post?.image || No_image_placeholder}
                    style={{ height: "250px", width: "100%" }}
                    PlaceholderSrc={No_image_placeholder}
                    alt="Image Alt"
                    effect="blur"
                  />
                  <div className="p-4" style={{ flex: "1 1 auto" }}>
                    <h2 className="font-bold text-lg mt-1 mb-1">
                      {post?.title?.length > 60
                        ? post.title.substring(0, 80) + "..."
                        : post.title}
                    </h2>
                    <p className="text-gray-600 text-md mt-1 mb-1">
                      <FaCalendarAlt className="inline-block mr-1" />{" "}
                      {moment(post?.published_date).format("MMMM D, YYYY")}
                    </p>
                    <div
                      className="text-gray-700 text-md font-bold mt-1 mb-1"
                      dangerouslySetInnerHTML={
                        {__html: post?.content?.length > 60
                          ? post.content.substring(0, 60) + "..."
                          : post.content
                        }}
                    ></div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="rounded-3xl text-center  w-60  bg-themeBlue text-white px-5 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white"
                      style={{
                        alignSelf: "flex-center",
                        marginBottom: "20px",
                        margin: "20px",
                      }}
                      onClick={() => handleKnowMore(post)}
                    >
                      Read More
                    </button>
                  </div>
                </Card>
              </Grid>
            ))
          ) : (
            <p></p>
          )}
        </Grid>
      </InfiniteScroll>
    </>
  );
};

export default BlogPost;
