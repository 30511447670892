export const userFields = [
  {
    fieldName: 'name',
    fieldTitle: 'Full Name',
    placeholder: 'Name',
    validationObj: {
      required: 'Please Enter Name',
      maxLength: {
        value: 52,
        message: 'Character Limit Exceeded, Max Allowed Is 52!',
      },
    },
    fieldType: 'text',
  },
  {
    fieldName: 'email',
    fieldTitle: 'Email',
    placeholder: 'Email',
    fieldType: 'email',
    disabled: true,
  },
  // {
  //   fieldName: 'phoneNumber',
  //   fieldTitle: 'Phone',
  //   placeholder: 'Phone',
  //   validationObj: {},
  //   fieldType: 'text',
  // },
];


export const AddUserFields = [
  {
    fieldName: 'name',
    fieldTitle: 'Full Name',
    placeholder: 'Name',
    validationObj: {
      required: 'Please Enter Name',
      maxLength: {
        value: 52,
        message: 'Character Limit Exceeded, Max Allowed Is 52!',
      },
    },
    fieldType: 'text',
  },
  {
    fieldName: 'email',
    fieldTitle: 'Email',
    placeholder: 'Email',
    fieldType: 'email',
 
  },
  // {
  //   fieldName: 'phoneNumber',
  //   fieldTitle: 'Phone',
  //   placeholder: 'Phone',
  //   validationObj: {},
  //   fieldType: 'text',
  // },
];
