import React, { useState } from 'react';
import Hero from './Hero';
import MainSection from './MainSection';
import { Link } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa';
import { FaRegListAlt, FaCamera, FaSearch } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import video from '../assets/ArcadeScansDemo.mp4';
import SEO from '../components/SEO';
function Home() {
  return (
    <>
      <SEO
        title={`Enhance Efficiency with Scans - Ultimate Warehouse Management Software`}
        description={
          'Discover Scans, the premier inventory management software that boosts efficiency, leverages advanced technology, and provides a user-friendly interface. Try Scans free and transform your storage management.'
        }
        keywords={
          'Warehouse, Inventory Management Software, Enhance Efficiency, Advanced Inventory Technology, User-Friendly Inventory Software, Inventory Solutions, Boost Efficiency with Scans, Free Inventory Management Software, Transform Storage Management'
        }
      />

      <Hero />
      <div className="flex flex-col bg-blue-100 justify-center items-center laptop:flex laptop:flex-col laptop:justify-center laptop:items-center laptop:pl-10 laptop:pr-10 desktop:pl-12 desktop:pr-12 desktop:flex desktop:flex-col desktop:justify-center desktop:items-center mobile:pl-6 mobile:pr-6 ">
        <div className="flex border w-50 border-solid border-gray-900 rounded-lg mt-10 pt-3 pb-3">
          <div className="flex flex-wrap rounded-3xl w-full justify-start items-center text-left">
            <div className="mobile:pl-10 mobile:pr-10 laptop:pl-0 laptop:pt-0 mobile:pt-0 rounded-3xl laptop:w-1/4">
              <ReactPlayer
                url={video}
                controls={true}
                playbackRate={1.5}
                width="100%"
                height="80vh"
                volume={0.8}
                muted={true}
                loop={true}
                playing={true}
                pip={false}
                config={{
                  youtube: {
                    playerVars: { origin: window.location.origin },
                  },
                }}
              />
            </div>

            <div className="w-full  laptop:w-3/4 px-4 text-center tablet:text-left">
              <h3 className="font-proximaNovaBold mt-4 tablet:mt-0 text-xl tablet:text-2xl">
                Step-by-Step Inventory Scanning
              </h3>
              <p className="text-lg mt-4 text-justify tablet:text-xl">
                Streamline your inventory management process with our
                step-by-step scanning solution.
              </p>
              <ol className="list-decimal text-start pl-6 mt-4 mb-8">
                <li className="text-lg text-gray-800 mb-5">
                  <FaRegListAlt className="inline mr-2" />
                  <strong>Organize Items:</strong> Begin by categorizing your
                  items and assigning labels. Our intuitive platform allows you
                  to create virtual storage layouts for easy navigation.
                </li>
                <li className="text-lg text-gray-800 mb-5">
                  <FaCamera className="inline mr-2" />
                  <strong>Scan Items:</strong> Utilize our scanning feature to
                  capture images or barcodes of your items. Easily associate
                  scanned data with item details for efficient tracking.
                </li>
                <li className="text-lg text-gray-800 mb-5">
                  <FaSearch className="inline mr-2" />
                  <strong>Review and Confirm:</strong> Review scanned
                  information and ensure accuracy. Make any necessary
                  adjustments before finalizing the inventory update.
                </li>
              </ol>
              <div className="flex justify-center text-center mt-10 mb-2">
                <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
                  <Link
                    to="/web/login"
                    className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                  >
                    Try Scans free
                  </Link>
                  <Link
                    to="/web/pricing"
                    className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
                  >
                    See all plans <FaAngleRight className="inline" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MainSection />
      </div>
    </>
  );
}

export default Home;
