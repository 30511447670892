import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import PlanCard from "./PlanCard";
import { planDetails } from "../../utils/PlanDetails";
import { isYearlyAction } from "../../redux/actions/term.action";
import { getPlansGenOp } from "../../redux/operations/plans.operations";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SEO from "../../components/SEO";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import moment from "moment";
const UpgradePlan = () => {
  const [plansData, setPlansData] = useState([]);
  const [isLoading,setIsLoading]=useState(false)
  const { isYearly } = useSelector((state) => state.term);
  const interval=useSelector((state)=>state.subscription?.plan?.interval)
  const token = useSelector((state) => state.user.token);
  const userPlan = useSelector((state) => state.user.plan);
  const { current_period_end } = useSelector((state) => state?.subscription);
  const { cancel_at_period_end } = useSelector((state) => state?.subscription);
  const cancelDate = moment.unix(current_period_end).format("DD-MM-YY");
const currentDate = moment(new Date()).format('DD-MM-YY');

// Step 2: Parse the formatted dates back to moment objects
const canceledMoment = moment(cancelDate, "DD-MM-YY");
const currentMoment = moment(currentDate, "DD-MM-YY");
  const dispatch = useDispatch();
  // console.log(
  //   '🚀 ~ file: Subscription.js:24 ~ Subscription ~ userPlan:',
  //   userPlan
  // );

  useEffect(() => {
    dispatch(isYearlyAction(interval === 'year'));
    getAllPlans();
  }, []);

  const getAllPlans = async () => {
    try {
      setIsLoading(true)
      await dispatch(getPlansGenOp()).then((res) => {
        if (res && res.data) {
          if (planDetails.length > 0 && res.data.length > 0) {
            // Ensure that the lengths of both arrays are the same
            const length = Math.min(planDetails.length, res.data.length);

            const mergedData = planDetails
              .slice(0, length)
              .map((plan, index) => {
                const apiPlan = res.data[index];
                return {
                  ...plan,
                  ...apiPlan.metadata,
                };
              });
            setPlansData(mergedData);
            setIsLoading(false)
          } else {
            setIsLoading(false)
            console.error("Either planDetails or res.data is empty.");
          }
        } else {
          setIsLoading(false)
          console.error("API response is invalid or empty.");
        }
      });
    } catch (error) {
      setIsLoading(false)
      console.error("An error occurred while fetching plans:", error);
    }
  };

  const navigate = useNavigate();

  const toggleYearly = () => {
    dispatch(isYearlyAction(!isYearly));
  };

  const goToCheckout = (plan) => {
    navigate("/checkout", {
      state: {
        data: plan,
        isCheckout: true,
        isYearly: isYearly,
      },
    });
  };

  return (
    <>
      <SEO
        title={`Manage Inventory Items with Scans - Efficient and Accurate Tracking`}
        description={`Track and manage your inventory items with Scans. Ensure efficient and accurate tracking of all your items for optimal inventory control.`}
        keywords={`Inventory Management, Inventory Tracking, Scans Inventory Management, Efficient Inventory Tracking, Accurate Inventory Control, Inventory Tracking Software, Inventory Management Solutions, Inventory Control System`}
      />
      <div className="flex w-full flex-col tablet:flex-row justify-end items-center mr-3 tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
        <label className="themeSwitcherThree relative inline-flex cursor-pointer select-none items-center">
          <input
            type="checkbox"
            checked={isYearly}
            onChange={toggleYearly}
            className="sr-only"
          />
          <div className="shadow-card border-solid border border-gray-900 p-1 flex h-12 w-36 items-center justify-center rounded-3xl bg-white">
            <span
              className={`flex h-full w-full items-center font-bold justify-center rounded-3xl font-small ${
                !isYearly ? "bg-primary text-white" : "text-body-color"
              }`}
            >
              Monthly
            </span>
            <span
              className={`flex h-full w-full items-center font-bold justify-center rounded-3xl font-small ${
                isYearly ? "bg-primary text-white" : "text-body-color"
              }`}
            >
              Yearly
            </span>
          </div>
        </label>
      </div>

      <div className="flex justify-center bg-themeBodyBg p-5">
      {isLoading?(<FullScreenLoader/>):(
        <Grid container spacing={2}>
          {plansData.map((plan, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <PlanCard
                title={plan.title}
                description={plan.descriptionText}
                buttonText={
                  userPlan?.length > 0 &&
                  (userPlan[0]?.currentPlan === plan.title
                    ? cancel_at_period_end && canceledMoment.isSameOrBefore(currentMoment)
                      ? "Subscribe"
                      : "Current Plan"
                    : userPlan[0]?.currentPlan === "Infinity"
                    ? "Disabled"
                    : userPlan[0]?.currentPlan === "Free" && plan.title !== "Free"
                    ? "Upgrade"
                    : userPlan[0]?.currentPlan === "Pro"
                    ? plan.title === "Free"
                      ? "Disabled"
                      : plan.title === "Pro"
                      ? "Current Plan"
                      : "Upgrade"
                    : userPlan[0]?.currentPlan === "Premium"
                    ? plan.title === "Free" || plan.title === "Pro"
                      ? "Disabled"
                      : plan.title === "Premium"
                      ? "Current Plan"
                      : "Upgrade"
                    : "Upgrade")
                }
                disabled={
                  userPlan?.length > 0 &&
                  (userPlan[0]?.currentPlan === plan.title
                    ? !(cancel_at_period_end && canceledMoment.isSameOrBefore(currentMoment))
                    : userPlan[0]?.currentPlan === "Infinity"
                    ? true
                    : userPlan[0]?.currentPlan === "Free" && plan.title === "Free"
                    ? true
                    : userPlan[0]?.currentPlan === "Pro" &&
                      (plan.title === "Free" || plan.title === "Pro")
                    ? true
                    : userPlan[0]?.currentPlan === "Premium" &&
                      (plan.title === "Free" ||
                        plan.title === "Pro" ||
                        plan.title === "Premium")
                    ? true
                    : false)
                }
                planType={userPlan?.length > 0 ? "Paid" : "Trial"}
                price={isYearly ? plan.yearly_price : plan.price}
                userLicenses={plan.userLicenses}
                fields={plan.fields}
                items={plan.items}
                onClick={() =>
                  goToCheckout({
                    ...plan,
                    price: isYearly ? plan.yearly_price : plan.price,
                  })
                }
                point1={plan.point1}
                point2={plan.point2}
                point3={plan.point3}
                point4={plan.point4}
                point5={plan.point5}
                icon={plan.icon}
                isYearly={isYearly}
              />
            </Grid>
          ))}
        </Grid>
      )}
      </div>
    </>
  );
};

export default UpgradePlan;
