import React from 'react'
import { Grid } from '@mui/material'
import Card from './Card'
import { Organizedata } from '../../utils/FeaturesData'

const Organize = () => {
  return (
    <>
    <div className="bg-blue-100">
    <h1 className='text-xl tablet:text-2xl laptop:text-3xl font-bold font-proximaNovaBlack text-blue-900 uppercase tracking-wider'>Organize</h1>
    <h1 className='font-proximaNovaBlack text-lg tablet:text-xl laptop:text-2xl pt-4'>Organize your inventory.</h1>
    <p className='font-proximaNovaBlack text-gray-700 text-md laptop:text-lg'>Get organized by adding your inventory and inviting your team to Scans.</p>
    <div className="flex justify-center pt-7">
        <Grid container spacing={2}>
          {Organizedata.map((organize, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <Card
                title={organize.title}
                description={organize.description}
                icon={organize.icon}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      </div>
    </>
   
  )
}

export default Organize