import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { createQRGenOp } from "../../redux/operations/qrCodes.operations";
import InputField from "../../common/InputField";
import { useForm } from "react-hook-form";
import { genQRFields } from "../../Fields/generateQRFields";
import { useNavigate } from "react-router";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { useSelector } from "react-redux";
import Modal from '../../components/Modal';
function AddBox({ isAddModal, setIsAddModal, dataId,isLoading,setIsLoading}) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [box,setBox]=useState(null)
  // const [isLoading, setIsLoading] = useState(false);
  const plan = useSelector((state) => state.user.plan[0].currentPlan);
  console.log("plan", plan);

  const navigate = useNavigate();
  const count = useSelector((state) => state.qrCodes.count);
  const labels = useSelector((state) => state.user.plan[0].labels);

  const fields = genQRFields;
  console.log("🚀 ~ file: AddBox.js:20 ~ AddBox ~ fields:", fields);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  // const decreaseQty = (q) => {
  //   const qty = parseInt(q);
  //   if (qty >= 2) {
  //     const updated = qty - 1;
  //     setNumberOfQR(updated.toString());
  //   }
  // };

  // const increaseQty = (q) => {
  //   const qty = parseInt(q);
  //   if (q.replace(/[^0-9]/g, '') === '' || qty === 0) {
  //     const updated = 1;
  //     setNumberOfQR(updated.toString());
  //   }
  //   if (qty >= 1) {
  //     const updated = qty + 1;
  //     setNumberOfQR(updated.toString());
  //   }
  // };

  const wait = (ms) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  };

  const genQR = async (data) => {
    setIsAddModal(false)
    setIsLoading(true);
    let qrTrim = box;
    if (qrTrim < 1 || qrTrim === "") {
      toast.error("Qty should be greater than 0");
      setIsLoading(false);
      setButtonDisabled(false);
    } else {
      // setIsLoading(true);
      const qrCodes = parseInt(qrTrim);
      const allowedLabels = labels - count;
      if ((plan === "Pro" || plan === "Premium" )&& qrCodes > allowedLabels) {
        toast.error(`You have reached the limit for your plan. You can add up to : ${allowedLabels}`);
        setIsLoading(false);
        setButtonDisabled(false);
      } else {
        const body = {
          qrCodes: qrCodes,
        };
        dispatch(createQRGenOp(body));
        wait(5000).then(() => {
          toast.success('Box Added Successfully')
          navigate("/web/qr/boxes");
        });
      }
    }
  };

  return (
    <>
    {/* <div>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <form>
          <div className="flex flex-col">
            {fields &&
              fields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  disabled,
                } = field;
                return (
                  <div
                    className="flex flex-col mb-6 w-full laptop:w-3/6"
                    key={fieldName}
                  >
                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                      {fieldTitle}
                    </label>
                    <InputField
                      fieldName={fieldName}
                      validationObj={validationObj}
                      register={register}
                      errors={errors}
                      fieldType={fieldType}
                      disabled={disabled}
                      //onBlur={onFieldBlur}
                      placeholder={fieldTitle}
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex flex-col tablet:flex-row tablet:justify-start tablet:w-full">
            <button
              type="submit"
              onClick={handleSubmit(genQR)}
              className={`inline-block rounded border-2 border-themeBlue bg-themeBlue mt-2 px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out hover:border-themeLightBlue hover:bg-themeLightBlue hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white`}
            >
              Generate QR
            </button>
          </div>
        </form>
      )}
    </div> */}
    <div>
    {isAddModal && (
      <Modal
        dataId={dataId|| ''}
        firstButtonText={'Cancel'}
        secondButtonText={'Generate QR'}
        heading={'Generate QR'}
        body={
          <form>
          <div className="flex flex-col">
            {fields &&
              fields.map((field) => {
                const {
                  fieldTitle,
                  fieldName,
                  fieldType,
                  validationObj,
                  disabled,
                } = field;
                return (
                  <div
                    className="flex flex-col mb-6 w-full"
                    key={fieldName}
                  >
                    <label className="block text-gray-800 font-bold mb-3 pr-4">
                      {fieldTitle}
                    </label>
                    <InputField
                      fieldName={fieldName}
                      validationObj={validationObj}
                      register={register}
                      errors={errors}
                      fieldType={fieldType}
                      disabled={disabled}
                      //onBlur={onFieldBlur}
                      placeholder={fieldTitle}
                      onChange={(event)=>setBox(event.target.value)}
                    />
                  </div>
                );
              })}
          </div>
          
        </form>
        }
        modal={isAddModal}
        pressSecondButton={() =>genQR()}
        pressFirstButton={() => setIsAddModal(false)}
        pressCloseButton={() => setIsAddModal(false)}
      />
    )}
  </div>
  </>
  );
}

export default AddBox;
