/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { get, isEmpty } from "lodash";
import { useParams, useLocation, useNavigate } from "react-router";
import {
  updateItemGenOp,
  addItemGenOp,
  getItemsGenOp,
  removeItemGenOp,
} from "../redux/operations/items.operations";
import { MdOutlineCancel } from "react-icons/md";
import { IoMdSend } from "react-icons/io";
import { imageAnalyzeGenOp } from "../redux/operations/imageAnalyze.operations";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import FullScreenLoader from "./Loader/FullScreenLoader";
import InputField from "../common/InputField";
// import ImageSlider from './ImageSlider';
import { itemFields } from "../Fields/itemFields";
import { itemFieldsWithoutBox } from "../Fields/itemFieldsWithoutBox";
import UploadField from "../common/UploadField";
import { FcAddImage } from "react-icons/fc";
import BackButton from "../common/BackButton";
import { toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import Modal from "../components/Modal";
import { FaCheck } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { setItems } from "../redux/actions/items.action";
import AddForm from "./CustomInput/AddForm";
import { Tooltip } from "@mui/material";
import RenderCustomInput from "./CustomInput/RenderCustomInput";

function FormItem() {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user._id);
  const { companyId } = useSelector((state) => state.user);
  const plan = useSelector((state) => state.user.plan[0].currentPlan);

  const labels = useSelector((state) => state.qrCodes.labels);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [selectedImageLabels, setSelectedImageLabels] = useState([]);
  const [singleImage, setSingleImage] = useState("");
  const [labelTitle, setLabelTitle] = useState("");
  const [addKeyword, setAddKeyword] = useState("");
  const [modal, setModal] = useState(false);
  const [customInputModal, setCustomInputModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [fields, setFields] = useState();
  const [labelTitleFromDropDown, setLabelTitleFromDropDown] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const [radioValue, setRadioValue] = useState(null);

  const [actualCustomfields, setActualCustomfields] = useState(
    plan === "Free"
      ? 2
      : plan === "Pro"
      ? 2
      : plan === "Premium"
      ? 100
      : plan === "Infinity"
      ? 500
      : 0
  );

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setValue,
    control,
    formState: { errors },
  } = useForm({});

  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const labelId = params.labelId;
  const itemId = params.itemId;
  const { isEdit } = get(location, "state");
  const { isView } = get(location, "state");
  useEffect(() => {
    if (labelId) {
      setFields(itemFields);
    }
  }, [labelId]);
  const data = location?.state?.data;
  console.log("location.state", location.state);
  useEffect(() => {
    // need to replace this import data with api data in the future
    if (!labelId && !fields) {
      import("../Fields/itemFieldsWithoutBox").then((fieldsData) => {
        // eslint-disable-next-line array-callback-return
        fieldsData.itemFieldsWithoutBox.map((field) => {
          if (field.options) {
            populateLabels().then((response) => {
              field.options = response;
            });
          }
        });
        setFields(fieldsData.itemFieldsWithoutBox);
      });
    }
  }, []);

  const populateLabels = async () => {
    let options = [];
    // eslint-disable-next-line array-callback-return
    labels.map((label) => {
      options.push({
        key: label.labelId,
        label: label.title,
        value: label.labelId,
      });
    });
    return options;
  };

  const openModal = () => {
    setModal(true);
  };

  const openAlertModal = () => {
    setAlertModal(true);
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const openCustomInputModal = () => {
    const customFieldsUsed =
        params.type === "edit" ? data.customFieldsUsed : data;
    console.log("customFieldsUsed", customFieldsUsed);
    console.log("actualCustomfields", actualCustomfields);
    const allowedCustomFields = actualCustomfields - customFieldsUsed;
    console.log("allowedCustomFields", allowedCustomFields);
    console.log("customFields?.length", customFields?.length);

    if (
        allowedCustomFields ===0 ||
        customFields?.length >= actualCustomfields
    ) {
        toast.error("You have reached the limit! Please upgrade your plan.");
    } else {
        setCustomInputModal(true);
    }
};

  const getItems = async (id) => {
    setIsLoading(true);
    dispatch(getItemsGenOp(id)).then((res) => {
      dispatch(setItems(res));
      setIsLoading(false);
      setModal(false);
      navigate("/web/items/all");
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    dispatch(removeItemGenOp(id)).then((res) => {
      if (res) {
        getItems(labelId);
      }
    });
  };

  useEffect(() => {
    if (!isEmpty(get(location, "state.data"))) {
      const { isEdit } = get(location, "state");
      const { isView } = get(location, "state");
      if (isEdit) {
        console.log("location.state.data", location.state.data);
        setEditItem(true);
        reset(get(location, "state.data"));
        setKeywords(location.state.data.keywords);
        setSingleImage(location.state.data.image[0]);
        setLabelTitle(location.state.data.labelTitle);
        setCustomFields(location.state.data.customInput);
      } else if (!isView || isView) {
        console.log("location.state.data", location.state.data);
        setLabelTitle(location.state.data.title);
      }
    }
  }, []);

  useEffect(() => {
    if (params.type === "edit") {
      setEditItem(true);
    } else {
      setEditItem(false);
    }
  }, []);

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.map(({ label }) => " ✔️ " + label)
      : "No Items Selected";
  };

  const onUpload = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    dispatch(imageAnalyzeGenOp(formData))
      .then((res) => {
        setSelectedImageLabels(res.data.data);
        if (res.data.data.length > 0) {
          openModal();
        } else {
          openAlertModal();
        }
        setSingleImage(res.data.imgUrl);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const removeItem = (item) => {
    var array = [...keywords];
    var index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
      setKeywords(array);
    }
  };

  const onSelectionChange = (selectedItems) => {
    setSelected(selectedItems);
  };

  const onAddAnalyzedItems = () => {
    const selectedItemsArray = [
      ...new Set(selected.map((labels) => JSON.stringify(labels.value))),
    ].map((uniqueLabels) => JSON.parse(uniqueLabels.toLowerCase()));
    setKeywords(selectedItemsArray);
    setModal(false);
  };

  const addAnotherItem = (data) => {
    const analyzedItemToAdd = data?.replace(/[^a-zA-Z ]/g, "").trim();
    if (
      analyzedItemToAdd !== "" &&
      analyzedItemToAdd?.length > 0 &&
      analyzedItemToAdd !== undefined
    ) {
      const trimmedItem = analyzedItemToAdd
        ?.replace(/^\s+|\s+$/g, "")
        .toLowerCase();
      const newArray = [...keywords];
      if (newArray.includes(trimmedItem) === false) {
        newArray.push(trimmedItem);
        setKeywords(newArray);
        resetField("addKeyword");
        setAddKeyword("");
      } else {
        toast.error(`${trimmedItem} already present in analyzed items.`);
        resetField("addKeyword");
        setAddKeyword("");
      }
    } else {
      toast.error(
        "Add new keyword should not be empty or contain special characters!"
      );
      resetField("addKeyword");
      setAddKeyword("");
    }
  };

  const addCustomFields = (data) => {
    const additionalFields = [...customFields];
    additionalFields.push(data);
    setCustomFields(additionalFields);
  };

  const handleChange = (e) => {
    const updatedFields = customFields.map((field) => {
      if (field.title === e.nativeEvent.target.name) {
        return { ...field, value: e.nativeEvent.target.value };
      }
      return field;
    });
    setCustomFields(updatedFields);
  };

  const handleDateChange = (e, fieldName) => {
    const updatedFields = customFields.map((field) => {
      if (field.title === fieldName) {
        return { ...field, date: e };
      }
      return field;
    });
    setCustomFields(updatedFields);
  };

  const handlePhoneChange = (e) => {
    const updatedFields = customFields.map((field) => {
      if (field.title === e.target.name) {
        return { ...field, phoneNumber: e.target.value };
      }
      return field;
    });
    setCustomFields(updatedFields);
  };

  const handleChangeDropdown = (e) => {
    const updatedFields = customFields.map((field) => {
      if (isEdit) {
        if (field.title === e.nativeEvent.target.name) {
        return {...field,value:{...field.value,title:e.target.value}}
        }
        return field
      }else{
        if (field.title === e.nativeEvent.target.name) {
          return { ...field, selected: e.target.value };
        }
        return field;
      }

    });
    setCustomFields(updatedFields);
  };

  const handleChangeCheckBox = (e) => {
    const { name, checked, id } = e.nativeEvent.target;
    console.log("e.nativeEvent.target.name", name);
    console.log("e.nativeEvent.target.checked", checked);

    const updatedFields = customFields.map((field) => {
      if (field.title === name) {
        if (isEdit) {
          console.log("isEdit from if", isEdit);
          // When either edit or isEdit is true
          const updatedValue = field.value.map((eachValue) => {
            if (eachValue.key === id) {
              console.log("eachValue.key", eachValue.key);
              console.log("id", id);
              return { ...eachValue, isChecked: checked };
            }
            return eachValue;
          });
          return { ...field, value: updatedValue };
        } else {
          console.log("isEdit from else ", isEdit);
          // When both edit and isEdit are false
          const selectedOptions = { ...field.selectedoptions, [id]: checked };
          return { ...field, selectedoptions: selectedOptions };
        }
      }
      return field;
    });

    console.log("updatedFields+++++++++++++++++++++", updatedFields);
    setCustomFields(updatedFields);
  };

  const onSubmit = (data) => {
    console.log("data+++++++++++++++++:", data);
    function transformData(customFields) {
      console.log("customFields+++++++++++++++++:", customFields);
      return customFields.map((item) => {
        console.log("item:", item);

        if (item.type === "check_box") {
          if (item?.options) {
            const options = Object.keys(item.options).map((key) => ({
              key: key,
              title: item.options[key],
              isChecked: item.selectedoptions[key] || false,
            }));

            const metadataOptions = Object.keys(item.options).map((key) => ({
              key: key,
              title: item.options[key],
            }));

            return {
              type: item.type,
              title: item.title,
              value: options,
              key: `ex-${Math.random().toString(36).substr(2, 16)}`,
              metadata: {
                options: metadataOptions,
              },
            };
          } else {
            return item;
          }
        } else if (item.type === "dropdown") {
          if (item.options) {
            const selectedKey = item.selected;
            const defaultOptions = Object.keys(item.options).map(
              (key, index) => ({
                key: key,
                title: item.options[key],
                _index: index,
              })
            );
            const options = defaultOptions.find(
              (option) => option.key === selectedKey
            );
            const metadataOptions = Object.keys(item.options).map(
              (key, index) => ({
                key: key,
                title: item.options[key],
                _index: index,
              })
            );
            return {
              type: item.type,
              title: item.title,
              value: options || "",
              key: `ex-${Math.random().toString(36).substr(2, 16)}`,
              metadata: {
                options: metadataOptions,
              },
            };
          } else {
            return item;
          }
        } else {
          // Handle other field types if needed
          return {
            type: item.type,
            title: item.title,
            value:
              item.type === "date"
                ? item.date ||item.value
                : item.type === "phone_number"
                ? item.phoneNumber || item.value
                : item.value,
          };
        }
      });
    }

    const transformedData = transformData(customFields);
    console.log("transformedData:", transformedData);

    const formattedData = transformedData.map((eachData) => {
      return {
        type: eachData.type,
        title: eachData.title || "",
        value: eachData.value,
        key: Math.random().toString(36).substring(2),
        metadata: eachData.metadata || {},
      };
    });

    console.log("formattedData:", formattedData);

    let dataToBeSent = {
      title: data.title,
      description: data.description,
      companyId: companyId,
      keywords: keywords,
      labelId: labelId || data.labelId,
      labelTitle: labelTitle || labelTitleFromDropDown,
      image: singleImage,
      customInput: JSON.stringify(formattedData),
      customFieldsUsed:
        customFields && customFields.length > 0 ? customFields.length : 0,
    };

    if (!singleImage) {
      return toast.error("Item Photo is required!");
    }
    if (!keywords || keywords.length === 0) {
      return toast.error("Add atleast one keyword to proceed!");
    }
    if (!labelId && !data.labelId) {
      return toast.error("Box is required!");
    }
    setIsLoading(true);
    if (editItem) {
      setIsLoading(false);
      dispatch(updateItemGenOp(itemId, dataToBeSent)).then((res) => {
        //dispatch(setItems(res.data.data));
        setIsLoading(false);
        if (res) {
          navigate(`/web/item/${labelId || data.labelId}/view`);
        }
      });
    } else {
      dispatch(addItemGenOp(dataToBeSent)).then((res) => {
        //dispatch(setItems(res.data.data));
        setIsLoading(false);
        if (res) {
          navigate(`/web/item/${labelId || data.labelId}/view`);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="block rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] mt-4 ml-2 mr-2 overflow-auto">
        {isLoading && <FullScreenLoader />}
        <div>
          {/* <BackButton /> */}
          <Modal
            firstButtonText={"Cancel"}
            secondButtonText={"Add"}
            heading={"Select the items from the dropdown!"}
            body={
              <MultiSelect
                options={selectedImageLabels?.map((option) => ({
                  value: option,
                  label: option,
                }))}
                value={selected}
                onChange={onSelectionChange}
                labelledBy="Select"
                valueRenderer={customValueRenderer}
                className="h-96"
                shouldToggleOnHover={true}
              />
            }
            modal={modal}
            pressSecondButton={(item) => onAddAnalyzedItems(item)}
            pressFirstButton={() => setModal(false)}
            pressCloseButton={() => setModal(false)}
          />
          <Modal
            dataId={itemId}
            firstButtonText={"Cancel"}
            secondButtonText={"Delete"}
            heading={"Confirm Delete"}
            body={
              <div>
                <h2>Deleting the image will delete the item!</h2>
              </div>
            }
            modal={deleteModal}
            pressSecondButton={(id) => handleDelete(id)}
            pressFirstButton={() => setDeleteModal(false)}
            pressCloseButton={() => setDeleteModal(false)}
          />
          <Modal
            firstButtonText={"Cancel"}
            secondButtonText={"Okay"}
            heading={"Add manually!"}
            body={
              <div>
                <h2>Sorry, no keywords were detected! You can add manually!</h2>
              </div>
            }
            modal={alertModal}
            pressSecondButton={() => setAlertModal(false)}
            pressFirstButton={() => navigate(-1)}
            pressCloseButton={() => navigate(-1)}
          />
          <Modal
            heading={"Create Your Own"}
            body={
              <AddForm
                onSubmit={(data) => {
                  addCustomFields(data);
                  setCustomInputModal(false);
                }}
              />
            }
            modal={customInputModal}
            pressCloseButton={() => setCustomInputModal(false)}
          />
          <div className="mt-2 mb-2 flex flex-col laptop:grid laptop:grid-cols-9 laptop:gap-2">
            <div className="mt-2 mb-2 col-span-2">
              {singleImage && Object.keys(singleImage).length !== 0 ? (
                <React.Fragment>
                  <div className="relative">
                    <img
                      alt="item"
                      src={singleImage}
                      className="object-cover"
                    />
                    <RiDeleteBin5Line
                      className="absolute top-2 right-2 cursor-pointer"
                      size={24}
                      color="red"
                      onClick={() => openDeleteModal()}
                    />
                  </div>
                </React.Fragment>
              ) : (
                <label className="w-1/6">
                  <span className="w-3/6">
                    <FcAddImage size={64} />
                  </span>
                  <UploadField
                    fieldName="image"
                    className={
                      "appearance-none rounded w-full py-2 px-4 text-gray-700 border-primary-300 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200 hidden"
                    }
                    validationObj=""
                    register={register}
                    errors={errors}
                    fieldType="upload"
                    onChange={onUpload}
                    //onRemoveFile={onRemoveFile}
                    value={""}
                    placeholder={"Upload Image"}
                  />
                </label>
              )}
              {/* <ImageSlider itemData={currentItems} /> */}
            </div>
            <div className="mt-2 mb-2 col-span-2">
              {singleImage && Object.keys(singleImage).length !== 0 ? (
                <div
                  className="flex flex-col mb-6 w-full laptop:w-3/6"
                  key="add-keyword"
                >
                  <label className="block text-gray-800 font-bold mb-3 pr-4">
                    Add Keyword
                  </label>
                  <InputField
                    fieldName="addKeyword"
                    register={register}
                    errors={errors}
                    fieldType="text"
                    placeholder="Add Keyword"
                    onChange={(e) => setAddKeyword(e.target.value)}
                  />
                  <button
                    onClick={() => addAnotherItem(addKeyword)}
                    type="button"
                    className="inline-block border-2 border-themeBlue bg-themeBlue mt-2 px-6 pt-2 pb-[6px] text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full"
                  >
                    Add
                  </button>
                </div>
              ) : null}
              <h5 className="text-2xl">Items Analyzed:</h5>
              <ul>
                {keywords &&
                  keywords.length > 0 &&
                  keywords.map((keyword) => (
                    <li className="text-xl" key={keyword}>
                      <FaCheck className="inline" color="green" /> {keyword}{" "}
                      {params.type === "edit" && (
                        <RiDeleteBin5Line
                          color="red"
                          className="cursor-pointer inline"
                          onClick={() => removeItem(keyword)}
                        />
                      )}
                    </li>
                  ))}
              </ul>
            </div>
            {/* <div className='mt-2 mb-2 col-span-2'>
              <h5 className='text-2xl'>Items Analyzed Now:</h5>
              <ul>
                {newKeywords &&
                  newKeywords.length > 0 &&
                  newKeywords.map((keyword) => (
                    <li key={keyword}>{keyword}</li>
                  ))}
              </ul>
            </div> */}
            <div className="mt-2 mb-2 col-span-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col">
                  {fields &&
                    fields.map((field) => {
                      return (
                        <div
                          className="flex flex-col mb-6 w-full laptop:w-3/6"
                          key={field.fieldName}
                        >
                          <label className="block text-gray-800 font-bold mb-3 pr-4">
                            {field.fieldTitle}
                          </label>
                          <InputField
                            {...field}
                            register={register}
                            errors={errors}
                            selectOnChange={(e) => {
                              const index = e.nativeEvent.target.selectedIndex;
                              setLabelTitleFromDropDown(
                                e.nativeEvent.target[index].text
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  {customFields &&
                    customFields.map((field, index) => {
                      console.log("field++++++++", field);
                      return (
                        <RenderCustomInput
                          setValue={setValue}
                          Controller={Controller}
                          control={control}
                          index={index}
                          fieldType={field.type}
                          fieldName={field.title}
                          fieldTitle={field.title}
                          register={register}
                          errors={errors}
                          placeholder={field.title}
                          defaultValue={field.value}
                          dateValue={field.date||field.value}
                          phoneNumber={field.phoneNumber ||field.value}
                          fieldOptions={field.options || field.value}
                          dropdownOptions={field.type==="dropdown"?field?.metadata?.options:null}
                          selected={field.selectedoptions}
                          selectedRadio={field.selected}
                          onChange={(e) => handleChange(e)}
                          onDateChange={(e, fieldName) =>
                            handleDateChange(e, fieldName)
                          }
                          onChangePhone={(e) => handlePhoneChange(e)}
                          onChangeDropdown={(e) => handleChangeDropdown(e)}
                          onChangeCheckBox={(e) => handleChangeCheckBox(e)}
                        />
                      );
                    })}
                  {/* <Tooltip
                    title={
                      (plan === "Free" || plan === "Pro") &&
                      customFields?.length >= 2
                        ? "Based on your plan, you have reached the limit to add custom fields. Upgrade your plan to add more."
                        : plan === "Premium" && customFields?.length >= 100
                        ? "Based on your plan, you have reached the limit to add custom fields. Upgrade your plan to add more."
                        : ""
                    }
                  > */}
                  <button
                    onClick={() => openCustomInputModal()}
                    className="w-full laptop:w-3/6 inline-block border-2 border-themeBlue bg-themeBlue mt-2 px-6 pt-2 pb-[6px] text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full"
                    type="button"
                    // disabled={
                    //   (plan === "Free" || plan === "Pro") &&
                    //   customFields?.length >= 2
                    //     ? true
                    //     : plan === "Premium" && customFields?.length >= 100
                    //     ? true
                    //     : false
                    // }
                  >
                    + Add Fields
                  </button>
                  {/* </Tooltip> */}
                </div>
                <div className="flex flex-col tablet:flex-row tablet:justify-between laptop:w-3/6">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className={`inline-block border-2 border-red-600 bg-red-600 mt-2 px-6 py-2 text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-red-600 hover:bg-red-500 hover:text-white focus:border-red-600 focus:text-white focus:outline-none focus:ring-0 active:border-red-600 active:text-white rounded-full`}
                  ><MdOutlineCancel  size={23}
                  className="min-w-max mr-1 mb-1 inline-flex"/>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    // onClick={handleSubmit(onSubmit)}
                    className={`inline-block border-2 border-themeBlue bg-themeBlue mt-2 px-6 pt-2 pb-[6px] text-xl font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full tablet:ml-4`}
                  ><IoMdSend  size={23}
                  className="min-w-max mr-1 mb-1 inline-flex"/>
                    {editItem ? "Update" : "Submit"}{" "}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FormItem;
