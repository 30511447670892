import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from '../../components/interceptor/interceptor';
import {
  ADD_QR,
  CREATE_QR,
  UPDATE_QR,
  GET_QRs,
  REMOVE_QR,
} from '../../apiConst';

export const createQRAction = (data) => ({
  type: 'CREATE_QR',
  payload: postRequest(`${CREATE_QR}`, data),
});

export const addQRAction = (data) => ({
  type: 'ADD_QR',
  payload: postRequest(`${ADD_QR}`, data),
});

export const getQRsAction = (pageNumber, perPage) => ({
  type: 'GET_QRCODES',
  payload: getRequest(`${GET_QRs}?perPage=${perPage}&pageNum=${pageNumber}`),
});

export const setQRCodes = (data) => ({
  type: 'SET_QRCODES',
  payload: { ...data },
});

export const updateQRAction = (labelId, data) => ({
  type: 'UPDATE_QR',
  payload: putRequest(`${UPDATE_QR}${labelId}`, data),
});

export const removeQRAction = (data) => ({
  type: 'REMOVE_QRCODES',
  payload: deleteRequest(`${REMOVE_QR}${data}`),
});
