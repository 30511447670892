export const planDetails = [
  {
    title: 'Free',
    descriptionText: 'Best for personal use.',
    buttonText: 'Sign Up',
    price: '0.00',
    userLicenses: 2,
    fields: 2,
    items: 200,
    point1: 'Universal Search',
    point2: 'Basic Customizable Alerts',
    point3: 'Barcode Scanning',
    point4: null,
    point5: 'Image scanning with 70% accuracy',
    icon: 'FaPaperPlane',
    priceId: 'price_1P0f6aKvsKjb9FIoXBeAiOCh',
  },
  {
    title: 'Pro',
    descriptionText: 'Best for small businesses.',
    buttonText: 'Start Free Trial',
    price: '9.99',
    userLicenses: 5,
    fields: 2,
    items: 2000,
    point1: 'Priority Support',
    point2: 'Advanced Customizable Alerts',
    point3: 'Export/Import Functionality',
    point4: 'Standard Reports',
    point5: 'Image scanning with 90% accuracy',
    icon: 'GiAirBalloon',
    priceId: 'price_1P0fBRKvsKjb9FIoZov9w92f',
  },
  {
    title: 'Premium',
    descriptionText: 'Best for medium businesses.',
    buttonText: 'Start Free Trial',
    price: '19.99',
    userLicenses: 10,
    fields: 100,
    items: 2000,
    point1: 'Premium Support',
    point2: 'Advanced Reporting and Analytics',
    point3: 'Enhanced AI-Powered Efficiency',
    point4: null,
    point5: null,
    icon: 'MdFlightTakeoff',
    priceId: 'price_1P0fHPKvsKjb9FIoMtaWEs4V',
  },
  {
    title: 'Infinity',
    descriptionText: 'Best for larger businesses.',
    buttonText: 'Start Free Trial',
    price: '49.99',
    userLicenses: 20,
    fields: 'unlimited',
    items: 'Unlimited',
    point1: 'Dedicated Account Manager',
    point2: 'VIP Access to New Features and Beta Testing',
    point3: 'Tailored AI Solutions for Specific Needs',
    point4: 'On-Site Training and Implementation Support',
    point5: null,
    icon: 'IoMdRocket',
    priceId: 'price_1P0fnGKvsKjb9FIoWGzU4A5b',
  },
];
