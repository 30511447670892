/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { postRequest } from "../../components/interceptor/interceptor";
import { STRIPE_PAYMENT_INTENT } from "../../apiConst";
import { toast } from "react-toastify";
import CheckoutForm from "../../components/CheckoutForm";
import { get, isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  activatePersonalGenOp,
  createSubscriptionGenOp,
  getSubscriptionGenOp,
} from "../../redux/operations/subscription.operations";
import { setSubscription } from "../../redux/actions/subscription.action";
import { getUserGenOp } from "../../redux/operations/user.operations";
import { setUser } from "../../redux/actions/login.action";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
import { Card } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { FaPaperPlane } from "react-icons/fa";
import { MdFlightTakeoff } from "react-icons/md";
import { GiAirBalloon } from "react-icons/gi";
import { IoMdRocket } from "react-icons/io";
import clsx from "clsx";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 900],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const stripeKey = process.env.REACT_APP_STRIPE;

const stripePromise = loadStripe(stripeKey);

function Checkout() {
  const location = useLocation();
  const userId = useSelector((state) => state.user._id);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [plan, setPlan] = useState("");
  const [priceId, setPriceId] = useState("");
  const [planType, setPlanType] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isYearly } = get(location, "state");
  useEffect(() => {
    if (!isEmpty(get(location, "state.data"))) {
      const { isCheckout } = get(location, "state");
      const { isYearly } = get(location, "state");
      if (isCheckout) {
        setPrice(location.state.data.price);
        setPlan(location.state.data.title);
        setPriceId(
          isYearly
            ? location.state.data.yearly_priceId
            : location.state.data.priceId
        );
        setPlanType(location.state.data.planType);
        setDescription(location.state.data.descriptionText);
      }
      console.log("🚀 ~ useEffect ~ location.state.data:", location.state.data);
    }
  }, []);

  console.log('priceId',priceId)
  console.log('plan',plan)
  console.log('isYearly',isYearly)

  const appearance = {
    theme: "stripe",
  };
  const options = {
    mode: "subscription",
    appearance,
    currency: "usd",
    amount: Math.round(parseFloat(price)) * 100,
  };

  const activateFree = () => {
    setIsLoading(true);
    const wait = (ms) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, ms);
      });
    };
    const dataToBeSent = {
      description: plan,
      customer: userId,
      id: userId,
      email: user?.email,
      name: user?.name || user?.email,
    };
    try {
      dispatch(activatePersonalGenOp(dataToBeSent))
        .then(() =>
          wait(3000).then(() =>
            dispatch(getUserGenOp(userId)).then(
              (res) => dispatch(setUser(res.data)),
              dispatch(getSubscriptionGenOp(userId)).then((res) => {
                toast.success(
                  `${res.data.description} is now ${res.data.status}`
                );
                dispatch(setSubscription(res.data));
              })
            )
          )
        )
        .then(() => navigate("/web/dashboard"));
    } catch (error) {
      console.log("🚀 ~ file: Checkout.js:72 ~ activateFree ~ error:", error);
    }
  };

  const activateTrial = () => {
    setIsLoading(true);
    const wait = (ms) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, ms);
      });
    };
    const subscriptionData = {
      name: user?.name || user?.email,
      email: user?.email,
      priceId: priceId,
      plan: plan,
      userId: userId,
    };
    dispatch(createSubscriptionGenOp(subscriptionData)).then(() =>
      wait(5000).then(() =>
        dispatch(getUserGenOp(userId))
          .then(
            (res) => dispatch(setUser(res.data)),
            dispatch(getSubscriptionGenOp(userId)).then((res) => {
              toast.success(
                `${res?.data?.description} is now ${res?.data?.status}`
              );
              dispatch(setSubscription(res.data));
            })
          )
          .then(() => navigate("/web/dashboard"))
      )
    );
  };

  return (
    <React.Fragment>
      <Card className="m-3 p-5">
        <div className="flex flex-col laptop:flex-row items-center justify-around gap-10 ">
          <div
            className={`border border-solid p-5 shadow-xl mb-4 flex flex-col justify-between rounded-3xl w-full laptop:w-1/2`}
          >
            <h2 className="text-2xl font-bold mb-2 text-center">{plan}</h2>
            <p className="text-center mb-4">{description}</p>
            <h1 className="text-4xl font-extrabold mb-4 text-center">
              ${price}
              <span className="text-lg font-semibold"> {`/${isYearly?'Year':'Month'}`}.</span>
            </h1>
            <div className="text-sm">
              <h3 className="mb-2 font-bold text-gray-700">
                {location.state.data.userLicenses} User Licenses
              </h3>
              <BorderLinearProgress
                variant="determinate"
                value={location.state.data.userLicenses}
              />
            </div>
            <div className="text-sm">
              <div className="mb-2 font-bold text-gray-700">
                {location.state.data.fields === "unlimited"
                  ? "Unlimited"
                  : location.state.data.fields}{" "}
                Customizable Fields
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={
                  location.state.data.fields === "unlimited"
                    ? 100
                    : location.state.data.fields
                }
              />
            </div>
            <div className="text-sm">
              <div className="mb-2 font-bold text-gray-700">
                {location.state.data.items} Items
              </div>
              <BorderLinearProgress
                variant="determinate"
                value={location.state.data.items / 2}
              />
            </div>
            <div className="h-40 mt-2 overflow-scroll">
              <h2 className="text-md mt-1 mb-1 font-bold">Features</h2>
              {location.state.data.point1 && (
                <p className="text-sm mb-2">{location.state.data.point1}</p>
              )}
              {location.state.data.point2 && (
                <p className="text-sm mb-2">{location.state.data.point2}</p>
              )}
              {location.state.data.point3 && (
                <p className="text-sm mb-2">{location.state.data.point3}</p>
              )}
              {location.state.data.point4 && (
                <p className="text-sm mb-2">{location.state.data.point4}</p>
              )}
              {location.state.data.point5 && (
                <p className="text-sm mb-2">{location.state.data.point5}</p>
              )}
            </div>
          </div>
          <div className="w-full laptop:w-1/2">
            {price && user?.plan?.length > 0 ? (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  plan={plan}
                  priceId={priceId}
                  email={user?.email}
                  name={user?.name || user?.email}
                  planType={planType}
                  customer={userId}
                  price={price}
                  setPrice={setPrice}
                  isYearly={isYearly}
                />
              </Elements>
            ) : (
              <React.Fragment>
                {!isLoading ? (
                  <button
                    className={
                      "inline-block border-2 border-themeBlue bg-themeBlue mt-2 px-4 py-2 text-lg font-semibold leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full w-full"
                    }
                    onClick={() =>
                      plan === "Pro" || plan === "Premium"
                        ? activateTrial()
                        : activateFree()
                    }
                  >
                    {plan === "Pro" || plan === "Premium"
                      ? "Activate Trial"
                      : "Activate Free"}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="pointer-events-none inline-block bg-themeBlue px-4 py-2 text-sm font-semibold uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 disabled:opacity-70 rounded-full w-full"
                    disabled
                  >
                    <div
                      className="inline-block h-3 w-3 animate-spin rounded-full bg-current align-middle mr-1"
                      role="status"
                    ></div>
                    <span>Please wait...</span>
                  </button>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default Checkout;
