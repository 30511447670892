export const genQRFields = [
  {
    fieldName: 'qr',
    fieldTitle: 'Number Of Boxes',
    placeholder: 'Number Of Boxes',
    validationObj: {
      required: 'Please Enter Required Number of Boxes',
    },
    fieldType: 'number',
  },
];
