import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AddUserFields } from "../../Fields/userFields";
import InputField from "../../common/InputField";
import { useDispatch, useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import {
  addUserGenOp,
  getUserByAdminGenOp,
  deleteUserByAdminGenOp,
} from "../../redux/operations/user.operations";
import { toast } from "react-toastify";
import { setUser } from "../../redux/actions/login.action";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import DataTable from "react-data-table-component";
import { Card } from "@mui/material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import { Helmet } from "react-helmet";
import FullScreenLoader from "../../components/Loader/FullScreenLoader";
const AddUser = () => {
  const [fields, setFields] = useState();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState([]);
  const plan = useSelector((state) => state?.user?.plan);
  const [addedusers, setAddedUsers] = useState(
    plan&&plan?.length>0 && plan[0].currentPlan === "Free"
      ? 1
      : plan&&plan?.length>0 && plan[0].currentPlan === "Pro"
      ? 4
      : plan&&plan?.length>0 && plan[0].currentPlan === "Premium"
      ? 9
      : plan&&plan?.length>0 && plan[0].currentPlan === "Infinity"
      ? 19
      : 0
  );
  const [usersCount, setUsersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlan,setCurrentPlan]=useState(plan&&plan?.length>0 && plan[0].currentPlan)

  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (!fields) {
      setFields(AddUserFields);
    }
  }, [fields]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setIsLoading(true);
    dispatch(getUserByAdminGenOp())
      .then((res) => {
        setData(
          res.data.map((eachData, index) => ({ ...eachData, sno: index + 1 }))
        );
        setUsersCount(res?.data?.length || 0);
        //   dispatch(setItems(res));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("Error", error);
      });
  };
  const handleDeleteUserByAdmin = (id,currentPlan) => {
    console.log('userId',id)
    console.log('currentPlan',currentPlan)

    setIsLoading(true)
    dispatch(deleteUserByAdminGenOp(id,currentPlan))
      .then((res) => {
        console.log("response delete", res);
        setIsLoading(false)
        toast.success(res.data.message);
        getUsers();
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.response?.data?.message || "Error while Deleting user")
        setIsLoading(false)
      });
  };

  const onSubmit = (value) => {
    const allowedUsers = addedusers - usersCount;
    if (allowedUsers <= 0) {
      toast.error("You have reached the limit! please upgrade your Plan");
      reset();
    } else {
        setIsLoading(true)
      const phoneNumber = value.phoneNumber;
      let formData = new FormData();
      formData.append("name", value.name);
      formData.append("phoneNumber", phoneNumber);
      formData.append("email", value.email);
      if (phoneNumber?.length > 0) {
        setIsLoading(false)
        const isPhoneNumber = isPossiblePhoneNumber(phoneNumber);
        if (!isPhoneNumber) {
            setIsLoading(false)
          return toast.error("Enter a valid phone number!");
        }
      }
      dispatch(addUserGenOp(formData)).then((res) => {
        if (res.status === 200) {
            setIsLoading(false)
          reset();

          getUsers();
          toast.success(res.data.message);
        } else {
            setIsLoading(false)
          toast.error(res.data.message || res.message);
          reset();
        }
      }).catch((error)=>{
        setIsLoading(false)
        reset();
        toast.error(error.response.data.message)
      })
    }
  };

  const columns = [
    {
      name: "SNo",
      selector: (row) => row.sno,
      sortable: true,
      width: "75px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => <Tooltip title={row.email}>{row.email}</Tooltip>,
      sortable: true,
    },

    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.userType,
      sortable: true,
    },
    {
      name: "Invited At",
      selector: (row) => moment(row.createdAt).format("MMM DD, YYYY"),
      sortable: true,
    },
    // {
    //     name: "Last Login At",
    //     selector: (row) => moment(row.lastLogin).format("MMM DD, YYYY"),
    //     sortable: true,
    //   },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {/* <IconButton size="small">
              <Tooltip title="Edit">
                <EditIcon color="primary" />
              </Tooltip>
            </IconButton> */}

          <IconButton
            size="small"
            onClick={() => handleDeleteUserByAdmin(row._id,currentPlan)}
          >
            <Tooltip title="Delete">
              <DeleteIcon color="error" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      width: "100px",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>{`SCANS  |  Users`}</title>
        <meta name="description" content='Users' />
      </Helmet>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <Card className="mt-2 mb-2 p-3">
          <h1 className="text-md laptop:text-xl font-bold mb-4">Add User</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4 gap-6">
              {fields &&
                fields.map((field) => (
                  <div className="flex flex-col" key={field.fieldName}>
                    <label className="block text-gray-800 font-bold mb-3">
                      {field.fieldTitle}
                    </label>
                    <InputField
                      {...field}
                      register={register}
                      errors={errors}
                      className="shadow appearance-none border-2 border-gray-500 rounded-xl w-full  py-3.5 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                    />
                  </div>
                ))}
              <div className="flex flex-col">
                <label className="block text-gray-800 font-bold mb-3">
                  Phone Number
                </label>
                <PhoneInputWithCountry
                  flags={flags}
                  className="shadow appearance-none border-2 border-gray-500  rounded-xl w-full  py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200"
                  defaultCountry="US"
                  name="phoneNumber"
                  defaultValue={''}
                  control={control}
                  placeholder="Phone Number"
                />
              </div>
              <div className="flex flex-col tablet:mt-9">
                <button
                  type="submit"
                  className={`inline-block border-2 border-themeBlue bg-themeBlue px-6 py-2 text-md tablet:ml-3 font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full laptop:w-3/4`}
                >
                   <IoMdAdd size={23} className="mr-1 inline-flex mb-1 text-white" /> Add User
                </button>
              </div>
            </div>
          </form>

          <div className="mt-10 mb-3 ">
            <h2 className="text-md laptop:text-xl font-bold">Current Users</h2>
          </div>

          <div className="rounded-xl">
            {data ? (
              <DataTable
                columns={columns}
                data={data}
                pagination
                striped
                highlightOnHover
                pointerOnHover
                customStyles={customStyles}
              />
            ) : (
              <p>No Users data available.</p>
            )}
          </div>
        </Card>
      )}
    </div>
  );
};
const customStyles = {
  subHeader: {
    style: {
      backgroundColor: "blue",
    },
  },
  head: {
    style: {
      color: "black",
      fontSize: "13px",
      fontWeight: 600,
      minHeight: "2px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#ADC8E3",
      minHeight: "45px",
      borderBottomWidth: "1px",
      borderBottomColor: "#ffffff",
      borderBottomStyle: "solid",
    },
  },

  rows: {
    style: {
      fontSize: "13px",
      minHeight: "45px",
      fontWeight: 600,
    },
  },
};
export default AddUser;
