import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';

const ComparePlansTable = () => {
  return (
    <>
      <div className="hidden laptop:flex laptop:justify-center laptop:items-center bg-blue-100 p-4">
        <h1 className="text-4xl text-gray-700 font-extrabold ">
          Compare Plans
        </h1>
      </div>
      <div className=" hidden laptop:flex laptop:flex-col laptop:justify-center p-5 bg-blue-100 shadow-xl">
        <table className="border-collapse border border-gray-400 bg-themeBodyBg">
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                ORGANIZE
              </th>
              <th className="border border-gray-200 text-white px-4 py-2">
                FREE
              </th>
              <th className="border border-gray-200 text-white px-4 py-2">
                PRO
              </th>
              <th className="border border-gray-200 text-white px-4 py-2">
                PREMIUM
              </th>
              <th className="border border-gray-200 text-white px-4 py-2">
                INFINITY
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">Items</td>
              <td className="border border-gray-200 px-4 text-center  py-2">
                200
              </td>
              <td className="border border-gray-200 px-4 text-center  py-2">
                2000
              </td>
              <td className="border border-gray-200 px-4 text-center  py-2">
                2000
              </td>
              <td className="border border-gray-200 px-4 text-center py-2">
                Unlimited
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  ">
                {' '}
                User Licenses
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center ">
                2
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center ">
                5
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center ">
                10
              </td>
              <td className="border border-gray-200 px-4 py-2 text-center ">
                20
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                {' '}
                Inventory Import
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">Item Photos</td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Inventory Lists
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                CUSTOMIZE
              </th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Custom Fields
              </td>
              <td className="border border-gray-200 px-4 text-center  py-2">
                2
              </td>
              <td className="border border-gray-200 px-4 text-center  py-2">
                2
              </td>
              <td className="border border-gray-200 px-4 text-center  py-2">
                100
              </td>
              <td className="border border-gray-200 px-4 text-center py-2">
                Unlimited
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  ">
                {' '}
                Custom Folders
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2"> Custom Tags</td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">
                Customizable User Access
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200 px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Customizable Roles
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                MANAGE
              </th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Barcode & QR Code Scanning
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  ">
                {' '}
                QR Code Label Generation
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Barcode Label Generation
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">
                Item Check-in/Check-out
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Purchase Order
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                TRACK AND UPDATE
              </th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Low Stock Alerts
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  ">
                Date-based Alerts
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                {' '}
                Offline Mobile Access
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">
                Automatic Sync
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                In-app Alerts
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">Email Alerts</td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                REPORT
              </th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Activity History Reports
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  ">
                {' '}
                Inventory Summary Reports
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                {' '}
                User Activity Summary Reports
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">
                Low Stock Reports
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Move Summary Reports
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Item Flow Report
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Saved Reports
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Report Subscriptions
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Activity History
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                1 month
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                1 year
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                3 years
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                Unlimited
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Transaction Reports
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                1-month limit
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                1-year limit
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                3-years limit
              </td>
              <td className="border border-gray-200 text-center px-4 py-2">
                Unlimited
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                INTEGRATIONS
              </th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                QuickBooks Online
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  "> Slack</td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2"> Webhooks</td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">
                Microsoft Teams
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">API</td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
          </tbody>
          <thead>
            <tr className="bg-gray-700">
              <th className="border border-gray-200 text-white px-4  py-2">
                SUPPORT
              </th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
              <th className="border border-gray-200 text-white px-4  py-2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Help Center Resources & Tutorials
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2  ">
                {' '}
                Weekly Onboarding Webinars
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                {' '}
                Email Support
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2 ">
                Scheduled Phone Support
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Onboarding Training Session
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Dedicated Customer Success Manager
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
            <tr>
              <td className="border border-gray-200 px-4 py-2">
                Custom Inventory Setup & Upload
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <IoCloseSharp className="m-auto" />
              </td>
              <td className="border border-gray-200  px-4 py-2">
                <FaCheck className="m-auto" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ComparePlansTable;
