import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';

const UploadField = ({
  fieldId,
  fieldName,
  register,
  validationObj,
  placeholder,
  onChange,
  onBlur,
  className,
  valueClassName = 'flex justify-around items-center',
  errors,
  disabled,
  value,
  onRemoveFile,
}) => {
  return (
    <>
      <input
  id={fieldId}
  name={fieldName}
  {...register(fieldName, {
    onChange: (e) => {
      const file = e.target.files[0];
      if (file && !file.type.startsWith('image/')) {
        e.target.value = ''; // Clear the input
        // Optionally, you can provide feedback to the user, e.g., show an error message
        alert('Please select only image files.');
        return;
      }
      onChange && onChange(e);
    },
    onBlur: (e) => onBlur && onBlur(e),
    ...validationObj,
  })}
  type='file'
  className={className}
  placeholder={placeholder}
  disabled={disabled}
  accept="image/*"
/>
      {value && (
        <div className={valueClassName}>
          <div>{value}</div>
          <div
            className='cursor-pointer hover:text-danger ml-2'
            onClick={onRemoveFile}
          >
            <FaRegTrashAlt />
          </div>
        </div>
      )}

      <p className='py-2 text-danger'>
        {errors[fieldName] && errors[fieldName].message}
      </p>
    </>
  );
};

export default UploadField;
