import React from 'react';

const InputField = ({
  maxLength,
  fieldId,
  fieldName,
  fieldLabel,
  fieldType,
  register,
  validationObj,
  placeholder,
  onChange,
  onBlur,
  className = 'shadow appearance-none  border-1 border-gray-600 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-primary-300 disabled:bg-gray-200',
  errors,
  ref,
  children,
  multiple,
  required,
  disabled,
  defaultValue,
  value,
  onClick,
  keyDown,
  Icon,
  options,
  selectOnChange,
}) => {
  return (
    <React.Fragment>
      {fieldType === 'textarea' ? (
        <textarea
          name={fieldName}
          id={fieldId}
          {...register(fieldName, {
            onChange: (e) => onChange && onChange(e),
            onBlur: (e) => onBlur && onBlur(e),
            ...validationObj,
          })}
          className={className}
          placeholder={placeholder}
          disabled={disabled}
        />
      ) : fieldType === 'text' || fieldType === 'email' ? (
        <React.Fragment>
          {Icon ? (
            <label className="relative block">
              <Icon className="absolute top-1/2 transform -translate-y-1/2 left-3" />
              <input
                id={fieldId}
                name={fieldName}
                maxLength={maxLength}
                ref={ref}
                {...register(fieldName, {
                  onChange: (e) => onChange && onChange(e),
                  onBlur: (e) => onBlur && onBlur(e),
                  ...validationObj,
                })}
                type={fieldType}
                className="w-full pl-16 pr-4 bg-blue-100 border border-transparent focus:bg-white focus:border-gray-300 focus:outline-none rounded-3xl h-18 p-4 placeholder-gray-500 rounded text-gray-700 text-sm"
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value}
                onKeyDown={keyDown}
              />
            </label>
          ) : (
            <input
              id={fieldId}
              name={fieldName}
              maxLength={maxLength}
              ref={ref}
              {...register(fieldName, {
                onChange: (e) => onChange && onChange(e),
                onBlur: (e) => onBlur && onBlur(e),
                ...validationObj,
              })}
              type={fieldType}
              className={className}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              defaultValue={defaultValue}
              value={value}
              onKeyDown={keyDown}
            />
          )}
        </React.Fragment>
      ) : fieldType === 'select' ? (
        <select
          {...register(fieldName)}
          defaultValue={defaultValue}
          name={fieldName}
          id={fieldName}
          className={className}
          onChange={selectOnChange}
        >
          <option value="">Select</option>
          {options &&
            options.map((option, index) => (
              <>
                {Array.isArray(option) ? (
                  option.map((data, key) => (
                    <option key={key} value={data.value}>
                      {data.label}
                    </option>
                  ))
                ) : (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                )}
              </>
            ))}
        </select>
      ) : fieldType === 'number' ? (
        <React.Fragment>
          <input
            id={fieldId}
            name={fieldName}
            maxLength={maxLength}
            ref={ref}
            {...register(fieldName, {
              onChange: (e) => onChange && onChange(e),
              onBlur: (e) => onBlur && onBlur(e),
              ...validationObj,
            })}
            type={fieldType}
            className={className}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            onKeyDown={keyDown}
          />
        </React.Fragment>
      ) : null}
      <p className="py-2 text-danger">
        {errors[fieldName] && errors[fieldName].message}
      </p>
    </React.Fragment>
  );
};

export default InputField;
