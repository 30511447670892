import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from '../../components/interceptor/interceptor';
import {
  ADD_ITEM,
  UPDATE_ITEM,
  GET_ITEMS,
  GET_ITEMS_BY_USER,
  REMOVE_ITEM,
} from '../../apiConst';

export const addItemAction = (data) => ({
  type: 'ADD_ITEM',
  payload: postRequest(`${ADD_ITEM}`, data),
});

export const getItemsAction = (id) => ({
  type: 'GET_ITEMS',
  payload: getRequest(`${GET_ITEMS}${id}`),
});

export const getItemsByUserAction = (type,pageNumber, perPage) => ({
  type: 'GET_ITEMS_BY_USER',
  payload: getRequest(
    `${GET_ITEMS_BY_USER}?perPage=${perPage}&pageNum=${pageNumber}&type=${type}`
  ),
});

export const setItems = (data) => ({
  type: 'SET_ITEMS',
  payload: { ...data },
});

export const updateItemAction = (id, data) => ({
  type: 'UPDATE_ITEM',
  payload: putRequest(`${UPDATE_ITEM}${id}`, data),
});

export const removeItemAction = (id) => ({
  type: 'REMOVE_ITEM',
  payload: deleteRequest(`${REMOVE_ITEM}${id}`),
});
