/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../redux/actions/items.action";
import {
  getItemsGenOp,
  removeItemGenOp
} from "../redux/operations/items.operations";
import FullScreenLoader from "./Loader/FullScreenLoader";
import HomeCardWithImage from "./HomeCardWithImage";
import Menu from "../scenes/menu/Menu";
// import ImageSlider from './ImageSlider';
import { useNavigate } from "react-router-dom";
import BackButton from "../common/BackButton";
import FormItem from "./FormItem";
import Modal from "../components/Modal";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTable from "react-data-table-component";
import ItemsTable from "../scenes/table/ItemsTable";

function ViewItems() {
  const items = useSelector((state) => state.items.data);
  const params = useParams();
  const labelId = params.labelId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [viewItem, setViewItem] = useState(false);
  const [modal, setModal] = useState(false);


  const [dataId, setDataId] = useState("");
const {isGrid} =useSelector((state)=>state.menu)
  const getItems = (id) => {
    setIsLoading(true);
    dispatch(getItemsGenOp(id)).then((res) => {
      console.log("item", res);
      dispatch(setItems(res));
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (params.type === "view") {
      setViewItem(true);
    } else {
      setViewItem(false);
    }
  }, []);

  useEffect(() => {
    if (labelId) {
      const id = labelId;
      getItems(id);
    }
  }, []);

  const viewCurrentItem = (item) => {
    navigate(`/web/item/${item.labelId}/${item._id}/view`, {
      state: {
        data: item,
        isView: true
      }
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    dispatch(removeItemGenOp(id)).then((res) => {
      if (res) {
        getItems(labelId);
        setModal(false);
        navigate("/web/qr/boxes");
        setIsLoading(false);
      }
    });
    setIsLoading(false);
  };

  const openModal = (item) => {
    setModal(true);
    setDataId(item._id);
  };

  // const goToEdit = (item) => {
  //   navigate(`/web/items/${labelId}/${item._id}/edit`, {
  //     state: {
  //       data: item,
  //       isEdit: true,
  //     },
  //   });
  // };

  const columns = [
    // {
    //   name: "SNo",
    //   selector: (row) => row._id,
    //   sortable: true,
    //   width: "75px"
    // },
    {
      name: "Title",
      selector: (row) => (
        // <a
        //   href="#"
        //   className="text-blue-900"
        //   onClick={() => viewItem(row)}
        // >
        //   {
            row.title
        //   }
        // </a>
      ),
      sortable: true
    },
    {
      name: "Lable-Title",
      selector: (row) => (
        // <a
        //   href="#"
        //   className="text-blue-900"
        //   onClick={() => viewItem(row)}
        // >
        // {
          row.labelTitle
        // }
        // </a>
      ),
      sortable: true
    },
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true
    },
    {
      name: "Updated Date",
      selector: (row) => moment(row.updatedAt).format("DD-MM-YYYY"),
      sortable: true
    },

    {
      name: "Action",
      selector: (row) => (
        <div>
          {/* <IconButton size="small">
          <Tooltip title="Edit">
            <EditIcon color='primary' />
          </Tooltip>
        </IconButton> */}

          <IconButton
            size="small"
            onClick={() => openModal(row)}
          >
            <Tooltip title="Delete">
              <DeleteIcon color="error" />
            </Tooltip>
          </IconButton>
        </div>
      ),
      width: "100px"
    }
  ];

  return (
    <div>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <React.Fragment>
            <div className="flex justify-between items-center">
              <h5 className="mt-1 text-xl self-start font-bold leading-tight text-neutral-800">
                Inventory Items
              </h5>
            <Menu
            />
          </div>
          <React.Fragment>
            
            <Modal
              dataId={dataId}
              firstButtonText={"Cancel"}
              secondButtonText={"Delete"}
              heading={"Confirm Delete"}
              body={<p>Are you sure you want to delete item?</p>}
              modal={modal}
              pressSecondButton={(id) => handleDelete(id)}
              pressFirstButton={() => setModal(false)}
              pressCloseButton={() => setModal(false)}
            />
          </React.Fragment>
          {isGrid ? (
            <div className="flex flex-row flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-2 justify-items-center">
              {viewItem && items && items.length > 0 ? (
                <>
                  {items.map((item) => (
                    <HomeCardWithImage
                      title={item?.title}
                      image={item?.image[0]}
                      link=""
                      boxTitle={item?.labelTitle}
                      updatedAt={item?.updatedDate
                      }
                      onClick={() => viewCurrentItem(item)}
                      deleteBtn={() => openModal(item)}
                    />
                  ))}
                </>
              ) : (
                <div>
                  {/* <BackButton /> */}
                  <FormItem />
                </div>
              )}
            </div>
          ) : (
            
                <ItemsTable data={items} viewItem={viewCurrentItem} openModal={openModal}  />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

const customStyles = {
  subHeader: {
    style: {
      backgroundColor: "blue"
    }
  },
  head: {
    style: {
      color: "black",
      fontSize: "13px",
      fontWeight: 600,
      minHeight: "2px"
    }
  },
  headRow: {
    style: {
      backgroundColor: "#ADC8E3",
      minHeight: "45px",
      borderBottomWidth: "1px",
      borderBottomColor: "#ffffff",
      borderBottomStyle: "solid"
    }
  },

  rows: {
    style: {
      fontSize: "13px",
      minHeight: "45px",
      fontWeight: 600
    }
  }
};

export default ViewItems;
