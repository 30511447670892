import React from 'react';
import moment from 'moment';
import noImage from '../assets/noImage.png';
import { RiDeleteBin5Line } from 'react-icons/ri';

function HomeCardWithImage({
  title,
  image,
  boxTitle,
  updatedAt,
  onClick,
  deleteBtn,
}) {
  return (
    <>
      <div className="flex flex-col max-w-sm laptop:w-64 bg-white border border-gray-200 rounded-lg shadow mb-2">
        <div className="flex justify-center items-center">
          <div className="rounded-lg overflow-hidden p-1">
            <img
              src={image || noImage}
              alt={title}
              height={300}
              width={300}
              className="rounded-lg rounded-b-none max-h-40 cursor-pointer"
              onClick={onClick}
            />
          </div>
        </div>
        <div className="mt-auto p-4">
          <div className="flex flex-row justify-between">
            <span
              className="mb-2 font-normal text-gray-700 text-justify hover:text-blue-800 hover:underline   truncate cursor-pointer"
              onClick={onClick}
            >
              {boxTitle}
            </span>
            {deleteBtn ? (
              <RiDeleteBin5Line
                size={26}
                color="red"
                className="cursor-pointer"
                onClick={deleteBtn}
              />
            ) : null}
          </div>
          <h5
            className="mb-2 text-2xl font-proximaNova hover:text-blue-800 hover:underline tracking-tight text-gray-900 truncate cursor-pointer"
            onClick={onClick}
          >
            {title}
          </h5>
          <p className="font-normal text-gray-500">
            Last updated on{' '}
            <span>{moment(updatedAt).format('MM-DD-YYYY')}</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default HomeCardWithImage;
