
/* eslint-disable react-hooks/exhaustive-deps */
import { values } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { RiDeleteBinLine } from 'react-icons/ri';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

function AddForm({ data, onSubmit }) {
  const {
    register,
    watch,
    reset,
    setValue,
    getValues,
    control,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({});
  const [options, setOptions] = useState([]);
  const [refresh, setRefresh] = useState(1);
  const [date, setDate] = React.useState(
    moment(new Date(Date.now())).format('MM-DD-YYYY')
  );

  const handleChange = (dateChange) => {
    const formattedDate = moment(dateChange).format('MM-DD-YYYY');
    setValue('date', formattedDate, {
      shouldDirty: true,
    });
    setDate(formattedDate);
  };

  const field_types = [
    { label: 'Text', value: 'text' },
    { label: 'Description', value: 'description' },
    { label: 'Dropdown', value: 'dropdown' },
    { label: 'Check Box', value: 'check_box' },
    { label: 'Number', value: 'number' },
    { label: 'Decimal', value: 'decimal' },
    { label: 'Date', value: 'date' },
    { label: 'Phone Number', value: 'phone_number' },
    { label: 'Web Site', value: 'website' },
    { label: 'Email', value: 'email' },
  ];

  const addOption = () => {
    options.push({
      name: 'options.' + Math.random().toString(36).substring(2, 15),
    });
    console.log('options in formitem.js->line 56:',options)
    setOptions(options);
    setRefresh(Math.random().toString(36).substring(2, 15));
  };

  const removeOption = (index) => {
    options.splice(index, 1);
    setOptions(options);
    setRefresh(Math.random().toString(36).substring(2, 15));
  };

  const watch_field_type = watch('type', false);

  useEffect(() => {
    //setOptions([]);
    setRefresh(Math.random().toString(36).substring(2, 15));
  }, [watch_field_type, handleSubmit]);

  useEffect(() => {
    reset();
    setOptions([]);
  }, [onSubmit]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
      <div>
        <div>
          <div className="flex flex-col">
            <div className="mb-2 mt-2">
              <select
                id="type"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                name="type"
                type="select"
                {...register('type', {
                  required: true,
                  onChange: (e) =>
                    (
                      e.target.value === 'check_box') &&
                    options.length === 0 &&
                    addOption(),
                })}
                placeholder="Select field type"
              >
                <option value="">Please select input field type</option>
                {field_types.map((item) => (
                  <option key={item.label} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
            {watch_field_type ? (
              <div className="mt-2 mb-2">
                <input
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="title"
                  type="text"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                  placeholder="Enter Title"
                ></input>
                <p className="py-2 text-danger">
                  {errors['title'] && errors['title'].message}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <div>
          {watch_field_type === 'text' ? (
            <div>
              <div>
                <div className="mt-2 mb-2">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="value"
                    name="value"
                    type="text"
                    {...register('value', { required: false })}
                    placeholder="Please enter short text"
                  ></input>
                </div>
              </div>
            </div>
          ) : null}

          {watch_field_type === 'email' ? (
            <div>
              <div>
                <div className="mt-2 mb-2">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="value"
                    name="value"
                    type="email"
                    {...register('value', {
                      pattern: {
                        value:
                          /[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/g,
                        message: 'Enter a valid email.',
                      },
                    })}
                    placeholder="Please enter email"
                  ></input>
                </div>
              </div>
              {/* <div>
                {data.type === 'exam' ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}

          {watch_field_type === 'website' ? (
            <div>
              <div className="mt-2 mb-2">
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  id="value"
                  name="value"
                  type="text"
                  {...register('value', {
                    required: false,
                    pattern: {
                      value:
                        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
                      message: 'Enter a valid web address',
                    },
                  })}
                  placeholder="Please enter web address"
                ></input>
              </div>

              {/* <div>
                {data.type === 'exam' ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}

          {watch_field_type === 'phone_number' ? (
            <div>
              <PhoneInputWithCountry
                rules={{
                  validate: (value) =>
                    value && value.length > 0 && isPossiblePhoneNumber(value),
                  message: 'Enter a valid phone number',
                }}
                flags={flags}
                className="shadow appearance-none border-2 rounded w-full py-2 px-4 text-gray-700 leading-tight"
                defaultCountry="US"
                name="phoneNumber"
                control={control}
                defaultValue=""
                placeholder="Phone Number"
              />
              {errors['phoneNumber'] && (
                <p className="py-2 text-danger">Enter a valid phone number!</p>
              )}
              {/* <div>
                {data.type === 'exam' ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}

          {watch_field_type === 'description' ? (
            <div>
              <div>
                <div className="mt-2 mb-2">
                  <textarea
                    rows="4"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="value"
                    name="value"
                    type="textarea"
                    {...register('value', { required: false })}
                    placeholder="Please enter long text"
                  ></textarea>
                </div>
              </div>
              {/* <div>
                {data.type == 'exam' ? (
                  <div className="mb-2 mt-2">
                    <textarea
                      id="correct_answer"
                      rows="4"
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      name="correct_answer"
                      type="textarea"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct long answer text"
                    ></textarea>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}
          {watch_field_type === 'number' ? (
            <div>
              <div>
                <div className="mt-2 mb-2">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="value"
                    name="value"
                    type="number"
                    min={0}
                    max={9999}
                    {...register('value', { required: false })}
                    placeholder="Please enter round number"
                  ></input>
                </div>
              </div>
              {/* <div>
                {data.type === 'exam' ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}
          {watch_field_type === 'decimal' ? (
            <div>
              <div>
                <div className="mt-2 mb-2">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="value"
                    name="value"
                    type="number"
                    min={0.01}
                    step="0.01"
                    max={9999.99}
                    presicion={2}
                    {...register('value', {
                      pattern: {
                        value: /^\d+(\.\d{1,2})?$/,
                        message: 'Enter a valid decimal number.',
                      },
                    })}
                    placeholder="Please enter decimal number"
                  ></input>
                </div>
              </div>
              {/* <div>
                {data.type === 'exam' ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}
          {watch_field_type === 'date' ? (
            <div>
              <Controller
                name="date"
                control={control}
                defaultValue={date}
                rules={{
                  value:
                    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                  message: 'Enter a valid date',
                }}
                render={() => (
                  <DatePicker
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    dateFormat="MM-dd-YYYY"
                    selected={date}
                    placeholderText="Select date"
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    adjustDateOnChange
                  />
                )}
              />
              <p className="py-2 text-danger">
                {errors['date'] && errors['date'].message}
              </p>
              {/* <div>
                {data.type === 'exam' ? (
                  <div className="mt-2 mb-2">
                    <input
                      id="correct_answer"
                      name="correct_answer"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register('correct_answer', { required: true })}
                      placeholder="Please enter correct short answer text"
                    ></input>
                  </div>
                ) : null}
              </div> */}
            </div>
          ) : null}
          {watch_field_type === 'dropdown' ? (
            <div>
              {options.map((option, option_index) => (
                <div key={option_index}>
                  <div className="flex flex-row mt-2 mb-2">
                    {/* {data.type == 'exam' ? (
                      <input
                        name="correct_answer"
                        className="mt-[16px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        type="radio"
                        {...register('correct_answer', { required: true })}
                        value={option.name.split('.')[1]}
                      ></input>
                    ) : (
                      <input disabled={true} type="radio" />
                    )} */}
                    {/* <input
                      id={option.name}
                      name={option.name}
                      className="mt-[16px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                      type="radio"
                      {...register('selected')}
                      value={option.name.split('.')[1]}
                    ></input> */}
                    <input
                      id={option.name}
                      name={option.name}
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      {...register(option.name, { required: true })}
                      placeholder={'Option ' + (option_index + 1)}
                    ></input>
                    <button
                      className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2 ml-2"
                      href="#"
                      onClick={() => removeOption(option_index)}
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>
                </div>
              ))}
              <button
                href="#"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                onClick={() => addOption()}
              >
                Add option
              </button>
            </div>
          ) : null}

          {watch_field_type === 'check_box' ? (
            <div>
              {options.map((option, option_index) => (
                <div key={option_index}>
                  <div className="flex flex-row mt-2 mb-2">
                    {/* {data.type == 'exam' ? (
                      <input
                        name="correct_answer"
                        className="mt-[16px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        type="checkbox"
                        {...register('correct_answer', { required: true })}
                        value={option.name.split('.')[1]}
                      ></input>
                    ) : (
                      <input disabled={true} type="checkbox" />
                    )} */}
                    <input
                      key={option_index}
                      className="mt-[16px] mr-[10px] w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      type="checkbox"
                      {...register(`selected${option.name}`)}
                    ></input>
                    <input
                      id={option.name}
                      name={option.name}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      type="text"
                      {...register(option.name, { required: true })}
                      placeholder={'Option ' + (option_index + 1)}
                    ></input>
                    <button
                      className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2 ml-2"
                      href="#"
                      onClick={() => removeOption(option_index)}
                    >
                      <RiDeleteBinLine />
                    </button>
                  </div>
                </div>
              ))}
              <button
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 mt-2"
                href="#"
                onClick={() => addOption()}
              >
                Add option
              </button>
            </div>
          ) : null}

          {/* {watch_field_type == 'file_upload' ? (
            <div>
              <div>
                <textarea
                  value="Allowed file types: pdf, jpg, jpeg, png, xls, xlsx, csv"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                  type="textarea"
                  id="answer"
                  name="answer"
                  {...register('answer', { required: true })}
                />
              </div>
            </div>
          ) : null} */}
        </div>
        <p className="py-2 text-danger">
          {errors['value'] && errors['value'].message}
        </p>
        <button
          type="submit"
          className="text-white bg-gradient-to-r bg-themeBlue hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-primary-300 p-2 mt-2 mb-2 w-full rounded-full"
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default AddForm;
