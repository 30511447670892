/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import { get, isEmpty } from "lodash";
import FullScreenLoader from "./Loader/FullScreenLoader";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import { useDispatch } from "react-redux";
import {
  getItemsGenOp,
  removeItemGenOp,
} from "../redux/operations/items.operations";
import { setItems } from "../redux/actions/items.action";
import BackButton from "../common/BackButton";
import { FaCheck } from "react-icons/fa";

function ViewSingleItem() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [viewItem, setViewItem] = useState(false);
  const [item, setItem] = useState();
  const [modal, setModal] = useState(false);
  const [labelId, setLabelId] = useState("");

  const [dataId, setDataId] = useState("");

  useEffect(() => {
    if (!isEmpty(get(location, "state.data"))) {
      setIsLoading(true);
      const { isView } = get(location, "state");
      if (isView) {
        console.log("location.state.data", location.state.data);
        setItem({
          ...location.state.data,
          customInput: JSON.parse(location.state.data.customInput),
        });
        setIsLoading(false);
      }
    }
  }, []);

  console.log("item", item?.customInput);

  const renderCheckBoxItems = (obj, options) => {
    const keys = Object.keys(obj).filter((k) => obj[k] === true);
    const values = Object.keys(options).filter((v) =>
      keys.map((key) => console.log(key, v))
    );

    return values;
  };

  useEffect(() => {
    if (params.type === "view") {
      setViewItem(true);
    } else {
      setViewItem(false);
    }
  }, []);

  const goToEdit = (item) => {
    navigate(`/web/items/${item.labelId}/${item._id}/edit`, {
      state: {
        data: item,
        isEdit: true,
      },
    });
  };

  const getItems = async (id) => {
    setIsLoading(true);
    dispatch(getItemsGenOp(id)).then((res) => {
      dispatch(setItems(res));
      setIsLoading(false);
      setModal(false);
      navigate("/web/items/all");
    });
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    dispatch(removeItemGenOp(id)).then((res) => {
      if (res) {
        getItems(labelId);
      }
    });
  };

  const openModal = (item) => {
    setModal(true);
    setDataId(item._id);
    setLabelId(item.labelId);
  };

  console.log("itemId", dataId);

  return (
    <div>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <div>
          {/* <BackButton /> */}
          <Modal
            dataId={dataId}
            firstButtonText={"Cancel"}
            secondButtonText={"Delete"}
            heading={"Confirm Delete"}
            body={<p>Are you sure you want to delete item?</p>}
            modal={modal}
            pressSecondButton={(id) => handleDelete(id)}
            pressFirstButton={() => setModal(false)}
            pressCloseButton={() => setModal(false)}
          />
          {viewItem && item && (
            <div
              key={item._id}
              className="flex flex-col justify-start bg-white shadow-md rounded-xl items-start"
            >
              <div className="flex flex-col  bg-blue-100 p-1 w-full rounded-tr-xl rounded-tl-xl  laptop:flex-row  laptop:justify-between gap-2 laptop:gap-4">
                <h5 className="text-xl col-span-2 mt-2 truncate">
                  Item Title: {item.title}
                </h5>
                <div className="flex flex-col laptop:flex-row">
                  <button
                    className="inline border-2 border-themeBlue bg-themeBlue mt-3 laptop:mt-0  px-6 py-2 laptop:mr-3  text-md font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-themeBlue hover:bg-indigo-700 hover:text-white focus:border-themeBlue focus:text-white focus:outline-none focus:ring-0 active:border-themeBlue active:text-white rounded-full"
                    onClick={() => goToEdit(item)}
                  >
                    Edit
                  </button>
                  <button
                    className="inline-block border-2 border-red-600 bg-red-600 mt-3 laptop:mt-0 px-6 py-2 laptop:ml-3   text-md font-medium leading-normal text-white transition duration-150 ease-in-out hover:border-red-600 hover:bg-red-500 hover:text-white focus:border-red-600 focus:text-white focus:outline-none focus:ring-0 active:border-red-600 active:text-white rounded-full"
                    onClick={() => openModal(item)}
                  >
                    Delete
                  </button>
                </div>
              </div>
              <div className=" flex flex-col laptop:grid laptop:grid-cols-12 laptop:gap-2">
                <div className="mt-3 col-span-3">
                  <img
                    alt="item"
                    src={item?.image}
                    className="object-cover laptop: rounded-bl-xl"
                  />
                </div>
                <div className=" mt-2 ml-3 mb-2 laptop:ml-3 mb-2 col-span-3">
                  <h5 className="text-lg">Items: </h5>
                  {item.keywords.map((keyword) => (
                    <ul key={keyword}>
                      <li className="text-md">
                        <FaCheck className="inline" color="green" /> {keyword}
                      </li>
                    </ul>
                  ))}
                </div>
                <div className="mt-2 ml-3 mb-2 col-span-3">
                  <h5 className="text-lg">Item Description:</h5>
                  <p className="text-md">{item.description}</p>
                </div>
                <div className="mt-2 ml-3 mb-2 col-span-3">
                  {item.customInput?.map((field) => (
                    <ul key={field.key}>
                      {field.title && (
                        <li className="text-lg">
                          {field.title} : 
                          <p className="text-sm ml-1 inline-flex">
                            {field.type === "dropdown" &&
                            field.value &&
                            typeof field.value === "object"
                              ? field.value.title
                              : field.type !== "check_box"
                              ? field.value
                              : ""}
                          </p>
                          {field.type === "check_box" && (
                            <React.Fragment>
                              <div className="flex flex-row tablet:flex-col laptop:flex-row">
                                {field.metadata.options.map((option) => (
                                  <div key={option.key}>
                                    <div className="mt-1 mb-2 ml-1">
                                      <input
                                        id={option.key}
                                        className="w-5 h-5 rounded text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                                        type="checkbox"
                                        value={option.title}
                                        defaultChecked={field.value.some(
                                          (val) =>
                                            val.key === option.key &&
                                            val.isChecked
                                        )}
                                        disabled
                                      />
                                      <label className="ml-2 mr-2 text-md font-medium text-gray-800">
                                        {option.title}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </React.Fragment>
                          )}
                        </li>
                      )}
                    </ul>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ViewSingleItem;
