const subscriptionState = {};

export const subscriptionReducer = (state = subscriptionState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        ...payload,
      };
    case 'GET_SUBSCRIPTION':
      return {
        ...payload,
      };
    default:
      return { ...state };
  }
};
