import React from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { MdProductionQuantityLimits } from 'react-icons/md';
import { FaThList } from 'react-icons/fa';
import { MdAddPhotoAlternate } from 'react-icons/md';
import { MdInventory2 } from 'react-icons/md';
import { MdDashboardCustomize } from 'react-icons/md';
import { MdNewLabel } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { GrUserAdmin } from 'react-icons/gr';
import { IoMdBarcode } from 'react-icons/io';
import { MdOutlineQrCodeScanner } from 'react-icons/md';
import { FaCartPlus } from 'react-icons/fa';
import { MdOutlineLibraryAddCheck } from 'react-icons/md';
import { IoMdNotifications } from 'react-icons/io';
import { GoAlertFill } from 'react-icons/go';
import { MdWifiOff } from 'react-icons/md';
import { FaSync } from 'react-icons/fa';
import { IoMdAlert } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';
import { FaHistory } from 'react-icons/fa';
import { GiStack } from 'react-icons/gi';
import { PiUserListFill } from 'react-icons/pi';
import { AiOutlineStock } from 'react-icons/ai';
import { MdOutlineMemory } from 'react-icons/md';
import { BsHddRackFill } from 'react-icons/bs';
import { FaTag } from 'react-icons/fa';
import { IoMdMailUnread } from 'react-icons/io';
const Card = ({ title, description, icon }) => {
  return (
    <div
      className={`border border-solid p-5 shadow-xl bg-themeBodyBg mb-2 flex flex-col rounded-3xl`}
      style={{ height: '340px' }}
    >
      {icon === 'MdAddPhotoAlternate' ? (
        <span className="flex justify-center items-center">
          {<MdAddPhotoAlternate size={70} color="#020381" />}
        </span>
      ) : icon === 'FaUserPlus' ? (
        <span className="flex justify-center items-center">
          {<FaUserPlus size={70} color="#020381" />}
        </span>
      ) : icon === 'MdProductionQuantityLimits' ? (
        <span className="flex justify-center items-center">
          <MdProductionQuantityLimits size={70} color="#020381" />
        </span>
      ) : icon === 'FaThList' ? (
        <span className="flex justify-center items-center">
          <FaThList size={70} color="#020381" />
        </span>
      ) : icon === 'MdInventory2' ? (
        <span className="flex justify-center items-center">
          <MdInventory2 size={70} color="#020381" />
        </span>
      ) : //customize
      icon === 'MdDashboardCustomize' ? (
        <span className="flex justify-center items-center">
          <MdDashboardCustomize size={70} color="#020381" />
        </span>
      ) : icon === 'FaUsers' ? (
        <span className="flex justify-center items-center">
          <FaUsers size={70} color="#020381" />
        </span>
      ) : icon === 'MdNewLabel' ? (
        <span className="flex justify-center items-center">
          <MdNewLabel size={70} color="#020381" />
        </span>
      ) : icon === 'GrUserAdmin' ? (
        <span className="flex justify-center items-center">
          <GrUserAdmin size={70} color="#020381" />
        </span>
      ) : //manage
      icon === 'IoMdBarcode' ? (
        <span className="flex justify-center items-center">
          <IoMdBarcode size={70} color="#020381" />
        </span>
      ) : icon === 'MdOutlineQrCodeScanner' ? (
        <span className="flex justify-center items-center">
          <MdOutlineQrCodeScanner size={70} color="#020381" />
        </span>
      ) : icon === 'FaCartPlus' ? (
        <span className="flex justify-center items-center">
          <FaCartPlus size={70} color="#020381" />
        </span>
      ) : icon === 'MdOutlineLibraryAddCheck' ? (
        <span className="flex justify-center items-center">
          <MdOutlineLibraryAddCheck size={70} color="#020381" />
        </span>
      ) : //track and update
      icon === 'IoMdNotifications' ? (
        <span className="flex justify-center items-center">
          <IoMdNotifications size={70} color="#020381" />
        </span>
      ) : icon === 'GoAlertFill' ? (
        <span className="flex justify-center items-center">
          <GoAlertFill size={70} color="#020381" />
        </span>
      ) : icon === 'MdWifiOff' ? (
        <span className="flex justify-center items-center">
          <MdWifiOff size={70} color="#020381" />
        </span>
      ) : icon === 'FaSync' ? (
        <span className="flex justify-center items-center">
          <FaSync size={70} color="#020381" />
        </span>
      ) : icon === 'IoMdAlert' ? (
        <span className="flex justify-center items-center">
          <IoMdAlert size={70} color="#020381" />
        </span>
      ) : icon === 'MdEmail' ? (
        <span className="flex justify-center items-center">
          <MdEmail size={70} color="#020381" />
        </span>
      ) : //Report
      icon === 'FaHistory' ? (
        <span className="flex justify-center items-center">
          <FaHistory size={70} color="#020381" />
        </span>
      ) : icon === 'GiStack' ? (
        <span className="flex justify-center items-center">
          <GiStack size={70} color="#020381" />
        </span>
      ) : icon === 'PiUserListFill' ? (
        <span className="flex justify-center items-center">
          <PiUserListFill size={70} color="#020381" />
        </span>
      ) : icon === 'AiOutlineStock' ? (
        <span className="flex justify-center items-center">
          <AiOutlineStock size={70} color="#020381" />
        </span>
      ) : icon === 'BsHddRackFill' ? (
        <span className="flex justify-center items-center">
          <BsHddRackFill size={70} color="#020381" />
        </span>
      ) : icon === 'MdOutlineMemory' ? (
        <span className="flex justify-center items-center">
          <MdOutlineMemory size={70} color="#020381" />
        </span>
      ) : icon === 'FaTag' ? (
        <span className="flex justify-center items-center">
          <FaTag size={70} color="#020381" />
        </span>
      ) : (
        <span className="flex justify-center items-center">
          <IoMdMailUnread size={70} color="#020381" />
        </span>
      )}
      <h2 className="text-2xl font-bold text-center mt-5 mb-5">{title}</h2>
      <p className="mb-4">{description}</p>
    </div>
  );
};

export default Card;
