import React from "react";
import SEO from "../../components/SEO";
import Featuretabs from "./Tabs";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
const Features = () => {
  return (
    <>
      <SEO
        title={`Discover Scans Features - Track the Features and Activate the best Plan`}
        description={`Explore Scans Features and find the perfect one for your inventory management needs. Start your free trial today.`}
        keywords={`Scans Pricing Plans, Fetaures , Organize , Track and Update , Customize ,Manage, Inventory Management Pricing, Find the Perfect Plan, Scans Free Trial, Inventory Software Pricing, Affordable Inventory Management, Scans Pricing Options, Best Inventory Management Plans`}
      />

      <div className="laptop:flex laptop:flex-col laptop:justify-center  laptop:pl-16 laptop:pr-16 desktop:pl-18 pt-10 desktop:pr-18 bg-blue-100">
        <header className="w-full pt-12 flex flex-col justify-center items-center pb-10">
          <h2 className="text-4xl w-full text-themeHeadingText laptop:mt-15 laptop:text-6xl desktop:text-[4rem] laptop:mr-24 laptop:ml-24 font-proximaNovaBlack text-center">
            Explore Our Inventory Management Features
          </h2>

          <p className="w-full text-md tablet:text-xl laptop:text-2xl mt-8 pr-5 pl-5 text-center">
            Discover how Scans simplifies inventory with features designed for
            ease and organization.
          </p>
          <div className="flex justify-center text-center pt-10 pb-5">
            <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
              <Link
                to="/web/login"
                className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
              >
                Try Scans free
              </Link>
              <Link
                to="/web/pricing"
                className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
              >
                See all plans <FaAngleRight className="inline" />
              </Link>
            </div>
          </div>
        </header>
        <Featuretabs />
        <div className="flex justify-center text-center pt-5 pb-5">
          <div className="flex w-full flex-col tablet:flex-row justify-center items-center tablet:items-start tablet:pb-10 laptop:pb-0 fade-in">
            <Link
              to="/web/login"
              className="text-white bg-themeBlue focus:ring-4 focus:ring-primary-200 font-bold rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
            >
              Try Scans free
            </Link>
            <Link
              to="/web/pricing"
              className="text-themeBlue font-bold rounded-lg text-lg py-2.5 text-center hover:underline tablet:ml-4"
            >
              See all plans <FaAngleRight className="inline" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
