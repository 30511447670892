import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { get, isEmpty } from 'lodash';
import FullScreenLoader from '../../components/Loader/FullScreenLoader';
import InputField from '../../common/InputField';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateQRGenOp,
  getQRsGenOp,
} from '../../redux/operations/qrCodes.operations';
import { setQRCodes } from '../../redux/actions/qrCodes.action';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';

function EditBox({ isEditModal, setIsEditModal, box, dataId,setIsLoading,isLoading }) {
  const userId = useSelector((state) => state.user._id);
  const {companyId} = useSelector((state) => state.user);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [title, setTitle] = useState(box?.title || '');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    setTitle(box?.title || '');
  }, [box]);

  const getQRCodes = async (id) => {
    dispatch(getQRsGenOp(id)).then((res) => {
      dispatch(setQRCodes(res));
    });
  };

  const updateQRCode = async (newTitle) => {
    setIsLoading(true);
    if (newTitle?.trim().length > 0) {
      const data = {
        title: newTitle.trim(),
        companyId:companyId,
      };
      dispatch(updateQRGenOp(box?.labelId, data)).then(() => {
        getQRCodes(userId).then(() => {
          toast.success('Box Title Updated Successfully')
          setIsEditModal(false);
          setIsLoading(false);
          
        });
      });
    } else {
      toast.error('Box Title Cannot Be Empty!');
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isEditModal && (
        <Modal
          dataId={dataId|| ''}
          firstButtonText={'Cancel'}
          secondButtonText={'Edit'}
          heading={'Edit Box'}
          body={
            <div className="flex flex-col mb-6 w-full " key="editBox">
              <label className="block text-gray-800 font-bold mb-3 pr-4 laptop:w-44">
                Box Title
              </label>
              <InputField
                fieldName="title"
                register={register}
                errors={errors}
                fieldType="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          }
          modal={isEditModal}
          pressSecondButton={() => updateQRCode(title)}
          pressFirstButton={() => setIsEditModal(false)}
          pressCloseButton={() => setIsEditModal(false)}
        />
      )}
    </div>
  );
}

export default EditBox;
