/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import OtpInput from 'react-otp-input';
import InputField from '../../common/InputField';
import LazyLoadImage from 'react-lazy-load-image-component';
import FullScreenLoader from '../Loader/FullScreenLoader';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { toast } from 'react-toastify';
import Navbar from '../Navbar.jsx';
import Footer from '../Footer';
// import scans_logo from '../../assets/scans_logo.ico';
import scans_logo from '../../assets/scans-logo-latest.png';
import { FaGoogle, FaApple } from 'react-icons/fa';
import SEO from '../SEO.jsx';
import {
  userLoginGenOp,
  socialLoginGenOp,
  userLoginVerifyOp,
} from '../../redux/operations/user.operations';
import { setUser } from '../../redux/actions/login.action';
import { Button, Card, Grid } from '@mui/material';

import { Box, styled } from '@mui/system';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  width: '100%',
  paddingLeft: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const IMG = styled('img')(() => ({
  width: '100vw',
  height: '50vh',
  backgroundColor: '#e6f6fc',
}));

const JWTRoot = styled(JustifyBox)(() => ({
  background: '#1A2038',
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .card': {
    maxWidth: 1000,
    maxHeight: 1000,
    borderRadius: 20,
    // height: '70vh',
  },
}));

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [otpFieldDisplay, setOtpFieldDisplay] = useState(false);
  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState(null);
  const [OTP, setOTP] = useState();

  const dispatch = useDispatch();
  const [timer, setTimer] = useState(120); // Timer starts at 2 minutes (120 seconds)
  const [disabled, setDisabled] = useState(true); // Resend button initially disabled

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timer]);

  useEffect(() => {
    if (timer === 0) {
      setDisabled(false); // Enable resend button when timer reaches 0
    }
  }, [timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  };

  const handleResendClick = () => {
    const data = {
      email: email,
    };
    dispatch(userLoginGenOp(data))
      .then((response) => {
        setOtpFieldDisplay(true);
        reset();
        toast.info(response.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        err.response &&
          err.response.data.message &&
          toast.error(err.response.data.message);
        console.log(err.response.data.message);
      });

    setTimer(120); // Reset timer back to 2 minutes
    setDisabled(true); // Disable resend button again
  };

  const handleChange = (value) => {
    setOTP(value);
  };

  useEffect(() => {
    if (googleUser && googleUser.access_token) {
      setIsLoading(true);
      try {
        dispatch(socialLoginGenOp(googleUser.access_token))
          .then((res) => {
            console.log('res', res);
            dispatch(setUser(res.data));
            reset();
            toast.success(res.data.message);
            const { token } = res.data;
            if (token) {
              localStorage.setItem('userAuthToken', token);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log('error in sso');
            console.log('error in sso', err);
            err.response &&
              err.response.data.message &&
              toast.error(err.response.data.message);
            console.log(err.response.data.message);
          });
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }

      // axios
      //   .get(
      //     `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${googleUser.access_token}`,
      //         Accept: 'application/json',
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     setIsLoading(false);
      //     setProfile(res.data);
      //   })
      //   .catch((err) => {
      //     setIsLoading(false);
      //     console.log(err);
      //   });
    }
  }, [googleUser]);

  // useEffect(() => {
  //   if (profile) {
  //     const data = {
  //       email: profile.email,
  //     };
  //     loginViaEmailAndOTP(data);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [profile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('userAuthToken')) {
      // navigate(-1);
      navigate('/web/dashboard');
    }
  });

  const loginViaEmailAndOTP = (data) => {
    if (otpFieldDisplay) {
      data = {
        ...data,
        email: email,
      };
      dispatch(userLoginVerifyOp(data))
        .then((res) => {
          // console.log(res.data.data);
          dispatch(setUser(res.data.data.user));
          reset();
          toast.success(res.data.message);
          const { token } = res.data.data;
          if (token) {
            localStorage.setItem('userAuthToken', token);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.response.data &&
            err.response.data.message &&
            toast.error('Invalid OTP');
          console.log(err);
        });
    } else {
      setEmail(data.email);
      dispatch(userLoginGenOp(data))
        .then((response) => {
          setOtpFieldDisplay(true);
          reset();
          toast.info(response.data.message);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.response &&
            err.response.data.message &&
            toast.error(err.response.data.message);
          console.log(err.response.data.message);
        });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    // e.stopPropagation();
    setIsLoading(true);
    // loginViaEmailAndOTP(data);
    loginViaEmailAndOTP(otpFieldDisplay ? { ...data, otp: OTP } : data);
  };

  const loginToGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log('Login Failed:', error),
  });

  return (
    <>
      <SEO
        title={`Try Scans Free - Experience the Ultimate Inventory Management Solution`}
        description={
          'Start your free trial of Scans today and experience the ultimate inventory management solution. Efficient, advanced, and user-friendly.'
        }
        keywords={
          'Free Inventory Management Trial, Try Scans Free, Ultimate Inventory Management Solution, Advanced Inventory Software, User-Friendly Inventory System, Efficient Inventory Management, Inventory Management Free Trial, Scans Inventory Software'
        }
      />
      <section className="h-screen">
        {isLoading && <FullScreenLoader />}
        {/* <Navbar /> */}
        <JWTRoot>
          <Card className="card">
            <Grid container>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <IMG src={scans_logo} alt="logo" effect="blur" />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <ContentBox>
                  <div
                    onKeyDown={(e) => handleSubmit(onSubmit, e)}
                    // className="rounded-lg bg-white p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
                  >
                    <div className="mt-2 mb-2">
                      <h2 className="text-md tablet:text-lg text-gray-600 font-bold">
                        {otpFieldDisplay
                          ? 'Enter OTP below'
                          : 'Welcome back! Sign in to your account.'}
                      </h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {otpFieldDisplay ? (
                        <div className="mb-6">
                          {/* <InputField
                            fieldName="otp"
                            validationObj={{
                              required: "Please Enter Otp to Verify"
                            }}
                            register={register}
                            errors={errors}
                            fieldType="text"
                            className="w-full px-8 py-4 rounded-lg font-medium bg-white border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400"
                            placeholder="Enter Otp Here"
                            keyDown={(e) => handleSubmit(onSubmit, e)}
                          /> */}
                          <div className="otp">
                            <OtpInput
                              value={OTP}
                              onChange={handleChange}
                              numInputs={4}
                              inputType="tel"
                              name="otp"
                              renderSeparator={<span></span>}
                              inputStyle="inputStyle"
                              shouldAutoFocus={true}
                              disabled={!disabled}
                              renderInput={(props) => (
                                <input {...props} required />
                              )}
                            />
                          </div>
                          <div className="flex flex-row mt-4 justify-center items-center">
                            <button
                              className={`px-4 py-2 border-themeBlue border border-solid text-themeBlue rounded-3xl hover:bg-themeBlue hover:text-white ${
                                disabled ? 'cursor-not-allowed opacity-50' : ''
                              }`}
                              onClick={handleResendClick}
                              disabled={disabled}
                            >
                              Resend OTP
                            </button>
                            {disabled && (
                              <p
                                className={`text-sm bg-gray-300 p-2 rounded font-bold text-gray-600 ml-3`}
                              >
                                {formatTime(timer)}
                              </p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="mb-6">
                          <InputField
                            fieldName="email"
                            register={register}
                            errors={errors}
                            validationObj={{
                              required: 'Please Enter Your Email',
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email',
                              },
                            }}
                            fieldType="email"
                            className="w-full px-8 py-4 rounded-lg font-medium bg-white border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400"
                            placeholder="Email"
                            keyDown={(e) => handleSubmit(onSubmit, e)}
                          />
                        </div>
                      )}
                      <div className="w-full flex flex-col items-center justify-center pb-3">
                        <button
                          type="submit"
                          className="w-full max-w-sm mb-3 inline-block rounded-3xl bg-themeBlue px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-themeBlue hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                          data-te-ripple-init
                          data-te-ripple-color="light"
                          onClick={(e) => handleSubmit(onSubmit, e)}
                        >
                          {otpFieldDisplay ? 'Verify OTP' : 'Sign in'}
                        </button>
                        {!otpFieldDisplay && (
                          <div className="w-full flex flex-col items-center justify-center">
                            <div className="flex justify-center items-center">
                              <hr className="w-full border-1 border-solid border-black-900 my-3 mr-2" />
                              <p className="text-gray-500 uppercase font-semibold">
                                OR
                              </p>
                              <hr className="w-full border-gray-300 my-3 ml-2" />
                            </div>
                            <button
                              type="button"
                              className="w-full flex items-center max-w-sm mb-3 p-2 rounded-3xl  border border-themeBlue border-solid bg-white text-themeBlue px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white focus:bg-themeBlue focus:text-white focus:outline-none focus:ring-0 active:bg-themeBlue active:text-white"
                              data-te-ripple-init
                              data-te-ripple-color="light"
                              onClick={() => loginToGoogle()}
                            >
                              <FaGoogle className="h-4 w-4 mr-2 ml-10" />
                              <span>Sign in with Google</span>
                            </button>

                            {/* <button
                              type="button"
                              className="w-full flex items-center max-w-sm mb-3 p-2 rounded-3xl  border border-themeBlue border-solid bg-white text-themeBlue px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal transition duration-150 ease-in-out hover:bg-themeBlue hover:text-white focus:bg-themeBlue focus:text-white focus:outline-none focus:ring-0 active:bg-themeBlue active:text-white"
                              data-te-ripple-init
                              data-te-ripple-color="light"
                            >
                              <FaApple className="h-4 w-4 mr-2 ml-10" />
                              <span>Sign in with Apple</span>
                            </button> */}
                          </div>
                        )}
                      </div>
                      {/* {!otpFieldDisplay && (
                    <div className='w-100 flex flex-row items-center justify-center pt-3'>
                      <button
                        type='button'
                        data-te-ripple-init
                        data-te-ripple-color='light'
                        onClick={() => loginToGoogle()}
                        className='mx-1 h-9 w-9 rounded-full bg-primary uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='currentColor'
                          className='mx-auto h-3.5 w-3.5'
                          viewBox='0 0 16 16'
                        >
                          <path
                            d='M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z'
                            id='mainIconPathAttribute'
                          ></path>
                        </svg>
                      </button>
                    </div>
                  )} */}
                    </form>
                  </div>
                </ContentBox>
              </Grid>
            </Grid>
          </Card>
        </JWTRoot>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default Login;
