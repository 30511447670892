import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userLogoutOp } from '../redux/operations/user.operations';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';
import { IoMenuSharp } from 'react-icons/io5';
import scansLogo from '../assets/scans-logo-text.png';
function Navbar() {
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const mobileMenu = () => {
    setShowMobileNav(!showMobileNav);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  useEffect(() => {
    if (localStorage.getItem('userAuthToken')) {
      setIsLogin(true);
    }
  }, [params]);

  const handleLogout = () => {
    localStorage.removeItem('userAuthToken');
    localStorage.removeItem('locationIp');
    dispatch(userLogoutOp());
    navigate('/web/login');
  };

  return (
    <nav className="w-full sticky top-0 bg-white shadow z-50">
      <div className="flex w-full flex-wrap justify-between items-center tablet:justify-center pl-2 pr-2">
        <Link to="/" className="flex items-center pl-1 tablet:pl-8 mr-auto">
          <img
            src={scansLogo}
            alt="logo"
            className="object-center object-scale-down h-24 tablet:h-28"
          />
        </Link>

        {showMobileNav ? (
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg laptop:hidden"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={mobileMenu}
          >
            <span className="sr-only">Open main menu</span>
            <AiOutlineClose size={24} />
          </button>
        ) : (
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg laptop:hidden"
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={mobileMenu}
          >
            <span className="sr-only">Open main menu</span>
            <IoMenuSharp size={24} />
          </button>
        )}

        {showMobileNav ? (
          <div className="w-full laptop:hidden" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg laptop:flex-row laptop:space-x-8 laptop:mt-0 laptop:text-sm laptop:font-bold laptop:border-0">
              {isLogin ? (
                <>
                  <li>
                    <Link
                      to="/web/dashboard"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded laptop:border-0font-proximaNova text-xl transition duration-300 ease-in-out hover:text-blue-500"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block py-2 pl-3 pr-4 bg-red-600 rounded hover:bg-red-500 laptop:hover:bg-red-500 tablet:border-0 text-white font-proximaNova text-lg"
                    >
                      Sign Out
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link
                      to="/"
                      onClick={mobileMenu}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded laptop:p-0 text-xl font-proximaNova transition duration-300 ease-in-out hover:text-blue-500"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.scanqr.ai/web/features"
                      onClick={mobileMenu}
                      // to="mailto:contact@scanqr.ai"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded laptop:p-0 text-xl font-proximaNova transition duration-300 ease-in-out hover:text-blue-500"
                    >
                      Features
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="https://www.scanqr.ai/web/contact-us"
                      onClick={mobileMenu}
                      // to="mailto:contact@scanqr.ai"
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded laptop:p-0 text-xl font-proximaNova transition duration-300 ease-in-out hover:text-blue-500"
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.scanqr.ai/web/pricing"
                      onClick={mobileMenu}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded laptop:p-0 text-xl font-proximaNova transition duration-300 ease-in-out hover:text-blue-500"
                    >
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://www.scanqr.ai/blog/"
                      onClick={mobileMenu}
                      className="block py-2 pl-3 pr-4 text-gray-700 rounded laptop:p-0 text-xl font-proximaNova transition duration-300 ease-in-out hover:text-blue-500"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="mt-5">
                    <Link
                      to="https://www.scanqr.ai/web/login"
                      onClick={mobileMenu}
                      className="text-themeBlue border border-2 border-solid border-themeBlue rounded-full  text-lg px-5 py-2.5 text-center hover:bg-themeBlue hover:text-white"
                    >
                      Sign In
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        ) : null}
        {/*Mobile Nav*/}
        <div
          className="hidden flex  justify-center items-center w-full laptop:block laptop:w-auto"
          id="navbar-default"
        >
          <ul className="flex flex-col p-4 mt-4 border border-gray-100 rounded-lg laptop:flex-row laptop:space-x-8 laptop:mt-0 laptop:text-sm laptop:font-bold laptop:border-0">
            {isLogin ? (
              <>
                <li>
                  <Link
                    to="/web/dashboard"
                    className="block py-2 pl-3 pr-4 text-gray-700 rounded font-proximaNova transition ease-in-out delay-150 text-xl"
                  >
                    Dashboard
                  </Link>
                </li>
                <li>
                  <button
                    onClick={handleLogout}
                    className="block py-2 pl-3 pr-4 text-white rounded-full hover:bg-red-500 tablet:hover:bg-red-500 tablet:border-0 bg-red-600 font-proximaNova text-lg"
                  >
                    Sign Out
                  </button>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/"
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded tablet:p-0 text-lg font-proximaNova transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.scanqr.ai/web/features"
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded tablet:p-0 text-lg font-proximaNova transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Features
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.scanqr.ai/web/contact-us"
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded tablet:p-0 text-lg font-proximaNova transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.scanqr.ai/web/pricing"
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded tablet:p-0 text-lg font-proximaNova transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <a
                    href="https://www.scanqr.ai/blog/"
                    className="block py-2 pl-3 pr-4 text-gray-900 rounded tablet:p-0 text-lg font-proximaNova transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <Link
                    to="https://www.scanqr.ai/web/login"
                    className="text-themeBlue border border-2 border-solid font-proximaNova border-themeBlue rounded-full  text-lg px-5 py-2.5 text-center hover:bg-themeBlue hover:text-white"
                  >
                    Sign In
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.scanqr.ai/web/login"
                    className="text-white bg-themeBlue font-proximaNovaThin focus:ring-4 focus:ring-primary-200 rounded-full text-lg px-5 py-2.5 text-center hover:bg-indigo-700"
                  >
                    Start a Free Trial
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
