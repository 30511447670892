import { combineReducers } from 'redux';
import { loginReducer, userReducer } from './login.reducer';
import { itemsReducer } from './items.reducer';
import {menuReducer} from './menu.reducer';
import { qrCodesReducer } from './qrCodes.reducer';
import { subscriptionReducer } from './subscriprion.reducer';
import {termReducer} from './term.reducer'

const appReducer = combineReducers({
  login: loginReducer,
  user: userReducer,
  qrCodes: qrCodesReducer,
  items: itemsReducer,
  subscription: subscriptionReducer,
  menu:menuReducer,
  term:termReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
