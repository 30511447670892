const qrCodesState = {};

export const qrCodesReducer = (state = qrCodesState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_QRCODES':
      return {
        ...state,
        ...payload,
      };
    case 'GET_QRCODES':
      return {
        ...payload,
      };
    case 'REMOVE_QRCODES':
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};
