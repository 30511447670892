const termState = {
    isYearly: false,
  };
  export const termReducer = (state = termState, action) => {
    const { type, payload } = action;
    switch (type) {
      case 'ISYEARLY':
        return {
          ...state,
          isYearly:payload,
        };
      default:
        return { ...state };
    }
  };