import React from 'react'
import { Grid } from '@mui/material'
import Card from './Card'
import { Reportdata } from '../../utils/FeaturesData'

const Report = () => {
  return (
    <>
     <div className="bg-blue-100">
    <h1 className='text-xl tablet:text-2xl laptop:text-3xl font-bold font-proximaNovaBlack text-blue-900 uppercase tracking-wider'>Report</h1>
    <h1 className='font-proximaNovaBlack text-lg tablet:text-xl laptop:text-2xl pt-4'>Inventory Reports.</h1>
    <p className='font-proximaNovaBlack text-gray-700 text-md laptop:text-lg'>Get in-depth reports on items, folders, user histories, stock levels, and more.</p>
    <div className="flex justify-center bg-blue-100 pt-7">
        <Grid container spacing={2}>
          {Reportdata.map((report, index) => (
            <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
              <Card
                title={report.title}
                description={report.description}
                icon={report.icon}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      </div>
    </>
   
  )
}

export default Report