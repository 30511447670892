export const LOGIN_API = 'user/login';
export const GENERATE_OTP = 'user/generateOtp';
export const VERIFY_OTP = 'user/verify';
export const ADD_QR = 'label';
export const GET_QRs = 'label';
export const UPDATE_QR = 'label?labelId=';
export const REMOVE_QR = 'label?labelId=';
export const ADD_ITEM = 'product';
export const GET_ITEMS = 'product?labelId=';
export const GET_ITEMS_BY_USER = 'product/byUserId';
export const UPDATE_ITEM = 'product?id=';
export const REMOVE_ITEM = 'product?id=';
export const STRIPE_PAYMENT_INTENT = 'stripe/pay';
export const GET_SUBSCRIPTION_STATUS = 'subscription/subscription-status?id=';
export const CREATE_QR = 'qr/qr-create';
export const SEARCH_API = 'oj/product/search';
export const UPDATE_USER = 'user?id=';
export const ADD_USER = 'user';
export const GET_USER_BY_ADMIN = 'admin/users';
export const DELETE_USER_BY_ADMIN = 'user/admin/user?userId=';
export const GET_USER = 'user?id=';
export const CREATE_SUBSCRIPTION = 'stripe/create-subscription';
export const CONTACT_INFO = 'contact/createContact';
export const ACTIVATE_PERSONAL = 'subscription/activate-personal';
export const DEACTIVATE_PERSONAL = 'subscription/deactivate-personal';
export const CANCEL_SUBSCRIPTION = 'stripe/cancel-subscription';
export const GENERATE_REPORT = 'report/excel-report';
export const GET_BLOGS = 'blogs/findAllBlogDetails';
export const SOCIAL_LOGIN = 'user/sso?token=';
export const IMAGE_ANALYZE = 'image/analyze';
export const CREATE_COUPON = 'stripe/coupon';
export const GET_ALL_PLANS = 'plans/get-all-plans';
export const DELETE_ADMIN = 'user/admin';
